import React from 'react'

function SendBoxGrayOut() {
	return (
		<div className="click-begin gray-out">
			<span>&nbsp;</span>
		</div>
	)
}
export default SendBoxGrayOut

export function scrollChatToBottom() {
	// AP client scroll issue.
	const feed = queryChatFeedEl()
	feed.lastElementChild && feed.lastElementChild.scrollIntoView({ behavior: 'smooth', block: 'start' })
}

function queryChatFeedEl() {
	const feed = document.querySelector('section[role="feed"]')
	if (!feed) throw new Error('Chat activity feed not found')
	return feed
}

export function isJson(str: string) {
	try {
		JSON.parse(str)
	} catch (e) {
		return false
	}
	return true
}

export function detectChannelAgentType(): 'Web Browser' | 'Mobile Browser' | 'Mobile App' | '' {
	if (
		// TODO: should use a package that specializes in mobile detection to handle this
		navigator.userAgent.match(/Android/i) ||
		navigator.userAgent.match(/webOS/i) ||
		navigator.userAgent.match(/iPhone/i) ||
		navigator.userAgent.match(/iPad/i) ||
		navigator.userAgent.match(/iPod/i) ||
		navigator.userAgent.match(/BlackBerry/i) ||
		navigator.userAgent.match(/Windows Phone/i)
	) {
		return 'Mobile Browser'
	}
	return 'Web Browser'
}

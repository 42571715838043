import axios from 'axios'
import { HEADER_PARAMS, globalTenantServiceUrl } from '../../../config'
import { identityApi } from './identityApi'
// import { apiUrl } from '../../../config';

export const getTenantService = (tenantId: string) => {
	// console.log("apiUrl REACT_APP_API_BASE_URL ",process.env.REACT_APP_API_BASE_URL)
	// console.log("apiUrl REACT_APP_API_HOST ",process.env.REACT_APP_API_HOST)

	const requestOptions = {
		url: `${globalTenantServiceUrl}/tenant?tenantId=${tenantId}`,
		method: 'GET',
		headers: HEADER_PARAMS,
	}

	return axios.request(requestOptions)
}

export const checkTenantExists = (tenantId: string) => {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...HEADER_PARAMS,
		},
		url: `${globalTenantServiceUrl}/tenant?tenantId=${tenantId}`,
	}

	return axios.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(new Error(`Unable to do tenant validation : Error code : ${response.status}`))
		}

		return response.data
	})
}

export const registerTenant = payload => {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${globalTenantServiceUrl}/tenant`,
		data: payload,
	}

	return axios.request(requestOptions).then(response => {
		if (response.status !== 201) {
			return Promise.reject(new Error(`Unable to do create tenant : Error code : ${response.status}`))
		}

		return response.data
	})
}

export const getTenantLifeCycle = tenantId => {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${globalTenantServiceUrl}/tenant/${tenantId}/lifecycle`,
	}

	return axios.request(requestOptions).then(response => {
		if (response.status !== 200 && response.status !== 304) {
			return Promise.reject(new Error(`Unable to do creation tenant lifecycle : Error code : ${response.status}`))
		}

		return response.data
	})
}

export const saveTenantId = options => {
	const ep = options.apiUrl || sessionStorage.getItem('apiUrl')
	let platform = 'Web'
	if (navigator.userAgent.match(/Android/i)) platform = 'Android'
	if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) platform = 'iOS'
	if (!options) {
		options = { source: 'none', platform, ip: '' }
		console.log(`Error in saveTenantId. Params missing`)
	} else {
		options.platform = platform
		options.ip = ''
	}
	// fetch('https://api.ipify.org/?format=json')
	//     .then(response => response.json())
	//     .then(response => {
		
	const requestOptions = {
		method: 'POST',
		data: options,
		headers: { 'Content-Type': 'application/json', 'x-tenantid': options.tenantId, ...HEADER_PARAMS },
		// REFACTOR-NOTES: url should not be conditioned on header params
		url: (HEADER_PARAMS && process.env.REACT_APP_API_HOST && process.env.REACT_APP_API_BASE_URL)
			? `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_BASE_URL}/botmgmt-service/botdef/saveTenantId`
			: `${ep}/botmgmt-service/botdef/saveTenantId`,
	}

	return axios.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}

		return response.data
	})
	// })
	// .catch(err => {
	//     console.log(err);
	// })
}
export const saveUserData = (options, token, apiUrl) => {
	const ep = apiUrl || sessionStorage.getItem('apiUrl')
	let platform = 'Web'
	if (navigator.userAgent.match(/Android/i)) platform = 'Android'
	if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) platform = 'iOS'
	if (!options) {
		options = { source: 'none', platform, ip: '' }
		console.log(`Error in saveUserData. Params missing`)
	} else {
		options.platform = platform
		options.ip = ''
	}
	const headers = { 'Content-Type': 'application/json', realmname: options.realmName, Authorization: `Bearer ${token}` }
	if (token.includes('Bearer')) {
		delete headers.Authorization
		headers.msTokenSignedForUser = token
	} // fetch('https://api.ipify.org/?format=json')
	//     .then(response => response.json())
	//     .then(response => {
	const requestOptions = {
		method: 'POST',
		data: options,
		headers,
		url: `${ep}/botmgmt-service/botdef/saveUserData`,
	}
	return identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}

		return response.data
	})
	// })
	// .catch(err => {
	//     console.log(err);
	// })
}

import { createSelector } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux'
import type { AppDispatch, RootState } from './store'

export const useAppDispatch: () => AppDispatch = useReduxDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useReduxSelector

// custom selectors
export const selectTenant = (state: RootState) => state.tenant
export const selectIdentity = (state: RootState) => state.identity
export const selectBotData = (state: RootState) => state.bot.botData
export const selectBotToken = (state: RootState) => {
	const { token, botSecret } = selectBotData(state) || {}
	return token || botSecret
}

const selectWhiteLabelEntityState = (state: RootState) => state.whiteLabelEntity
export const selectWhiteLabelConfig = createSelector(
	(state: RootState, appName: string) =>
		selectWhiteLabelEntityState(state)?.itemData?.filter(item => item.appName === appName),
	configs => configs
)

export const selectNotificationCount = (state: RootState) => state.notificationCount?.count?.count // TODO: can we flatten this?

export const selectGlobalSetting = (state: RootState) => state.globalSetting
export const selectGlobalSettingTenantConfig = (state: RootState) => selectGlobalSetting(state)?.tenantConfig

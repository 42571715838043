import { HEADER_PARAMS } from '../../../config'
import { identityServices } from '../../identity'

class WhiteLabelServices {
	apiUrl: string

	apiHostUrl: string

	constructor(options?: { apiUrl: string; apiModuleUrl: string }) {
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl') ?? ''
		this.apiHostUrl = options?.apiModuleUrl
			? `${options.apiUrl || this.apiUrl}/${options.apiModuleUrl}`
			: options?.apiUrl || this.apiUrl
	}

	getDataWithDefaultTenant(tenantUid: string, apiUrl: string) {
		const apiUrlCustom = apiUrl || this.apiHostUrl
		const usecase = window.location.pathname.split('/')[2] === 'morsel' ? 'morsel' : null

		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', usecase, ...HEADER_PARAMS },
			// REFACTOR-NOTE: url should not be conditioned on existence of header params
			url: (HEADER_PARAMS && process.env.REACT_APP_API_HOST && process.env.REACT_APP_API_BASE_URL)
				? `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_BASE_URL}/whiteLabel/findWhDefTnt?$filter=status eq 'Published'`
				: `${apiUrlCustom}/whiteLabel/findWhDefTnt?$filter=status eq 'Published'`,
		}
		console.log(requestOptions, 'requestOptions')

		identityServices.identityApi.interceptors.request.use(config => {
			config.headers.common['x-tenantId'] = tenantUid
			return config
		})

		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200 && response.status !== 304) {
				return Promise.reject(response)
			}
			return response.data
		})
	}
}
export const whiteLabelService = new WhiteLabelServices()

import { BotData } from '../../reducers/bot.reducers'

// eslint-disable-next-line import/prefer-default-export
export function interpretBotDataActivities(conversationId: string, botDataActivities: BotData['activities']) {
	return conversationId && botDataActivities
		? botDataActivities?.map((c, i) => {
				c.from.name = c.sentBy || c.name
				// REFACTOR-NOTES: init channelData to empty object because the current bf-webchat needs this in the LiveActivityRegion component
				// or it dies when trying to access an undefined object
				// The better way to handle this is to have our bots save field and return via the botdef/configs endpoint
				//
				c.channelData = {}
				if (c.from && (!c.from.role || c.attachments || c.from.agentCheck)) c.from.role = 'bot'
				if (!c.attachments) delete c.attachments
				if (c.attachments) {
					const nextCard = botDataActivities?.[i + 1]
					if (nextCard && nextCard.cardInput && Object.keys(nextCard.cardInput).length > 0) {
						const { cardInput } = nextCard
						c.attachments.map(att => {
							att.content.body?.map(item => {
								const inputCardKeys = Object.keys(cardInput)
								if (item.id && inputCardKeys.indexOf(item.id) !== -1) {
									item.value = cardInput[item.id]
								}
								return item
							})
							return att
						})
					}
				}
				return c
		  })
		: []
}

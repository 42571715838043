import { createReducer } from '@reduxjs/toolkit'
import {
	clearIdentity,
	initiateLogin,
	loginFailure,
	loginSuccessMs,
	setIdentityActionCreator,
} from '../actions/identity.actions'

export interface IdentityState {
	isAuthenticated: boolean
	isInitiated: boolean
	userInfo: {
		sub?: string
		name?: string
		preferred_username?: string
		email?: string
	}
	roles?: string[]
	token: string | null
	profile?: {
		tenantId: string
		email: string
	}
	exp?: number
	timeSkew: number
	error?: any
}
const initialState: IdentityState = {
	isAuthenticated: false,
	isInitiated: false,
	userInfo: {},
	token: null,
} as IdentityState

export const identity = createReducer(
	initialState,
	builder =>
		builder
			.addCase(
				// case identityConstants.LOGIN_INITIATE:
				// 	return {
				// 		...state,
				// 		...action.payload,
				// 	}
				initiateLogin,
				(state, action) => ({
					...state,
					...action.payload,
				})
			)
			.addCase(
				// case identityConstants.LOGIN_SUCCESS:
				// return {
				// 	...state,
				// 	...action.payload,
				// }
				loginSuccessMs,
				(state, action) => ({
					...state,
					...action.payload,
				})
			)
			.addCase(
				// case identityConstants.LOGIN_FAILURE:
				// return {
				// 	...state,
				// 	...action.payload,
				// }
				loginFailure,
				(state, action) => ({
					...state,
					...action.payload,
				})
			)
			.addCase(
				// case identityConstants.SET_IDENTITY:
				// return {
				// 	...state,
				// 	...action.payload,
				// }
				setIdentityActionCreator,
				(state, action) => ({ ...state, ...action.payload })
			)
			.addCase(
				// case identityConstants.LOGOUT_SUCCESS:
				// return {
				// 	...initialState,
				// }
				clearIdentity,
				(state, action) => ({ ...initialState })
			)
	// .addCase
	// case identityConstants.SET_ROLE: //NOTE: this doesn't seem to be used, there is no action defined so keep it commented
	// 	return {
	// 		...state,
	// 		userInfo: { roles: action.payload },
	// 	}
	// ()
)

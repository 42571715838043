import { HEADER_PARAMS } from '../../../config'
import { identityServices } from '../../identity'

// TODO: see why options doesn't include apiUrl and apiModuleUrl like in ctor
const options = { urlItemName: 'botdef' }

class BotServices {
	apiUrl: string

	apiHostUrl: string

	constructor(options) {
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl') ?? ''
		this.apiHostUrl = options?.apiModuleUrl
			? `${options.apiUrl || this.apiUrl}/${options.apiModuleUrl}`
			: options?.apiUrl || this.apiUrl
	}

	getBotData(item: unknown, apiUrl: string) {
		const apiUrlCustom = apiUrl || this.apiHostUrl || (sessionStorage.getItem('apiUrl') as string)
		const requestOptions = {
			method: 'POST',
			data: item,
			headers: { 'Content-Type': 'application/json', ...HEADER_PARAMS },
			// REFACTOR-NOTE: url should not be conditioned on existence of header params
			url: apiUrlCustom ? `${apiUrlCustom}/${'botdef'}/configs` : `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_BASE_URL}/${'botdef'}/configs`,
		}

		return identityServices.identityApi.request(requestOptions).then(
			response =>
				// if (response.status !== 200) {
				//     return Promise.reject(response);
				// }

				response.data
		)
	}

	getNotificationCount(tenantId: string, email: string, apiUrl: string) {
		const apiUrlCustom = apiUrl || this.apiHostUrl || (sessionStorage.getItem('apiUrl') as string)
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', ...HEADER_PARAMS },
			url: `${apiUrlCustom}/notification/getAllUnreadNotification?tenantId=${tenantId}&email=${email}`,
		}

		return identityServices.identityApi.request<unknown>(requestOptions).then(
			response =>
				// if (response.status !== 200) {
				//     return Promise.reject(response);
				// }

				response.data
		)
	}

	getLangs(tenantId: string, apiUrl?: string) {
		const apiUrlCustom = apiUrl || this.apiHostUrl || (sessionStorage.getItem('apiUrl') as string)

		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 'x-tenantId': tenantId },
			url: `${apiUrlCustom}/botMessage?group=lang,tenantId&$select=lang,tenantId&$filter=status eq 'Published' and tenantId eq '${tenantId}'`,
		}

		return identityServices.identityApi.request<unknown>(requestOptions).then(response => response.data)
	}

	static getAllTenantConfig(tenantId: string, apiUrl: string) {
		const apiUrlCustom = apiUrl || (sessionStorage.getItem('apiUrl') as string)
		const usecase = window.location.pathname.split('/')[2] === 'morsel' ? 'morsel' : null

		const requestOptions = {
			headers: { Pragma: 'no-cache', 'Content-Type': 'application/json', usecase },
			url: `${apiUrlCustom}/tenantConfig`,
			method: 'GET',
		}
		// identityServices.identityApi.interceptors.request.use(config => {
		//     config.headers.common['x-tenantId'] = tenantId;
		//     return config
		// })

		return identityServices.identityApi.request<unknown>(requestOptions).then(response => {
			if (response.status !== 200) {
				return Promise.reject(response)
			}
			return response.data
		})
	}
}
// eslint-disable-next-line import/prefer-default-export
export const botService = new BotServices(options)

import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AppContext } from 'src/app/App'
import { getResetBot } from 'src/app/bot/actions/bot.actions'
import { selectTenant, useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { useWidgetUiContext } from '../WidgetUiContext'

function ResetBtn() {
	const { t } = useTranslation()
	const tenant = useAppSelector(selectTenant)
	const { resetBot } = useContext(AppContext)
	const { ls } = useWidgetUiContext()
	const dispatch = useAppDispatch()

	const handleReset = () => {
		const botItem = {
			tenantId: tenant.id,
			conversationId: null,
			learnigKey: null,
			token: '',
		}
		// localStorage.removeItem('chatInfo')
		// props.ls.remove('chatInfo')
		const localStrchatInfo = localStorage.getItem(tenant.tenantId || 'chatInfo')
		const localStrGuestUser = localStorage.getItem('isGuest')
		if (localStrchatInfo) {
			localStorage.removeItem(tenant.tenantId || 'chatInfo')
			ls.remove(tenant.tenantId || 'chatInfo')
		}
		if (localStrGuestUser) {
			localStorage.removeItem('isGuest')
			ls.remove('isGuest')
		}

		setTimeout(() => dispatch(getResetBot(botItem, tenant.apiUrl)), 1000)
		setTimeout(() => resetBot(), 2000)
	}

	return (
		<span
			className="reset-icon"
			onClick={handleReset}
			data-tooltip-id="bottom-dark-solid"
			data-tooltip-content={t('Reset')}>
			{/* <img src={restartIcon}></img> */}Reset
		</span>
	)
}
export default ResetBtn

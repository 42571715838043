import React, { createContext, useState } from 'react'
// Containers
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { persistor, store } from '../redux/store'
import { BotContainer, MobileProps } from './bot'

interface AppProps {
	tenantId: string
	authToken?: string
	userPayloadToken?: string
	callBack?: (...args: any[]) => void // TODO: flesh out this type definition
	mobileProps?: MobileProps | string

	// props
	showBotCustom?: unknown
	disableBeginButtonOnRefresh?: boolean
	gaCodeId?: string
	classNames?: string
	[prop: string]: any // TODO: temporary measure to transition to TS. We should flesh out actual type definition of props
}

interface AppContextType {
	resetBot: () => void
}
export const AppContext = createContext<AppContextType>({} as AppContextType)

function App({ mobileProps, ...props }: AppProps) {
	const [botContainer, setBotContainer] = useState(true)

	function parseMobileProps(): MobileProps | undefined {
		return typeof mobileProps === 'string' ? (JSON.parse(mobileProps) as MobileProps) : mobileProps
	}

	function resetBot() {
		setBotContainer(false)
		// TODO: if possible, find a more robust way to do this instead of setTimeout
		setTimeout(() => {
			setBotContainer(true)
		}, 3000)
	}

	// eslint-disable-next-line react/jsx-no-constructed-context-values
	const appCtxValue = { resetBot }

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<div className="App">
					<AppContext.Provider value={appCtxValue}>
						{botContainer ? <BotContainer mobileProps={parseMobileProps()} {...props} /> : <></>}
					</AppContext.Provider>
				</div>
			</PersistGate>
		</Provider>
	)
}

export default App

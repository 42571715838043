import axios, { AxiosInstance } from 'axios'
import { HEADER_PARAMS } from 'src/config'

export const identityApi: Readonly<AxiosInstance> = axios.create({
	timeout: 90000,
	responseType: 'json',
})
export const getQueryParameters = () => Object.fromEntries(new URLSearchParams(window.location.search))

export const setMsTokenByInterceptor = (msTokenSignedForUser: string, tenantUid: string) =>
	identityApi.interceptors.request.use(config => {
		config.headers.common = {
			...((config?.headers?.common ?? {}) as object),
			msTokenSignedForUser,
			'x-tenantId': tenantUid,
			'Content-Type': 'application/json',
			...HEADER_PARAMS,
		}
		return config
	})

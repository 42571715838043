import { faCompressAlt, faExpandAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef } from 'react'
import { useWidgetUiContext } from '../WidgetUiContext'

interface disable{
	enableShortcut:boolean
}
export default function MinMaxBtn({enableShortcut}:disable) {
	const { handleMinimize } = useWidgetUiContext()
	const buttonRef = useRef<HTMLButtonElement>(null);
	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			let findIsClosed:any = document.querySelectorAll('div.close-bot') || null
			let isMac = false;
			if ((navigator as any).userAgentData) {
				isMac = (navigator as any).userAgentData.platform === 'macOS';
			  } else {
				// Fallback to userAgent for older browsers
				isMac = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);
			  }
            // console.log("mac is",isMac)
		  if (event.ctrlKey && event.key.toLowerCase() === 'm' && (!(findIsClosed && findIsClosed.length)) && isMac) {
			// console.log("close")
			buttonRef.current?.click();
		  }else if (event.altKey && event.key.toLowerCase() === 'm' && (!(findIsClosed && findIsClosed.length)) && !isMac) {
			// console.log("close")
			buttonRef.current?.click();
		  }
		};
		window.addEventListener('keydown', handleKeyDown);
		return () => {
		  window.removeEventListener('keydown', handleKeyDown);
		};
	  }, []);

	return (
		<span
			className="restart-icon btn-mazimize"
			ref={enableShortcut?buttonRef:null}  
			onClick={() => {
				handleMinimize()
				// refreshRendor()
			}}>
			<FontAwesomeIcon className="btn-mazimize-icon" color="white" icon={faExpandAlt} />
			<FontAwesomeIcon className="btn-nimimize-icon" color="white" icon={faCompressAlt} />
		</span>
	)
}

/**
 * may not be needed once moved to "entity" module parent structure
 */
import { createAction } from '@reduxjs/toolkit'
import type { AppDispatch } from 'src/redux/store'
import * as settingsConstants from '../constants/settings.constants'
import * as settingsServices from '../services/settings.services'
import * as alertActions from './alert.actions'

// const changeStatusRequest = () => ({
// 	type: settingsConstants.CHANGE_STATUS,
// })
const changeStatusRequest = createAction(settingsConstants.CHANGE_STATUS)

// const changeStatusSuccess = setting => ({
// 	type: settingsConstants.CHANGE_STATUS,
// 	payload: {
// 		...setting,
// 	},
// })
// NOTE: looks like this ^^ is a bug and should be CHANGE_STATUS_SUCCESS (otherwise we have 2 duplicate action creators)
// Changing it to CHANGE_STATUS_SUCCESS here (June 15, 2003)
export const changeStatusSuccess = createAction(settingsConstants.CHANGE_STATUS_SUCCESS, (setting: any) => ({
	payload: { ...setting },
}))

// const changeStatusFailure = error => ({
// 	type: settingsConstants.CHANGE_STATUS_FAILURE,
// 	payload: {
// 		error,
// 	},
// })
const changeStatusFailure = createAction<any>(settingsConstants.CHANGE_STATUS_FAILURE)

// const getAllGlobalSettingsRequest = () => ({
// 	type: settingsConstants.GETALL_REQUEST,
// })
export const getAllGlobalSettingsRequest = createAction(settingsConstants.GETALL_REQUEST)

// const getAllGlobalSettingsSuccess = setting => ({
// 	type: settingsConstants.GETALL_SUCCESS,
// 	payload: {
// 		...setting,
// 	},
// })
export const getAllGlobalSettingsSuccess = createAction(settingsConstants.GETALL_SUCCESS, (setting: any) => ({
	payload: { ...setting },
}))

// const getAllGlobalSettingsFailure = error => ({
// 	type: settingsConstants.GETALL_FAILURE,
// 	payload: {
// 		error,
// 	},
// })
const getAllGlobalSettingsFailure = createAction<any>(settingsConstants.GETALL_FAILURE)

// to be deleted
export const changeStatus = (status: string, id: string, apiUrl: string) => dispatch => {
	dispatch(changeStatusRequest())

	settingsServices.changeStatus(status, id, apiUrl).then(
		setting => {
			if (setting) {
				dispatch(changeStatusSuccess(setting))
			} else {
				const error = 'Cannot update status at this moment try again later'
				dispatch(changeStatusFailure(error))
				dispatch(alertActions.error(error))
			}
		},
		error => {
			dispatch(changeStatusFailure(error))
			dispatch(alertActions.error(error))
		}
	)
}

export const getAllGlobalSettings = (tenantId, userName, apiUrl) => async (dispatch: AppDispatch) => {
	dispatch(getAllGlobalSettingsRequest())
	// debugger;
	await settingsServices.getAllGlobalSettings(tenantId, apiUrl).then(
		data => {
			if (data) {
				dispatch(getAllGlobalSettingsSuccess(data))
			} else {
				const errorMsg = 'Unable to fetch settings from server try refreshing the page'
				dispatch(getAllGlobalSettingsFailure(errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		},
		error => {
			const errorMsg = 'Unable to fetch settings from server try refreshing the page'
			dispatch(getAllGlobalSettingsFailure(errorMsg))
			dispatch(alertActions.error(errorMsg))
		}
	)
}

// const updateGlobalSettingsRequest = () => ({
// 	type: settingsConstants.UPDATE_GLOBAL_SETTING_REQUEST,
// })
export const updateGlobalSettingsRequest = createAction(settingsConstants.UPDATE_GLOBAL_SETTING_REQUEST)

// const updateGlobalSettingsSuccess = setting => ({
// 	type: settingsConstants.UPDATE_GLOBAL_SETTING_SUCCESS,
// 	payload: {
// 		...setting,
// 	},
// })
export const updateGlobalSettingsSuccess = createAction(
	settingsConstants.UPDATE_GLOBAL_SETTING_SUCCESS,
	(setting: any) => ({
		payload: { ...setting },
	})
)

// const updateGlobalSettingsFailure = error => ({
// 	type: settingsConstants.UPDATE_GLOBAL_SETTING_FAILURE,
// 	payload: {
// 		error,
// 	},
// })
export const updateGlobalSettingsFailure = createAction(
	settingsConstants.UPDATE_GLOBAL_SETTING_FAILURE,
	(error: any) => ({
		payload: { error },
	})
)

export const updateGlobalSetting = (settingData, settingId, apiUrl) => async (dispatch: AppDispatch) => {
	dispatch(updateGlobalSettingsRequest())

	await settingsServices.updateGlobalSetting(settingData, settingId, apiUrl).then(
		setting => {
			if (setting) {
				dispatch(updateGlobalSettingsSuccess(setting))
				dispatch(alertActions.success('updated successfully.'))
			} else {
				const errorMsg = 'Unable to update settings try refreshing the page'
				dispatch(updateGlobalSettingsFailure(errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		},
		error => {
			const errorMsg = 'Unable to update settings try refreshing the page'
			dispatch(updateGlobalSettingsFailure(errorMsg))
			dispatch(alertActions.error(errorMsg))
		}
	)
}

// const getCustomerSettingsRequest = () => ({
// 	type: settingsConstants.GET_SETTINGS_REQUEST,
// })
export const getCustomerSettingsRequest = createAction(settingsConstants.GET_SETTINGS_REQUEST)

// const getCustomerSettingsSuccess = setting => ({
// 	type: settingsConstants.GET_SETTINGS_SUCCESS,
// 	payload: {
// 		...setting,
// 	},
// })
export const getCustomerSettingsSuccess = createAction(settingsConstants.GET_SETTINGS_SUCCESS, (setting: object) => ({
	payload: { ...setting },
}))

// const getCustomerSettingsFailure = error => ({
// 	type: settingsConstants.GET_SETTINGS_FAILURE,
// 	payload: {
// 		error,
// 	},
// })
export const getCustomerSettingsFailure = createAction<any>(settingsConstants.GET_SETTINGS_FAILURE)

export const getSettingByCustomer = (customerName, apiUrl) => async (dispatch: AppDispatch) => {
	dispatch(getCustomerSettingsRequest())

	await settingsServices.getSettingByCustomer(customerName, apiUrl).then(
		setting => {
			dispatch(getCustomerSettingsSuccess(setting))
			if (
				window.location.pathname === '/' &&
				setting &&
				setting.setting &&
				setting.setting.setting &&
				setting.setting.setting.isSignupHide
			)
				console.log("history.push('/login')")
		},
		error => {
			const errorMsg = 'Unable to fetch customer settings from server try refreshing the page'
			dispatch(getCustomerSettingsFailure(errorMsg))
		}
	)
}

// const getSsoDataRequest = () => ({
// 	type: settingsConstants.GET_SSODATA_REQUEST,
// })
const getSsoDataRequest = createAction(settingsConstants.GET_SSODATA_REQUEST)

// const getSsoDataSuccess = data => ({
// 	type: settingsConstants.GET_SSODATA_SUCCESS,
// 	payload: {
// 		...data,
// 	},
// })
const getSsoDataSuccess = createAction(settingsConstants.GET_SSODATA_SUCCESS, (data: object) => ({
	payload: { ...data },
}))

// const getSsoDataFailure = error => ({
// 	type: settingsConstants.GET_SSODATA_FAILURE,
// 	payload: {
// 		error,
// 	},
// })
const getSsoDataFailure = createAction<any>(settingsConstants.GET_SSODATA_FAILURE)

export const getSsoData = (tenanId: string, apiUrl: string) => async (dispatch: AppDispatch) => {
	dispatch(getSsoDataRequest())

	await settingsServices.getSsoData(tenanId, apiUrl).then(
		data => {
			dispatch(getSsoDataSuccess(data))
		},
		error => {
			const errorMsg = 'Unable to fetch customer settings from server try refreshing the page'
			dispatch(getSsoDataFailure(errorMsg))
		}
	)
}

// const getTenantConfigRequest = () => ({
// 	type: settingsConstants.GET_TENANT_CONFIG_REQUEST,
// })
const getTenantConfigRequest = createAction(settingsConstants.GET_TENANT_CONFIG_REQUEST)

const getTenantConfigSuccess = setting => ({
	type: settingsConstants.GET_TENANT_CONFIG_SUCCESS,
	payload: {
		...setting,
	},
})
// const getTenantConfigSuccess = createAction<any>(settingsConstants.GET_TENANT_CONFIG_SUCCESS, setting => ({
// 	payload: { ...setting },
// }))

// const getTenantConfigFailure = error => ({
// 	type: settingsConstants.GET_TENANT_CONFIG_FAILURE,
// 	payload: {
// 		error,
// 	},
// })
const getTenantConfigFailure = createAction<any>(settingsConstants.GET_TENANT_CONFIG_FAILURE)

export const getTenantConfig =
	(configType: string, tenantId: string, apiUrl: string) => async (dispatch: AppDispatch) => {
		dispatch(getTenantConfigRequest())

		await settingsServices.getTenantConfig(configType, tenantId, apiUrl).then(
			data => {
				if (data) {
					dispatch(getTenantConfigSuccess(data))
				} else {
					const errorMsg = 'Unable to fetch Tenant Config from server try refreshing the page'
					dispatch(alertActions.error(errorMsg))
				}
			},
			error => {
				const errorMsg = 'Unable to fetch Tenant Config from server try refreshing the page'
				dispatch(getTenantConfigFailure(errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		)
	}

// const getAllTenantConfigRequest = () => ({
// 	type: settingsConstants.GETALL_TENANT_CONFIG_REQUEST,
// })
export const getAllTenantConfigRequest = createAction(settingsConstants.GETALL_TENANT_CONFIG_REQUEST)

// const getAllTenantConfigSuccess = setting => ({
// 	type: settingsConstants.GETALL_TENANT_CONFIG_SUCCESS,
// 	payload: setting,
// })
export const getAllTenantConfigSuccess = createAction<any>(settingsConstants.GETALL_TENANT_CONFIG_SUCCESS)

// const getAllTenantConfigFailure = error => ({
// 	type: settingsConstants.GETALL_TENANT_CONFIG_FAILURE,
// 	payload: {
// 		error,
// 	},
// })
export const getAllTenantConfigFailure = createAction(settingsConstants.GETALL_TENANT_CONFIG_FAILURE, error => ({
	payload: { error },
}))

export const getAllTenantConfig = (tenantId: string, apiUrl: string) => async (dispatch: AppDispatch) => {
	dispatch(getAllTenantConfigRequest())

	await settingsServices.getAllTenantConfig(tenantId, apiUrl).then(
		data => {
			if (data) {
				dispatch(getAllTenantConfigSuccess(data))
			} else {
				const errorMsg = 'Unable to fetch Tenant Config from server try refreshing the page'
				dispatch(alertActions.error(errorMsg))
			}
		},
		error => {
			const errorMsg = 'Unable to fetch Tenant Config from server try refreshing the page'
			dispatch(getAllTenantConfigFailure(errorMsg))
			dispatch(alertActions.error(errorMsg))
		}
	)
}

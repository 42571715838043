export const GETALL_REQUEST = 'GETALL_REQUEST_SETTING'
export const GETALL_SUCCESS = 'GETALL_SUCCESS_SETTING'
export const GETALL_FAILURE = 'GETALL_FAILURE_SETTING'
export const CHANGE_STATUS = 'CHANGE_STATUS_SETTING'
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS_SETTING'
export const CHANGE_STATUS_FAILURE = 'CHANGE_STATUS_FAILURE_SETTING'
export const UPDATE_GLOBAL_SETTING_REQUEST = 'UPDATE_GLOBAL_SETTING_REQUEST'
export const UPDATE_GLOBAL_SETTING_SUCCESS = 'UPDATE_GLOBAL_SETTING_SUCCESS'
export const UPDATE_GLOBAL_SETTING_FAILURE = 'UPDATE_GLOBAL_SETTING_FAILURE'
export const GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST'
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS'
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE'
export const GET_SSODATA_REQUEST = 'GET_SSODATA_REQUEST'
export const GET_SSODATA_SUCCESS = 'GET_SSODATA_SUCCESS'
export const GET_SSODATA_FAILURE = 'GET_SSODATA_FAILURE'
export const GET_TENANT_CONFIG_REQUEST = 'GET_TENANT_CONFIG_REQUEST'
export const GET_TENANT_CONFIG_SUCCESS = 'GET_TENANT_CONFIG_SUCCESS'
export const GET_TENANT_CONFIG_FAILURE = 'GET_TENANT_CONFIG_FAILURE'

export const GETALL_TENANT_CONFIG_REQUEST = 'GETALL_TENANT_CONFIG_REQUEST'
export const GETALL_TENANT_CONFIG_SUCCESS = 'GETALL_TENANT_CONFIG_SUCCESS'
export const GETALL_TENANT_CONFIG_FAILURE = 'GETALL_TENANT_CONFIG_FAILURE'

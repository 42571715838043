import { toast } from 'react-toastify'
import * as alertConstants from '../constants/alert.constants'

export const success = message => dispatch => {
	dispatch({ type: alertConstants.SUCCESS, payload: message })
	toast.success(message)
	dispatch(clear())
}

export const info = message => dispatch => {
	dispatch({ type: alertConstants.INFO, payload: message })
	toast.info(message)
	dispatch(clear())
}

export const error = message => dispatch => {
	dispatch({ type: alertConstants.ERROR, payload: message })
	if (message && Array.isArray(message) && message.length > 0) {
		message.map((emsg, idx) => {
			toast.error(emsg.message)
		})
	} else toast.error(message)
	dispatch(clear())
}

export const clear = () => ({ type: alertConstants.CLEAR })

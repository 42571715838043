import { changeLanguage } from 'i18next'
import React, { useCallback } from 'react'
import type { BotData } from 'src/app/bot/reducers/bot.reducers'
import { saveTenantId } from 'src/app/identity/actions/tenant.actions'
import { selectTenant, useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { useWidgetUiContext } from '../WidgetUiContext'

interface LocaleSelectorProps {
	langs: BotData['langs']
	authToken: string
	botType: any
	botClientType: any
	userId: string
	userAttributes: any
	flowName: string
}
function LocaleSelector({
	langs,
	authToken,
	botType,
	userId,
	userAttributes,
	flowName,
	botClientType,
}: LocaleSelectorProps) {
	const tenant = useAppSelector(selectTenant)
	const { locale, setLocale } = useWidgetUiContext()
	const dispatch = useAppDispatch()

	const handleLocaleChange = useCallback(
		(event: React.ChangeEvent<HTMLSelectElement>) => {
			;(async function change() {
				const newLocale = event?.target?.value
				if (!newLocale) return true
				await changeLanguage(newLocale)

				const tenantData = {
					tenantId: tenant.id,
					userId,
					authUserId: authToken || '',
					locale: newLocale,
					userAttributes,
					userIntent: flowName || null,
					botType,
					botClientType,
					source: 'localeChange',
					apiUrl: tenant.apiUrl,
				}
				
				dispatch(saveTenantId(tenantData))
				setLocale(newLocale || locale) // TODO: why do we need to have a locale state if it is already tracked by i18n and tenantData
				sessionStorage.setItem('toggleLang', 'false')
				return true
			})()

			return null
		},
		[
			locale,
			setLocale,
			authToken,
			botType,
			botClientType,
			flowName,
			tenant.id,
			tenant.apiUrl,
			userAttributes,
			userId,
			dispatch,
		]
	)

	return langs && langs.length > 1 ? (
		<span className="restart-icon locale-select">
			<select className="locale-select-con" onChange={handleLocaleChange} defaultValue={(typeof locale === 'string' && locale.split('-') && locale.split('-').length) ? locale.split('-')[0] :""}>
				{langs.map(({ lang }, _i) => (
					<option key={lang} value={lang}>
						{lang.toUpperCase()}
					</option>
				))}
			</select>
		</span>
	) : null
}
export default LocaleSelector

import ReactWebChat, { createDirectLine } from 'botframework-webchat'
import { changeLanguage } from 'i18next'
import React, { useCallback, useContext, useEffect, useRef, useMemo, useState } from 'react'
import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import { getAllTenantConfig } from 'src/app/identity/actions/settings.actions'
import {
	selectBotData,
	selectBotToken,
	selectGlobalSettingTenantConfig,
	selectTenant,
	useAppDispatch,
	useAppSelector,
} from 'src/redux/hooks'
import { BaseProps, ChatInfo, MobileProps } from '../../interfaces'
import { WhiteLabelTheme } from '../../reducers/whiteLabel.reducers'
import ChatHeader from '../ChatHeader/ChatHeader'
import Upload from '../Upload'
import { useWidgetUiContext } from '../WidgetUiContext'
import { isJson } from '../utils'
import useActivityStatusMiddleware from '../webchat-middleware/ActivityStatusMiddleware'
import useAttachmentMiddleware from '../webchat-middleware/AttachmentMiddleWare'
import ChatOptions from './ChatOptions'
import PoweredBy from './PoweredBy'
import SendBoxClickToBegin from './SendBoxClickToBegin'
import SendBoxGrayOut from './SendBoxGrayOut'
import { interpretBotDataActivities } from './helpers'
import useComplexCreateStore from './useComplexCreateStore'
import { getResetBot } from '../../actions/bot.actions'
import { AppContext } from 'src/app/App'
import './style.css';

interface MinimizableWebChatProps {
	userId: string
	botAvatar: string
	agentAvatar: string
	username: string
	botName: string
	chatData: string
	user: {
		id: string
		userFullName: string
		username: string
		attributes: object
		channelAgent: string
		email: string
	}
	botTheme: string
	mobileData: MobileProps
	menuFlow: any
	theme: WhiteLabelTheme
	userAttributes: object
	conversationId: string
	isConnetedToAgent: boolean
	isEnableLastAC: boolean
	pgCtx?: string
	botId: string
	botType: string
	botClientType: string
	baseProps: BaseProps
	mobileProps: MobileProps
	disableBeginButtonOnRefresh: boolean
	uploadButton: unknown
	learnigKey: string
	showBotCustom: unknown
	gaCodeId?: string
	classNames?: string
	authToken: string
	flowName: string
	botStudioBot: unknown
	isSupportBot: any
	metadata: any
	isVirtualagent: any
	aiBot: any
	searchValue: any
	inSearch: any
}
function MinimizableWebChat({
	userId,
	showBotCustom,
	chatData,
	botId,
	botType,
	learnigKey,
	pgCtx,
	conversationId,
	disableBeginButtonOnRefresh,
	theme,
	mobileData,
	gaCodeId,
	classNames = '',
	authToken,
	flowName,
	botClientType,
	botStudioBot,
	...props
}: MinimizableWebChatProps) {
	const tenant = useAppSelector(selectTenant)
	const tenantConfig = useAppSelector(selectGlobalSettingTenantConfig)
	const botToken = useAppSelector(selectBotToken)
	const botData = useAppSelector(selectBotData)

	const { t } = useTranslation()
	const dispatch = useAppDispatch()

	const {
		uiClassNames,
		chatContainerClasses,
		uiTheme,
		displayTooltip,
		setDisplayTooltip,
		disableSendBox,
		hideDisconnect,
		handleMinimize,
		chatMinimize,
		regEvent,
		setRegEvent,
		isregisteredEvents,
		setIsregisteredEvents,
		setShowResetBtn,
		locale,
		ls,
	} = useWidgetUiContext()

	const [clickToBegin, setClickToBegin] = useState(!(conversationId && disableBeginButtonOnRefresh))
	const [hideSendBox, setHideSendBox] = useState(!(conversationId && disableBeginButtonOnRefresh))
	const [isEnableLastAC, setIsEnableLastAC] = useState(props.isEnableLastAC)
	// const [mobileData, setMobileData] = useState(props.mobileData)
	const [isConnetedToAgent, setIsConnetedToAgent] = useState(props.isConnetedToAgent || false)
	const [hideUploadButton, setHideUploadButton] = useState(props.uploadButton ? false : !props.isConnetedToAgent)
	const [enableShortcut, setenableShortcut] = useState((!props.isSupportBot && !botStudioBot) || false)

	const lsAgentChatActivate = useCallback(() => {
		const chatInfo = ls.get(pgCtx || 'chatInfo') as ChatInfo
		if (chatInfo && !chatInfo.isConnetedToAgent) {
			chatInfo.isConnetedToAgent = true
			ls.set(pgCtx || 'chatInfo', chatInfo)
		}
	}, [ls, pgCtx])

	const lsAgentChatDeactivate = useCallback(() => {
		const chatInfo = ls.get(pgCtx || 'chatInfo') as ChatInfo
		if (chatInfo && chatInfo.isConnetedToAgent) {
			chatInfo.isConnetedToAgent = false
			ls.set(pgCtx || 'chatInfo', chatInfo)
		}
	}, [ls, pgCtx])

	useEffect(() => {
		if (window.location.href.includes('/virtualagent')) dispatch(getAllTenantConfig(tenant.id, tenant.apiUrl))
	}, [dispatch, tenant.id, tenant.apiUrl])

	useEffect(() => {
		tenantConfig?.forEach(tC => {
			if (tC.name === 'showResetBtn') {
				if (tC.properties || tC.properties === 'true') {
					setShowResetBtn(true)
				}
			}
		})
	}, [tenantConfig, setShowResetBtn])

	const activities = interpretBotDataActivities(conversationId, botData?.activities)
	const chatItemsRef = useRef<NodeListOf<HTMLDivElement> | null>(null);
	const currentIndexRef = useRef<number>(0);
	const ariaLabelsRef = useRef<NodeListOf<HTMLDivElement> | null>(null);
	const ariaIndexRef = useRef<number>(0);
	const focusedParentRef = useRef<HTMLElement | null>(null);
	const isAriaTraversal = useRef<boolean>(false);

	const getAriaLabels = useCallback((parent: HTMLElement) => {
		let CollectAria = parent.querySelectorAll<HTMLDivElement>('[aria-label] ,.ac-textBlock, .ac-selectable .ac-textBlock  ,.ac-input-container,.ac-textRun ,.ac-image.ac-selectable');
		ariaLabelsRef.current = CollectAria && Array.from(CollectAria).filter(el => {
			//return el.tagName.toLowerCase() !== 'option'
			let av = el?.innerHTML || null;
			av = av && av?.trim() ? av.trim() : av;
			return el.tagName.toLowerCase() !== 'input' && el.tagName.toLowerCase() !== 'label' && el.tagName.toLowerCase() !== 'option' && el.ariaLabel !== "★★★★★" && el.ariaLabel !== "★★★★" && el.ariaLabel !== "★★★" && el.ariaLabel !== "★★" && el.ariaLabel !== "★" && av !== "*"
		})
		//console.log(ariaLabelsRef,"aria catched")
	}, []);


	const getChatItems = useCallback(() => {
		chatItemsRef.current = document.querySelectorAll<HTMLDivElement>(
			'.webchat__basic-transcript__scrollable .webchat__stacked-layout__message , .webchat__basic-transcript__scrollable .webchat__stacked-layout__attachment'
		);
		// console.log(chatItemsRef.current,"query")
	}, []);

	const resetTraversal = useCallback(() => {
		currentIndexRef.current = 0;
		isAriaTraversal.current = false;
		ariaLabelsRef.current = null;
		chatItemsRef.current = null;
		const prevFocusedItem = document.querySelector<HTMLDivElement>('.focused-chat-item');
		if (prevFocusedItem) {
			prevFocusedItem.classList.remove('focused-chat-item');
		}
		const prevFocusedAriaItem = document.querySelector<HTMLDivElement>('.webchat__basic-transcript__scrollable .focused-aria-item');
		if (prevFocusedAriaItem) {
			prevFocusedAriaItem.classList.remove('focused-aria-item');
		}
		getChatItems(); // Re-fetch chat items
	}, [getChatItems]);


	const scrollIntoViewIfNeeded = useCallback((element: HTMLElement | null) => {

		const scrollableDiv = document.querySelector(".webchat__basic-transcript__scrollable");

		if (scrollableDiv && element) {
			const containerRect = scrollableDiv.getBoundingClientRect();
			const elementRect = element.getBoundingClientRect();
			// Calculate desired scroll position
			const elementTop = element.offsetTop - scrollableDiv.offsetTop;
			const elementBottom = elementTop + elementRect.height;
			//   console.log(elementRect.top,elementRect.top % containerRect.top,containerRect,elementRect,elementTop,elementBottom,element.offsetTop ,scrollableDiv.offsetTop,"scrolling")
			// Determine if element is above or below visible area
			if (elementTop) {
				let move = 0;
				if (elementRect.top <= containerRect.height)
					move = elementRect.top - containerRect.top
				else
					move = elementRect.top - containerRect.top
				//console.log("move ",move,scrollableDiv.scrollTop + move)
				scrollableDiv.scrollTo({ top: scrollableDiv.scrollTop + move, behavior: 'smooth' });
			}

		}

	}, []);

	useEffect(() => {
		const prevFocusedItem = document.querySelector<HTMLDivElement>('.focused-chat-item');
		if (prevFocusedItem) {
			prevFocusedItem.classList.remove('focused-chat-item');
		}
		const prevFocusedAriaItem = document.querySelector<HTMLDivElement>('.webchat__basic-transcript__scrollable .focused-aria-item');
		if (prevFocusedAriaItem) {
			prevFocusedAriaItem.classList.remove('focused-aria-item');
		}
		getChatItems()
		resetTraversal()
		// console.log("render")
	}, [getChatItems, activities, clickToBegin, hideUploadButton, hideUploadButton, isConnetedToAgent, isEnableLastAC, botToken, conversationId, ls, pgCtx, setRegEvent])


	const focusChatItem = useCallback((index: number) => {
		if (!chatItemsRef.current || chatItemsRef.current.length === 0) return;

		// Remove focus from previously focused item
		const prevFocusedItem = document.querySelector<HTMLDivElement>('.focused-chat-item');
		if (prevFocusedItem) {
			prevFocusedItem.classList.remove('focused-chat-item');
		}

		// Focus and scroll to the specified chat item
		const item = chatItemsRef.current[index];
		if (item) {
			item.classList.add('focused-chat-item'); // Add red border
			item.focus();
			scrollIntoViewIfNeeded(item);
			getAriaLabels(item)
			if (ariaLabelsRef.current && ariaLabelsRef.current.length > 0) {
				//console.log("controller aria-label",currentIndexRef,ariaIndexRef)
				isAriaTraversal.current = true;
				ariaIndexRef.current = 0; // Reset index for aria-label traversal
			} else {
				isAriaTraversal.current = false;
			}
		}

	}, [scrollIntoViewIfNeeded, getAriaLabels, getChatItems]);

	const focusAriaLabel = useCallback(() => {
		//console.log("function entered")
		if (!ariaLabelsRef.current || ariaLabelsRef.current.length === 0) return;

		// Remove focus from previously focused aria-label item
		const prevFocusedItem = document.querySelector<HTMLDivElement>('.webchat__basic-transcript__scrollable .focused-aria-item');
		if (prevFocusedItem) {
			prevFocusedItem.classList.remove('focused-aria-item');
		}

		// Focus and scroll to the next aria-label item
		if (ariaIndexRef.current < ariaLabelsRef.current.length) {
			const item = ariaLabelsRef.current[ariaIndexRef.current];
			if (item) {
				if (item.tagName.toLowerCase() === 'button' && (item.getAttribute('aria-label') === 'Submit' || item.getAttribute('aria-label') === 'Cancel')) {
					// let item = item && item.tagName
					let getDiv: any = item.querySelector('div')
					// console.log("aria button handler 1",getDiv,item)
					getDiv.classList.add('focused-aria-item')
					getDiv.focus();
					ariaIndexRef.current++;
				} else {
					setTimeout(() => {
						item.classList.add('focused-aria-item'); // Add red border
						item.focus();
						ariaIndexRef.current++;

					}, 150)
				}

			}
		} else {
			// Reset index when reaching the bottom and exit aria-label traversal mode
			ariaIndexRef.current = 0;
			isAriaTraversal.current = false;

			// Focus the parent chat item
			if (focusedParentRef.current) {
				focusedParentRef.current.focus();
				scrollIntoViewIfNeeded(focusedParentRef.current);
			}
		}
	}, [scrollIntoViewIfNeeded]);



	const focusPreviousChatItem = useCallback(() => {
		if (!chatItemsRef.current || chatItemsRef.current.length === 0) return;

		currentIndexRef.current = currentIndexRef.current % chatItemsRef.current.length;

		const index = chatItemsRef.current.length - 1 - currentIndexRef.current;
		focusChatItem(index);
		currentIndexRef.current++;

	}, [focusChatItem, focusAriaLabel]);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			let findIsClosed: any = document.querySelectorAll('div.close-bot') || null
			if (event.key === 'Tab' && (!(findIsClosed && findIsClosed.length))) {
				event.preventDefault();
				if (isAriaTraversal.current) {
					focusAriaLabel();
				} else {
					focusPreviousChatItem();
				}
				//focusPreviousChatItem();
			} else if (event.key === 'Enter' && isAriaTraversal.current && (!(findIsClosed && findIsClosed.length))) {
				const focusedItem = document.querySelector<HTMLDivElement>('.webchat__basic-transcript__scrollable .focused-aria-item');
				if (focusedItem) {
					focusedItem.click();
					getChatItems()
					resetTraversal()
				}
			}
		};

		if (enableShortcut) {
			window.addEventListener('keydown', handleKeyDown);
		}

		// Get all elements with the class name 'className'
		const elements = document.querySelectorAll(
			'.chat-container, [data-id^="chat-item-"] '
		);

		setTimeout(() => {
			// Attach a click event listener to each element
			elements.forEach(element => {
				element.addEventListener('click', () => {
					// console.log('Element clicked:', element);
					getChatItems()
					resetTraversal()

				});
			});
		}, 150)

		getChatItems(); // Initial load
		resetTraversal()

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [focusPreviousChatItem, getChatItems, focusAriaLabel, activities, conversationId]);

	// Listen for changes in chat items and update focus accordingly
	useEffect(() => {
		const observer = new MutationObserver(() => {
			// Re-query and update chat items when changes occur
			getChatItems();
		});

		// Observe changes in the chat container
		const chatContainer = document.querySelector("div[role='complementary']");
		if (chatContainer) {
			observer.observe(chatContainer, { childList: true, subtree: true });
		}

		return () => {
			observer.disconnect(); // Clean up observer on unmount
		};
	}, [getChatItems, getChatItems, getAriaLabels, resetTraversal, activities, conversationId]);

	// NOTE: factor this complex store setup process out to a different file
	const complexCreateStore = useComplexCreateStore({
		activities,
		setHideUploadButton,
		lsAgentChatActivate,
		lsAgentChatDeactivate,
		botId,
		botStudioBot,
		learnigKey,
		user: props.user,
		conversationId,
		chatData,
		pgCtx,
		isEnableLastAC,
		setIsEnableLastAC,
		setIsConnetedToAgent,
		iframeScrollMeta: props?.metadata?.chatHeaderIcon
	})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const store = useMemo(complexCreateStore, [])

	// REFACTOR-NOTE: commented out because the rerender line was already commented out prior to refactor
	// store.subscribe(() => {
	// 	const { activities } = store.getState()
	// 	// reRendorDOM(activities)
	// })

	const directLine = useMemo(() => createDirectLine({ token: botToken, conversationId }), [botToken, conversationId])

	// REFACTOR-NOTE: commented out because not used anywhere
	// const styleSet = useMemo(
	// 	() =>
	// 		createStyleSet({
	// 			backgroundColor: 'Transparent',
	// 		}),
	// 	[]
	// )

	// NOTE: commented because not used anywhere
	// const disableSendBox = useCallback(
	// 	(disable = false) => {
	// 		let sendInput = document.querySelector('[data-id="webchat-sendbox-input"]')
	// 		if (!sendInput) return true
	// 		if (disable) {
	// 			// @ts-ignore
	// 			sendInput.disabled = disable
	// 			sendInput.classList.add('grayBtn')
	// 			setDisableSendBox(true)
	// 		} else {
	// 			// @ts-ignore
	// 			sendInput.disabled = disable
	// 			sendInput.classList.remove('grayBtn')
	// 			setDisableSendBox(false)
	// 		}
	// 	},
	// 	[disableSendBox, setDisableSendBox]
	// )

	const styleOptions = {
		botAvatarImage: (!hideDisconnect || isConnetedToAgent) && props.agentAvatar ? props.agentAvatar : props.botAvatar,
		avatarSize: 35,
		botAvatarInitials: '', // props.botAvatar ? '' : 'B',
		botAvatarBackgroundColor: 'transparent !important',
		// userAvatarImage: props.userAvatar,
		// userAvatarInitials: props.username || 'User',
		hideUploadButton: true, // hideUploadButton,
		bubbleBackground: '#FFF',
		hideSendBox,
		backgroundColor: '#FFF',
		groupTimestamp: false,
		// autoScrollSnapOnActivity:
		// 	(props.theme && props.theme.autoScrollSnapOnActivity && /true/i.test(props.theme.autoScrollSnapOnActivity)) || props.autoScrollSnapOnActivity ? 2 : false,
		// autoScrollSnapOnActivityOffset: 200,
		transcriptActivityVisualKeyboardIndicatorColor: 'transparent',
		transcriptVisualKeyboardIndicatorColor: 'transparent',
		bubbleNubSize: 0,
		bubbleNubOffset: 0,
		adaptiveCardsParserMaxVersion: '1.5',
		typingAnimationDuration: 30000,
		autoScrollSnapOnPage: true,
		autoScrollSnapOnActivity: 3,
		hideScrollToEndButton: false,
		// markdownExternalLinkIconImage:'',
		// transcriptActivityVisualKeyboardIndicatorStyle: 'transparent',
		// transcriptVisualKeyboardIndicatorStyle: 'transparent'
	}

	function setBotOpenOrClose() {
		if (theme) {
			const el = document.querySelector('.chat-container')
			if (theme) {
				let showBotYN = /true/i.test(showBotCustom || theme.showBot)
				if (
					// TODO: why are we doing this parsing? The result is not used anywhere. If parsing is needed, do it earlier on when we parse mobileProps
					typeof mobileData?.mobileApp === 'string' && isJson(mobileData?.mobileApp)
						? JSON.parse(mobileData.mobileApp)
						: mobileData?.mobileApp
				)
					showBotYN = true
				if (el && showBotYN) el.classList.remove('close-bot')
				else if (el && el.classList) el.classList.add('close-bot')
			}
		}
	}

	const regEventFun = useCallback(() => {
		setRegEvent(true)
		const dElement = document.querySelector('.chat-container')
		dElement?.addEventListener('DOMNodeInserted', e => {
			const cardEl = document.querySelectorAll('.chat-container .ac-adaptiveCard')
			if (cardEl) {
				for (let i = 0; i < cardEl.length; i++) {
					const pEl = cardEl[i].querySelector('.ac-container .ac-textBlock p') as HTMLElement
					const paragraphTextForAna = pEl?.innerText
					if (
						paragraphTextForAna != null &&
						(paragraphTextForAna.search(t('Did you mean')) !== -1 ||
							paragraphTextForAna.search(t('Please help me by choosing area of your interest from below')) !== -1 ||
							paragraphTextForAna.search(t('these possible matches I found')) !== -1 ||
							paragraphTextForAna.search(t('Plesae review these additional matches')) !== -1 ||
							paragraphTextForAna.search(t('Thank you! Please select the option below related to your issue')) !== -1)
					) {
						cardEl[i].classList.add('acBtnLink')
					}
				}
			}
		})
	}, [setRegEvent, t])

	const registerJSEvents = useCallback(() => {
		// TODO: why do we call changeLanguage here? Shouldn't it already be this way?
		changeLanguage(locale)
		const el = document.querySelector('.chat-container')
		const elClt = document.querySelector('.bot-icon-new')
		if (el && el.classList && elClt) {
			elClt.addEventListener('click', event => {
				if (el.classList.contains('close-bot')) el.classList.remove('close-bot')
				else el.classList.add('close-bot')
			})
		}
		if (theme) {
			let showBot = /true/i.test(showBotCustom || theme.showBot)
			if (
				mobileData &&
				(typeof mobileData?.mobileApp === 'string' && isJson(mobileData?.mobileApp)
					? JSON.parse(mobileData.mobileApp)
					: mobileData?.mobileApp)
			)
				showBot = true
			if (el && showBot) el.classList.remove('close-bot')
			else if (el && el.classList) el.classList.add('close-bot')
		} else {
			el?.classList.add('close-bot')
		}
		// for our viertual agent page
		const elClt1 = document.querySelector('.bot-circle')
		if (elClt1) {
			elClt1.addEventListener('click', event => {
				event.preventDefault()
				setTimeout(e => {
					const elctrl = document.querySelector('.chat-container')
					if (!elctrl?.classList.contains('close-bot') && displayTooltip) setDisplayTooltip(false)
				}, 1000)
			})
		}

		// for chubb heybanco client
		const elClt2 = document.querySelector('.open-icon')
		if (elClt2) {
			elClt2.addEventListener('click', event => {
				event.preventDefault()
				setTimeout(e => {
					const elctrl1 = document.querySelector('.chat-container')
					if (!elctrl1?.classList.contains('close-bot')) {
						// TODO: why does theme have a google analytics id?
						if (gaCodeId || (theme && theme.gaCodeId)) {
							ReactGA.event({
								category: 'Chat Bot',
								action: 'click',
								label: 'Chatbot assistant clicked',
							})
						}
					}
					if (!elctrl1?.classList.contains('close-bot') && displayTooltip) setDisplayTooltip(false)
				}, 1000)
			})
		}
		if (!regEvent) regEventFun()
		if (!chatMinimize) {
			handleMinimize()
		}
		setIsregisteredEvents(true)
	}, [
		chatMinimize,
		handleMinimize,
		displayTooltip,
		setDisplayTooltip,
		regEvent,
		regEventFun,
		setIsregisteredEvents,
		showBotCustom,
		locale,
		theme,
		mobileData,
		gaCodeId,
	])

	const handleMessage = (event) => {
		if (event?.data?.type === 'quickCommand' && event?.data?.content?.value) {
			store.dispatch({
				type: 'WEB_CHAT/SEND_MESSAGE',
				payload: { text: t(event.data.content.value) },
			})
		}
		if (event?.data?.type === 'botReady' && event?.data?.content?.value && props?.searchValue) {
			setClickToBegin(false)
			setHideSendBox(false)
			setTimeout(() => {
				store.dispatch({
					type: 'WEB_CHAT/SEND_MESSAGE',
					payload: { text: t(props?.inSearch ? 'Hi' : 'Hi') },
				})
				setTimeout(() => {
					store.dispatch({
						type: 'WEB_CHAT/SEND_MESSAGE',
						payload: { text: t('Ask a question') },
					})
					setTimeout(() => {
						store.dispatch({
							type: 'WEB_CHAT/SEND_MESSAGE',
							payload: { text: t(props?.searchValue) },
						})
					}, 2000)
				}, 2000)
			}, props?.inSearch ? 5000 : 3000)
		}
	}

	useEffect(() => {
		window.addEventListener('message', handleMessage)
		return () => {
			window.removeEventListener('message', handleMessage)
		}
	}, [])

	useEffect(() => {
		setBotOpenOrClose()
		// store.subscribe(() => {
		//   const { activities } = store.getState();
		//   reRendorDOM(activities);
		// });
		if (!isregisteredEvents) {
			registerJSEvents()
		}
		// TODO: why does theme have access to gaCodeId?
		if (gaCodeId || theme?.gaCodeId) {
			ReactGA.initialize(gaCodeId || theme?.gaCodeId)
		}


		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [theme])

	// NOTE: comment out this useEffect because it only had 1 line that was already commented out
	// useEffect(() => {
	// 	// refreshRendor()
	// })

	// NOTE: comment out because the render line was already commented out
	// const refreshRendor = () => {
	// 	setTimeout(() => {
	// 		const { activities } = store.getState()
	// 		// reRendorDOM(activities)
	// 	}, 10)
	// }

	// TODO: parse this regex earlier on when we receive theme data
	const isPowerdBy = theme?.isPowerdBy ? /true/i.test(theme.isPowerdBy) : true

	const welcomeMsg = theme?.welcomeMessage && theme.welcomeMessage.trim() !== '' ? t(theme.welcomeMessage) : null

	useEffect(() => {
		if (props.aiBot) {
			window.parent.postMessage({ type: 'restartBtn', content: { hideDisconnect, isConnetedToAgent, hideSendBox } }, '*')
		}
	}, [hideDisconnect, isConnetedToAgent, hideSendBox])

	const { resetBot } = useContext(AppContext)

	const handleReset = () => {
		const botItem = {
			tenantId: tenant.id,
			conversationId: null,
			learnigKey: null,
			token: '',
		}
		// localStorage.removeItem('chatInfo')
		// props.ls.remove('chatInfo')
		const localStrchatInfo = localStorage.getItem(pgCtx || tenant.tenantId || 'chatInfo')
		const localStrGuestUser = localStorage.getItem('isGuest')
		if (localStrchatInfo) {
			localStorage.removeItem(pgCtx || tenant.tenantId || 'chatInfo')
			ls.remove(pgCtx || tenant.tenantId || 'chatInfo')
		}
		if (localStrGuestUser) {
			localStorage.removeItem('isGuest')
			ls.remove('isGuest')
		}

		setTimeout(() => dispatch(getResetBot(botItem, tenant.apiUrl)), 1000)
		setTimeout(() => resetBot(), 2000)
		sessionStorage.setItem("traceLog", props?.traceLog)
	}

	useEffect(() => {
		if (botStudioBot) {
			const traceLog = (sessionStorage.getItem("traceLog") === 'true') ? true : (sessionStorage.getItem("traceLog") === 'false') ? false : null
			if (traceLog !== props?.traceLog) {
				handleReset()
			}
		}
	}, [])

	return (
		<>
			<style>
				{`.fullwidget_v2.chat-container .webchat__bubble:not(.webchat__bubble--rtl):not(.webchat__bubble--from-user).webchat__bubble--show-nub{
	border:1px solid #D1D5DB !important;
	box-shadow:none !important;
}
.fullwidget_v2.chat-container .webchat__stacked-layout__attachment , .fullwidget_v2.chat-container .webchat__stacked-layout__message{
	border:1px solid #D1D5DB !important;
	box-shadow:none !important;
}
.fullwidget_v2.chat-container .webchat__stacked-layout__attachment{
	min-width:60% !important;
}

.fullwidget_v2.chat-container  .webchat__basic-transcript__scrollable::-webkit-scrollbar {
	width: 10px;
	height: 10px;
  }
   
  .fullwidget_v2.chat-container  .webchat__basic-transcript__scrollable::-webkit-scrollbar-track {
	background: rgba(209, 213, 219, 0.3);
  }
   
  .fullwidget_v2.chat-container  .webchat__basic-transcript__scrollable::-webkit-scrollbar-thumb {
	background: rgba(17, 24, 39, 0.2);
	border-radius: 100px;
    border: 2px transparent solid;
    background-clip: padding-box;
   
  }
   
  .fullwidget_v2.chat-container  .webchat__basic-transcript__scrollable::-webkit-scrollbar-thumb:hover {
	background: #807f7f;
	border-radius: 100px;
  border: 2px transparent solid;
  background-clip: padding-box;
  }

  .fullwidget_v2.chat-container .webchat__send-box__main{
      padding-left:10px !important;
	  padding-right:10px !important;
  }

`}
			</style>
			{displayTooltip && welcomeMsg && (
				<div className="welcome-user">
					<p>{welcomeMsg}</p>
				</div>
			)}
			<div
				className={`chat-container chat-container-reset ${classNames} ${uiTheme} ${uiClassNames} ${chatContainerClasses} ${!hideUploadButton ? 'enabled-upload' : ''
					} ${props?.metadata?.chatHeaderIcon ? 'ap-client' : ''} ${props.aiBot ? 'chat-container-aibot fullwidget_v2' : ''}`} >
				{!(props.aiBot) && <ChatHeader
					store={store}
					userId={userId}
					theme={theme}
					enableShortcut={enableShortcut}
					props={{
						userAttributes: props.userAttributes,
						botType,
						botClientType,
						authToken,
						flowName,
						botStudioBot,
						isSupportBot: props.isSupportBot,
						metadata: props.metadata
					}}
					hideSendBox={hideSendBox}
					isConnetedToAgent={isConnetedToAgent}
					setIsConnetedToAgent={setIsConnetedToAgent}
					hideUploadButton={hideUploadButton}
					setHideUploadButton={setHideUploadButton}
					lsAgentChatDeactivate={lsAgentChatDeactivate}
				/>}
				{/* REFACTOR NOTE: 
				1.removed menuFlow from call bc ChatOptions get it straight from theme.menuFlow
				2.pass other args in because ChatOption uses them like a closure but we want to make it an actual decoupled function.
				*/}
				<ChatOptions
					theme={theme}
					store={store}
					authToken={authToken}
					userId={userId}
					userAttributes={props.userAttributes}
					botType={botType}
					botClientType={botClientType}
				/>
				<ReactWebChat
					//   activityMiddleware={activityMiddleware}
					attachmentMiddleware={useAttachmentMiddleware(store)}
					activityStatusMiddleware={useActivityStatusMiddleware(props.botName)}
					directLine={directLine}
					userID={userId}
					username={props.username}
					styleOptions={styleOptions}
					store={store}
					disabled={false}
					locale={locale}
				// sendTypingIndicator={true}
				// typingIndicatorMiddleware= {typingIndicatorMiddleware}
				/>
				{isPowerdBy && <PoweredBy theme={theme} />}
				{clickToBegin && (
					<SendBoxClickToBegin
						store={store}
						pgCtx={pgCtx}
						userId={userId}
						setClickToBegin={setClickToBegin}
						setHideSendBox={setHideSendBox}
						notificationLabel={mobileData?.notificationLabel || ''}
						enableShortcut={enableShortcut}
					/>
				)}
				{disableSendBox && <SendBoxGrayOut />}
				{!hideUploadButton && !clickToBegin && (
					<Upload
						store={store}
						bg={theme && theme.botTheme}
						directLine={directLine}
						userId={userId}
						username={props.username}
						customMessage={theme?.contantInfo || ''}
						aiBot={props.aiBot}
					/>
				)}
			</div>
		</>
	)
}

export default MinimizableWebChat

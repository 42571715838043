import { faBoltLightning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { WhiteLabelTheme } from '../../reducers/whiteLabel.reducers'

interface PoweredByProps {
	theme: WhiteLabelTheme
}
export default function PoweredBy({ theme }: PoweredByProps) {
	const { t } = useTranslation()
	return (
		<div className="powered_by">
			<a
				target="_blank"
				href={theme?.companyLink || 'https://www.rezolve.ai/'}
				className="powerdBy"
				rel="noreferrer">
				<FontAwesomeIcon icon={faBoltLightning} /> {t('by')} {t(theme?.companyName || 'Actionable Science')}
			</a>
		</div>
	)
}

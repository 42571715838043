import { createGlobalStyle } from 'styled-components'
import './v4.css'

//NOTE: this style var is declared but never used.
//TODO: verify that it's not being used automagically and remove
const style = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	border: 'solid 1px #ddd',
	background: '#f0f0f0',
}

// require('isomorphic-fetch');
const GlobalStyle = createGlobalStyle`
  body {
    & .ac-pushButton{
      color: ${props => props.botTheme} !important;
      border-color: ${props => props.botTheme} !important;
    }
    & .webchat__icon-button{
      background: ${props => props.botTheme} !important;
    }
		& #botChatDiv .chat-container .chat-header{
      background: ${props => props.botTheme} !important;
    }
    & .click-begin span{
      background: ${props => props.botTheme};
    }
    & .bot-circle span.bot-btn{
      background: ${props => props.botTheme} !important;
    }
   
    & .ac-pushButton:hover{
      background: ${props => props.botTheme} !important;
      border-color:${props => props.botTheme} !important;
    }
    & .webchat__bubble.webchat__bubble--from-user .webchat__bubble__content {
      background: ${props => props.botTheme} !important;
      color: #fff !important;
    }
    & .webchat__bubble.webchat__bubble--from-user{
      background: ${props => props.botTheme} !important;
    }
    & .webchat__bubble.webchat__bubble--from-user .webchat__bubble__content p{
      color: #fff !important;
    }
    & .chat-container .webchat__send-box__main .webchat__icon-button.webchat__icon-button--stretch{
      background-color: ${props => props.botTheme} !important;
      background: ${props => props.botTheme} !important;
    }
    & .chat-container .webchat__send-box__main .webchat__send-box-text-box__input:focus {
      border: ${props => props.botTheme}  1px solid !important;
      box-shadow: 0 1px 0 0 ${props => props.botTheme} !important;
    }
    ${
			// @ts-ignore
			props => props.botStyle
		}
   
  }
`
export default GlobalStyle

import { createAction } from '@reduxjs/toolkit'
import * as tenantConstants from '../constants/tenant.constants'
import * as tenantServices from '../services/tenant.services'

// const getTenantRequest = () => ({
// 	type: tenantConstants.GET_TENANT_REQUEST,
// })
export const getTenantRequest = createAction(tenantConstants.GET_TENANT_REQUEST)

// const getTenantSuccess = tenant => ({
// 	type: tenantConstants.GET_TENANT_SUCCESS,
// 	payload: {
// 		...tenant,
// 	},
// })
export const getTenantSuccess = createAction(tenantConstants.GET_TENANT_SUCCESS, tenant => ({
	payload: { ...tenant },
}))

// const getTenantFailure = error => ({
// 	type: tenantConstants.GET_TENANT_FAILURE,
// 	payload: error,
// })
export const getTenantFailure = createAction<any>(tenantConstants.GET_TENANT_FAILURE)

// TODO: change this name since we're saving more than just the id
export const saveTenantId = data => async dispatch => {
	try {
		const result = await tenantServices.saveTenantId(data)
	} catch (error) {}
}

export const saveUserData = (options, token, apiUrl) => async dispatch => {
	try {
		const result = await tenantServices.saveUserData(options, token, apiUrl)
	} catch (error) {}
}

export const selectTenant = (tenantId: string) => async dispatch => {
	try {
		let tenant

		dispatch(getTenantRequest())

		// if (hostname.substring(hostname.indexOf('.') + 1) === rootDomain) {

		//     if (hostname.substring(0, hostname.indexOf('.')) !== defaultSubdomain) {

		const result = await tenantServices.getTenantService(tenantId)

		if (result && result.data) {
			tenant = {
				tenantId,
				url: tenantId,
				isTenant: true,
				...result.data,
				apiUrl: result.data && result.data.apiUrl + result.data.basePath,
			}
			// localStorage.setItem('apiUrl', tenant.apiUrl)
			sessionStorage.setItem('apiUrl', tenant.apiUrl)
			dispatch(getTenantSuccess(tenant))
		} else {
			const error = 'Cannot fetch the required tenant'
			dispatch(getTenantFailure(error))
		}
		//     } else {

		//         tenant = {
		//             tenantId: 'default',
		//             url: hostname,
		//             isTenant: false,
		//             landingPage: '/register',
		//             rootDomain: rootDomain,
		//         }
		//         dispatch(getTenantSuccess(tenant))

		//     }
		// } else {
		//     let error = "Current domain does not seem to match the expected domain , please contact your administrator"
		//     dispatch(getTenantFailure(error));

		// }
	} catch (error) {
		dispatch(getTenantFailure(error))
	}
}

// const checkTenantExistsRequest = () => ({
// 	type: tenantConstants.CHECK_TENANT_EXISTS_REQUEST,
// })
export const checkTenantExistsRequest = createAction(tenantConstants.CHECK_TENANT_EXISTS_REQUEST)

// const checkTenantExistsSuccess = payload => ({
// 	type: tenantConstants.CHECK_TENANT_EXISTS_SUCCESS,
// 	payload,
// })
export const checkTenantExistsSuccess = createAction<any>(tenantConstants.CHECK_TENANT_EXISTS_SUCCESS)

// const checkTenantExistsFailure = payload => ({
// 	type: tenantConstants.CHECK_TENANT_EXISTS_FAILURE,
// 	payload,
// })
export const checkTenantExistsFailure = createAction<any>(tenantConstants.CHECK_TENANT_EXISTS_FAILURE)

export const checkTenantExists = tenantId => async dispatch => {
	try {
		dispatch(checkTenantExistsRequest())
		const checkTenant = await tenantServices.checkTenantExists(tenantId)

		const tenantExists = {}
		if (checkTenant.length > 0) {
			tenantExists[tenantId] = true
		} else tenantExists[tenantId] = false
		console.log(tenantExists)
		dispatch(checkTenantExistsSuccess(tenantExists))
	} catch (error) {
		dispatch(checkTenantExistsFailure(`Unable to check tenant details : ${error}`))
	}
}

// const registerTenantRequest = () => ({
// 	type: tenantConstants.REGISTER_TENANT_REQUEST,
// })
export const registerTenantRequest = createAction(tenantConstants.REGISTER_TENANT_REQUEST)

// const registerTenantSuccess = () => ({
// 	type: tenantConstants.REGISTER_TENANT_SUCCESS,
// })
export const registerTenantSuccess = createAction(tenantConstants.REGISTER_TENANT_SUCCESS)

// const registerTenantFailure = payload => ({
// 	type: tenantConstants.REGISTER_TENANT_FAILURE,
// 	payload,
// })
export const registerTenantFailure = createAction<any>(tenantConstants.REGISTER_TENANT_FAILURE)

export const registerTenant = payload => async dispatch => {
	try {
		dispatch(registerTenantRequest())
		const reqPayload = {
			tenantId: payload.tenantId,
			rootEmailId: payload.email,
			companyName: payload.companyName,
			password: payload.password,
			firstName: payload.firstName,
			lastName: payload.lastName,
		}
		await tenantServices.registerTenant(reqPayload)
		dispatch(registerTenantSuccess())
	} catch (error) {
		dispatch(registerTenantFailure(`Unable to register tenant : ${error}`))
	}
}

// const getTenantLifeCycleReqeust = () => ({
// 	type: tenantConstants.TENANT_LIFECYCLE_REQUEST,
// })
export const getTenantLifeCycleRequest = createAction(tenantConstants.TENANT_LIFECYCLE_REQUEST)

// const getTenantLifeCycleSuccess = payload => ({
// 	type: tenantConstants.TENANT_LIFECYCLE_SUCCESS,
// 	payload,
// })
export const getTenantLifeCycleSuccess = createAction<any>(tenantConstants.TENANT_LIFECYCLE_SUCCESS)

// const getTenantLifeCycleFailure = payload => ({
// 	type: tenantConstants.TENANT_LIFECYCLE_FAILURE,
// 	payload,
// })
export const getTenantLifeCycleFailure = createAction<any>(tenantConstants.TENANT_LIFECYCLE_FAILURE)

export const getTenantLifeCycle = tenantId => async dispatch => {
	try {
		dispatch(getTenantLifeCycleRequest())
		let success = false
		let counter = 0
		while (!success) {
			const lifecycle = await tenantServices.getTenantLifeCycle(tenantId)
			dispatch(getTenantLifeCycleSuccess(lifecycle))
			if (lifecycle.status === 'ACTIVE') success = true
			else {
				counter++
				if (counter > 100) throw new Error('Timeout Error: Taking too long, our systems are slow.')
				await new Promise(resolve => setTimeout(resolve, 4000))
			}
		}
	} catch (error) {
		dispatch(getTenantLifeCycleFailure(`Unable to check tenant details : ${error}`))
	}
}

// export const selectTenant = () => {
//     return dispatch => {
//         let tenant
//         dispatch(getTenantRequest());

//         const hostname = window.location.hostname

//         if ( hostname.substring(hostname.indexOf('.') + 1) === rootDomain )  {

//             if ( hostname.substring(0,hostname.indexOf('.')) !== defaultSubdomain )   {

//                 return tenantServices.getTenantService(hostname)
//                 .then ( result => {
//                         if (result.data && result.data.tenants && result.data.tenants.length === 1) {
//                             tenant = {
//                                 tenantId: hostname.substring(0,hostname.indexOf('.')),
//                                 url: hostname,
//                                 isTenant: true,
//                                 ...result.data.tenants[0]
//                             }
//                             dispatch(getTenantSuccess(tenant))
//                         } else {
//                             let error = "Cannot fetch the required tenant"
//                             dispatch(getTenantFailure(error));
//
//                         }
//                         setTimeout(() => {
//                             dispatch(alertActions.clear());
//                         }, 10000);
//                     }
//                 )
//                 .catch(
//                     error => {
//                         dispatch(getTenantFailure(error));
//
//                         setTimeout(() => {
//                             dispatch(alertActions.clear());
//                         }, 10000);
//                     });
//             } else {

//                 tenant = {
//                     tenantId: 'default',
//                     url: hostname,
//                     isTenant: false,
//                     landingPage: '/register'
//                 }
//                 dispatch(getTenantSuccess(tenant))

//             }

//         } else {
//             let error = "Current domain does not seem to match the expected domain , please contact your administrator"
//             dispatch(getTenantFailure(error));
//
//         }

//         return new Promise((resolve, reject) => reject("Unable to fetch tenant details"))

//     };

// }

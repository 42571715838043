import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React,{useEffect,useRef} from 'react'
import { useWidgetUiContext } from '../WidgetUiContext'

interface CloseBtnProps {
	botStudioBot?: boolean
}
function CloseBtn({ botStudioBot }: CloseBtnProps) {
	const { setChatContainerClasses } = useWidgetUiContext()
	const handleCloseBot = () => {
		window.parent.postMessage('botClose', '*')
		if (botStudioBot) {
			return setChatContainerClasses('close-bot')
		}

		const el = document.querySelector('.chat-container')
		const el1 = document.querySelector('.bot-circle > .bot-btn')
		const elm = document.querySelector('.bot-icon-main')
		if (el && el.classList) {
			el.classList.add('close-bot')
			el1?.classList?.remove('ch-tab')
		}
		if (elm && elm.classList) {
			elm.classList.add('chat-close')
		}
		return null
	}
	const buttonRef = useRef<HTMLButtonElement>(null);
	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
		  if (event.key === 'Escape') {
			// console.log("close")
			buttonRef.current?.click();
		  }
		};
	
		window.addEventListener('keydown', handleKeyDown);
	
		return () => {
		  window.removeEventListener('keydown', handleKeyDown);
		};
	  }, []);

	return (
		<span className="restart-icon btn-close" ref={buttonRef}  onClick={handleCloseBot}>
			<FontAwesomeIcon color="white" icon={faTimes} />
		</span>
	)
}
export default CloseBtn

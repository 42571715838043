import { Components } from 'botframework-webchat'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Store } from 'redux'
import type { BotActivity, BotActivityAttachment } from '../../reducers/bot.reducers'

const {
	AdaptiveCardContent,
	AnimationCardContent,
	AudioCardContent,
	HeroCardContent,
	OAuthCardContent,
	ReceiptCardContent,
	SignInCardContent,
	ThumbnailCardContent,
	VideoCardContent,
} = Components

interface AttachmentMiddlewareArgs {
	activity: BotActivity
	attachment: BotActivityAttachment
}

const getTestId = ({ activity }) => {
	if (activity.text && activity.text.startsWith('Thank you! I have created ticket') || activity.text && activity.text.includes('is created,')) {
		return 'createTicketSuccessfully'
	}
	if (activity.text && activity.text.endsWith('required. Please fill in and click on submit again.')) {
		return 'createTicketRequiredFilledErr'
	}
	if (activity.text && activity.text.startsWith('Attachment updated successfully')) {
		return 'attachmentUpdatedSuccessfully'
	}
	if (activity.text && activity.text.startsWith('Your ticket has successfully been updated.')) {
		return 'updateTicketSuccessfully'
	}
	if (activity.text && activity.text.startsWith('I opened a Helpdesk ticket -')) {
		return 'liveChat'
	}
	const [att] = activity.attachments || []
	if (att && att.content && att.content.body) {
		const flag = att.content.body.some(bd => bd.text === 'Here are all your Tickets from the last 6 months')
		const flag1 = att.content.body.some(bd => bd.text === 'Please select the Group for which you would like to request Support.')
		const flag2 = att.content.body.some(bd => bd.text && bd.text.includes('Please provide the following information about the new employee:'))
		if (flag) return 'ticketList'
		if (flag1) return 'liveChatGrouplist'
		if (flag2) return 'infoForm'
	}
}

export default function useAttachmentMiddleware(store: Store) {
	const { t } = useTranslation()
	return () => next =>
		function attachmentMiddleware(midProps: AttachmentMiddlewareArgs) {
			const { activities } = store.getState() as { activities: BotActivity[] }
			const { activity, attachment, ...others } = midProps

			const messageActivities = activities.filter(a => a.type === 'message')
			const recentBotMessage = messageActivities.pop() === activity
			const lastActivity = activities && activities[activities.length - 1]
			const secLastActivity = activities && activities[activities.length - 2]

			const isError =
				lastActivity?.text &&
				secLastActivity?.type === 'event' &&
				secLastActivity?.name === 'formPropertyError' &&
				(lastActivity?.text.toLowerCase().indexOf(t('required')) !== -1 ||
					lastActivity?.text.toLowerCase().indexOf(t('invalid')) !== -1 ||
					lastActivity?.text.toLowerCase().indexOf(t('error')) !== -1 ||
					lastActivity?.text.toLowerCase().indexOf(t('oops')) !== -1 ||
					lastActivity?.text.toLowerCase().indexOf(t('what')) !== -1 ||
					lastActivity?.text.toLowerCase().indexOf(t('incorrect')) !== -1)

			const testIdAttr = getTestId({ activity })
			// console.log(testIdAttr,"render",activity.id)
			const Attr = `chat-item-${activity.id}`;

			// SUP-5790 -> scroll issue
			setTimeout(() => {
				const button = document.querySelector('.webchat__scroll-to-end-button')
				if (button) button.click()
			}, 3000)
			
			// RT-4059 -> PRD-> shortcut keys
			let inputDetector = document.querySelector('.webchat__send-box-text-box__input');
			if (document.activeElement !== inputDetector){
				setTimeout(() => {
					const text = document.querySelector('.webchat__send-box-text-box__input')
					if (text) text.value = ''
				}, 0)
			}

			switch (attachment?.contentType) {
				case 'application/vnd.microsoft.card.adaptive':
					return (
						<div data-testId={testIdAttr} key={activity.id} data-id={Attr}>
							<AdaptiveCardContent
								actionPerformedClassName="card__action--performed"
								// content={JSON.parse(JSON.stringify(attachment.content).replace(/`/g, ''))} 
								content={attachment.content}
								disabled={!isError && !recentBotMessage}
							/>
						</div>
					)

				case 'application/vnd.microsoft.card.animation':
					return (
						<div data-testId={testIdAttr} key={activity.id} data-id={Attr}>
                        <AnimationCardContent
							actionPerformedClassName="card__action--performed"
							content={attachment.content}
							disabled={!isError && !recentBotMessage}
						/>
						</div>
						
					)

				case 'application/vnd.microsoft.card.audio':
					return (
						<div data-testId={testIdAttr} key={activity.id} data-id={Attr}>
                        <AudioCardContent
							actionPerformedClassName="card__action--performed"
							content={attachment.content}
							disabled={!isError && !recentBotMessage}
						/>
						</div>
						
					)

				case 'application/vnd.microsoft.card.hero':
					return (
						<div data-testId={testIdAttr} key={activity.id} data-id={Attr}>
                         <HeroCardContent
							actionPerformedClassName="card__action--performed"
							content={attachment.content}
							disabled={!isError && !recentBotMessage}
						/>
						</div>
						
					)

				case 'application/vnd.microsoft.card.oauth':
					return (
						<div data-testId={testIdAttr} key={activity.id} data-id={Attr}>

							<OAuthCardContent
							key={activity.id} data-id={Attr}
								actionPerformedClassName="card__action--performed"
								content={attachment.content}
								disabled={!isError && !recentBotMessage}
							/>
						</div>
					)

				case 'application/vnd.microsoft.card.receipt':
					return (
						<div data-testId={testIdAttr} key={activity.id} data-id={Attr}>

							<ReceiptCardContent
							key={activity.id} data-id={Attr}
								actionPerformedClassName="card__action--performed"
								content={attachment.content}
								disabled={!isError && !recentBotMessage}
							/>
						</div>
					)

				case 'application/vnd.microsoft.card.signin':
					return (
						<div data-testId={testIdAttr} key={activity.id} data-id={Attr}>

							<SignInCardContent
								actionPerformedClassName="card__action--performed"
								content={attachment.content}
								disabled={!isError && !recentBotMessage}
							/>
						</div>
					)

				case 'application/vnd.microsoft.card.thumbnail':
					return (
						<div data-testId={testIdAttr} key={activity.id} data-id={Attr}>

							<ThumbnailCardContent
								actionPerformedClassName="card__action--performed"
								content={attachment.content}
								disabled={!isError && !recentBotMessage}
							/>
						</div>
					)

				case 'application/vnd.microsoft.card.video':
					return (
						<div data-testId={testIdAttr} key={activity.id} data-id={Attr}>
							<VideoCardContent
								actionPerformedClassName="card__action--performed"
								content={attachment.content}
								disabled={!isError && !recentBotMessage}
							/>
						</div>
					)
				case 'text/markdown':
					return (
						<div data-testId={testIdAttr} key={activity.id} data-id={Attr}>
							{next(midProps)}
						</div>
					)

				default:
					return next({ activity, attachment, ...others })
			}
		}
}

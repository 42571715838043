import * as botConstants from '../constants/bot.constants'

export interface BotActivityAttachment {
	contentType: string
	// content: {
	// 	body: { id: string; value: unknown }[]
	// 	media?: any
	// }
	content: any
}
export interface BotActivity {
	from: {
		name?: string
		role?: string
		agentCheck?: unknown
	}
	sentBy: string
	name: string
	type: string
	text?: string
	attachments?: BotActivityAttachment[]
	cardInput?: object
}
export interface BotData {
	botLogo?: string
	botId?: string
	botName?: string
	landingMessage?: string
	landingMsgKey?: string
	token?: string
	botSecret?: string
	langs?: { lang: string }[] // TODO: should this obj array be flattened to a string array? (This type defn is from the LocaleSelector code)
	activities?: BotActivity[]
}
interface BotState {
	botData?: BotData
	loading?: boolean
	langs?: string
}
// eslint-disable-next-line import/prefer-default-export, @typescript-eslint/default-param-last
export const bot = (state: BotState = {}, action): BotState => {
	switch (action.type) {
		case botConstants.GETCONFIG_REQUEST_BOT_RESET:
			return {
				...state,
				botData: {},
				loading: true,
			}
		case botConstants.GETCONFIG_REQUEST_BOT:
			return {
				...state,
				//  botData:{},
				loading: true,
			}
		case botConstants.GETCONFIG_SUCCESS_BOT:
			return {
				...state,
				loading: false,
				botData: action.item,
			}
		case botConstants.GETCONFIG_FAILURE_BOT:
			return {
				...state,
				loading: false,
			}
		case botConstants.GET_LANG_SUCCESS:
			return {
				...state,
				loading: false,
				langs: action.item,
			}

		default:
			return state
	}
}

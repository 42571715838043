import React, { useCallback } from 'react'
import { useWidgetUiContext } from '../WidgetUiContext'

function ThemeSelector() {
	const { setUiTheme } = useWidgetUiContext()

	const handleThemeSelector = useCallback(
		(themeName: string) => {
			setUiTheme(themeName)
		},
		[setUiTheme]
	)

	return (
		<span className="theme-selector">
			<span
				className="default-theme color-selector"
				onClick={() => {
					handleThemeSelector('default-theme')
					// refreshRendor()
				}}
			/>
			<span
				className="dark-theme color-selector"
				onClick={() => {
					handleThemeSelector('darktheme')
					// refreshRendor()
				}}
			/>
		</span>
	)
}
export default ThemeSelector

import { createReducer } from '@reduxjs/toolkit'
import {
	changeStatusSuccess,
	getAllGlobalSettingsRequest,
	getAllGlobalSettingsSuccess,
	getAllTenantConfigFailure,
	getAllTenantConfigRequest,
	getAllTenantConfigSuccess,
	getCustomerSettingsFailure,
	getCustomerSettingsRequest,
	getCustomerSettingsSuccess,
	updateGlobalSettingsFailure,
	updateGlobalSettingsRequest,
	updateGlobalSettingsSuccess,
} from '../actions/settings.actions'

interface TenantConfig {
	name: string
	properties: unknown
}
interface GlobalSettingState {
	loading?: boolean
	settingData?: any
	tenantConfig?: TenantConfig[]
}

const initialState: GlobalSettingState = {}

export const globalSetting = createReducer(initialState, builder =>
	builder
		.addCase(
			// case settingsConstants.GETALL_REQUEST:
			// 	return {
			// 		...state,
			// 		loading: true,
			// 	}
			getAllGlobalSettingsRequest,
			(state, action) => ({
				...state,
				loading: true,
			})
		)
		.addCase(
			// case settingsConstants.GETALL_SUCCESS:
			// 	return {
			// 		...state,
			// 		loading: false,
			// 		...action.payload.data,
			// 	}
			getAllGlobalSettingsSuccess,
			(state, action) => ({
				...state,
				loading: false,
				...action.payload.data,
			})
		)
		.addCase(
			// case settingsConstants.CHANGE_STATUS_SUCCESS:
			// 	if (action.payload) {
			// 		return {
			// 			...state,
			// 			...action.payload.data,
			// 			loading: false,
			// 		}
			// 	}
			// 	break
			changeStatusSuccess,
			(state, action) => ({
				...state,
				loading: false,
				...(action.payload ? action.payload.data : {}),
			})
		)
		.addCase(
			// case settingsConstants.UPDATE_GLOBAL_SETTING_REQUEST:
			// 	return {
			// 		...state,
			// 		loading: true,
			// 	}
			updateGlobalSettingsRequest,
			(state, action) => ({
				...state,
				loading: true,
			})
		)
		.addCase(
			// case settingsConstants.UPDATE_GLOBAL_SETTING_SUCCESS:
			// 	if (action.payload) {
			// 		return {
			// 			loading: false,
			// 			...state,
			// 			...action.payload.data,
			// 		}
			// 	} else {
			// 		return {
			// 			...state,
			// 			loading: false,
			// 		}
			// 	}
			updateGlobalSettingsSuccess,
			(state, action) => ({
				loading: false,
				...state,
				...(action.payload ? action.payload.data : {}),
			})
		)
		.addCase(
			// case settingsConstants.UPDATE_GLOBAL_SETTING_FAILURE:
			// 	return {
			// 		...state,
			// 		loading: false,
			// 	}
			updateGlobalSettingsFailure,
			(state, action) => ({
				...state,
				loading: false,
			})
		)
		.addCase(
			// to be checked by harish for its compatibility
			// case settingsConstants.GET_SETTINGS_REQUEST:
			// 	return {
			// 		...state,
			// 		loading: true,
			// 	}
			getCustomerSettingsRequest,
			(state, action) => ({
				...state,
				loading: true,
			})
		)
		.addCase(
			// case settingsConstants.GET_SETTINGS_SUCCESS:
			// 	if (action.payload) {
			// 		return {
			// 			...state,
			// 			loading: false,
			// 			settingData: action.payload,
			// 		}
			// 	} else {
			// 		return {
			// 			...state,
			// 			loading: false,
			// 		}
			// 	}
			getCustomerSettingsSuccess,
			(state, action) => ({
				...state,
				loading: false,
				...(action.payload ? { settingData: action.payload } : {}),
			})
		)
		.addCase(
			// case settingsConstants.GET_SETTINGS_FAILURE:
			// 	return {
			// 		...state,
			// 		loading: false,
			// 	}
			getCustomerSettingsFailure,
			(state, action) => ({
				...state,
				loading: false,
			})
		)
		.addCase(
			// case settingsConstants.GETALL_TENANT_CONFIG_REQUEST:
			// 	return {
			// 		...state,
			// 		loading: true,
			// 	}
			getAllTenantConfigRequest,
			(state, action) => ({
				...state,
				loading: true,
			})
		)
		.addCase(
			// case settingsConstants.GETALL_TENANT_CONFIG_FAILURE:
			// 	return {
			// 		...state,
			// 		loading: false,
			// 	}
			getAllTenantConfigFailure,
			(state, action) => ({
				...state,
				loading: false,
			})
		)
		.addCase(
			// case settingsConstants.GETALL_TENANT_CONFIG_SUCCESS:
			// 	return {
			// 		...state,
			// 		tenantConfig: action.payload,
			// 		loading: false,
			// 	}
			getAllTenantConfigSuccess,
			(state, action) => ({
				...state,
				tenantConfig: action.payload,
				loading: false,
			})
		)
)

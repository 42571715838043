import { IdentityState } from 'src/app/identity/reducers/identity.reducers'
import { selectIdentity, selectWhiteLabelConfig, useAppSelector } from 'src/redux/hooks'
import { RootState } from 'src/redux/store'
import type { WhiteLabelEntity, WhiteLabelTheme } from '../reducers/whiteLabel.reducers'

function getWhiteLabelTheme(micrositeName: string, configs: WhiteLabelEntity[] | undefined, identity: IdentityState, tenant: any, props: any) {
	if (configs && configs.length > 0) {
		let entityConfig: WhiteLabelTheme = {}
		let publicConfig: WhiteLabelEntity[] | null = null
		let privateConfig: WhiteLabelEntity[] | null = null

		if (micrositeName)
			publicConfig = configs.filter(
				entity =>
					entity?.publicPrivate?.toLowerCase() === 'public' &&
					entity?.micrositeName?.toLowerCase() === micrositeName.toLowerCase()
			)

		if (!publicConfig)
			publicConfig = configs.filter(
				entity =>
					entity?.publicPrivate?.toLowerCase() === 'public' && entity?.micrositeName?.toLowerCase() === 'default'
			)

		if ((props.botStudioBot && identity && identity.isAuthenticated) || (identity && identity[tenant.tenantId] && identity[tenant.tenantId].isAuthenticated)) {
			if (micrositeName)
				privateConfig = configs.filter(
					entity =>
						entity?.publicPrivate?.toLowerCase() === 'private' &&
						entity?.micrositeName?.toLowerCase() === micrositeName.toLowerCase()
				)

			if (!privateConfig)
				privateConfig = configs.filter(
					entity =>
						entity?.publicPrivate?.toLowerCase() === 'private' && entity?.micrositeName?.toLowerCase() === 'default'
				)

			entityConfig = privateConfig?.[0].config ?? publicConfig?.[0].config ?? {}
		} else {
			entityConfig = publicConfig?.[0]?.config ?? {}
		}

		return entityConfig
	}
	return {}
}

const selectWhiteLabelConfigForVA = (state: RootState) => selectWhiteLabelConfig(state, '/virtualagent')

export default function useWhiteLabel(tenant, props) {
	const whiteLabelConfigs = useAppSelector(selectWhiteLabelConfigForVA)
	const identity = useAppSelector(selectIdentity)

	return {
		getMicrositeTheme: (micrositeName?: string) => getWhiteLabelTheme(micrositeName ?? '', whiteLabelConfigs, identity, tenant, props),
	}
}

import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
// import { single } from "rxjs/operators";
import { format as formatDate, intlFormatDistance } from 'date-fns'
import { initReactI18next } from 'react-i18next'
import { HEADER_PARAMS, globalTenantServiceUrl } from '../config'
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
// const tenantId = '123123'

export default function init(tenantUuid: string, token?: string | null) {
	const customHeader = {
		'x-tenantid': tenantUuid || '',
		...HEADER_PARAMS,
	}
	// if (token) {
	//   customHeader = {
	//     authorization: 'Bearer ' + token,
	//   }
	// }

	i18next
		// load translation using xhr -> see /public/locales
		// learn more: https://github.com/i18next/i18next-xhr-backend
		.use(HttpApi)
		// detect user language
		// learn more: https://github.com/i18next/i18next-browser-languageDetector
		.use(LanguageDetector)
		// pass the i18n instance to react-i18next.
		.use(initReactI18next)
		.init({
			backend: {
				loadPath: `${globalTenantServiceUrl}/Locale/{{lng}}/{{ns}}`, // loadPath: `${apiUrl}/languages/{{lng}}/{{ns}}/${tenantId}`,
				addPath: `${globalTenantServiceUrl}/Locale/add/{{lng}}/{{ns}}`, // addPath: `${apiUrl}/locales/add/{{lng}}/{{ns}}`
				allowMultiLoading: false,
				crossDomain: true,
				customHeaders: customHeader,
			},
			// resources:{
			//     en: {
			//         translation:{
			//             Welcome: 'Welcome to the new!',
			//             hey: 'hi there'
			//         }
			//     },
			//     de: {
			//         translation:{
			//             Welcome: 'Bon Jereaeaw!',
			//             hey: 'asdfads'
			//         }
			//     }

			// },
			// fallbackLng: {
			//   'default': userLanguage
			// },
			fallbackLng: 'en',
			// saveMissingTo:'current',
			// debug: i18nSaving,
			// saveMissing: i18nSaving,
			interpolation: {
				escapeValue: false, // not needed for react as it escapes by default

				format(value: unknown, format: string, lng: string): string {
					if (value instanceof Date) {
						const now = new Date()
						const olderThanDay = (now.getTime() - value.getTime()) / 3600 / 24 / 1000 > 1
						// return olderThanDay ? moment (value).format('MMM-DD-YYYY') : moment (value).tz(moment .tz.guess()).fromNow()
						return olderThanDay
							? formatDate(value, 'LLL-dd-yyyy')
							: intlFormatDistance(value, Date.now())
					}

					return value as string
				},
				// nonExplicitWhitelist: false, // has been renamed to nonExplicitSupportedLngs and already default to false
			},
		})

	// all our formating usage are with a format string or Intl so no need to mess with moment locale
	// i18next.on('languageChanged', lng => {
	//	moment .locale(lng)
	// })
	return i18next
}

import { faEnvelopeOpen, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Store } from 'redux'
import { saveTenantId } from 'src/app/identity/actions/tenant.actions'
import { selectTenant, useAppDispatch, useAppSelector } from 'src/redux/hooks'
import Swal from 'sweetalert2'
import { useWidgetUiContext } from '../WidgetUiContext'

interface ChatOptionsProps {
	theme: any
	store: Store
	authToken: string
	userAttributes: any
	botType: any
	botClientType: any
	userId: string
}

function ChatOptions({ theme, store, authToken, userId, userAttributes, botType, botClientType }: ChatOptionsProps) {
	const { t } = useTranslation()
	const tenant = useAppSelector(selectTenant)
	const { chatOption, resetChatOptions, locale } = useWidgetUiContext()
	const dispatch = useAppDispatch()

	let isPowerdBy
	let emailTranscript
	let printTranscript
	let menuFlow

	if (theme && theme) {
		isPowerdBy = /true/i.test(theme.isPowerdBy)
		emailTranscript = theme.emailTranscript == undefined ? true : /true/i.test(theme.emailTranscript)
		printTranscript = theme.printTranscript == undefined ? true : /true/i.test(theme.printTranscript)
		menuFlow = theme.menuFlow
	}
	if (menuFlow && typeof menuFlow === 'string') {
		try {
			menuFlow = JSON.parse(menuFlow)
		} catch (e) {
			menuFlow = []
		}
	}
	const style = {}
	// if (!menuFlow || menuFlow.length == 0)
	//     return (<></>)

	const handleEmailTranscript = () => {
		Swal.fire({
			title: t('This will reset your conversation'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: t('Okay'),
			cancelButtonText: t('Cancel'),
		}).then(result => {
			if (result.value) {
				if (store) {
					store.dispatch({
						type: 'WEB_CHAT/SEND_MESSAGE',
						payload: { text: t('Email Transcript') },
					})
				}
				resetChatOptions()
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				resetChatOptions()
			} else {
				resetChatOptions()
			}
		})
	}

	const handlePrintCoversation = () => {
		const w = window.open()
		if (!w) {
			throw new Error('window.open failed')
		}

		// @ts-ignore
		const is_chrome = Boolean(w.chrome)
		const ua = window.navigator.userAgent
		const msie = ua.indexOf('MSIE ')
		let chatLog: Node
		if (msie > 0) {
			chatLog = document.querySelectorAll('.webchat__basic-transcript__transcript')[0]
			w.document.write((chatLog as Element).innerHTML)
		} else {
			chatLog = document.querySelectorAll('.webchat__basic-transcript__transcript')[0].cloneNode(true)
			w.document.body.appendChild(chatLog)
		}
		const head = w.document.getElementsByTagName('head')[0]
		const link = w.document.createElement('link')
		link.rel = 'stylesheet'
		link.type = 'text/css'
		link.href = 'https://cdn.virtualpeople.ai/print.css'
		link.media = 'all'
		// Append link element to HTML head
		head.appendChild(link)
		if (is_chrome) {
			setTimeout(() => {
				w.document.close()
				w.focus()
				w.print()
				w.close()
			}, 200)
		} else {
			w.document.close()
			w.focus()
			w.print()
			w.close()
		}
		resetChatOptions()
	}

	const handleFlowMenu = (flow: any) => {
		if (!flow) return true
		Swal.fire({
			title: t('This will reset your conversation'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: t('Okay'),
			cancelButtonText: t('Cancel'),
		}).then(result => {
			if (result.value) {
				const tenantData = {
					tenantId: tenant.id,
					userId,
					authUserId: authToken || '',
					locale,
					userAttributes,
					userIntent: flow.value,
					botType,
					botClientType: botClientType || null,
					source: 'FlowMenu',
					apiUrl: tenant.apiUrl,
				}
				dispatch(saveTenantId(tenantData))
				if (store) {
					setTimeout(() => {
						store.dispatch({
							type: 'WEB_CHAT/SEND_MESSAGE',
							payload: { text: t(flow.name) },
						})
					}, 500)
				}
				resetChatOptions()
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				resetChatOptions()
			} else {
				resetChatOptions()
			}
		})
	}

	return (
		<div className={`exp-menu-frame scaleup ${chatOption ? 'transform-in' : ''}`} style={style}>
			{emailTranscript && (
				<div
					className="menuItem emailTriger"
					onClick={() => {
						handleEmailTranscript()
						// refreshRendor()
					}}>
					<FontAwesomeIcon icon={faEnvelopeOpen} />
					<span className="itemName">{t('Email Transcript')}</span>
				</div>
			)}
			{printTranscript && (
				<div
					className="menuItem pritTriger"
					onClick={() => {
						handlePrintCoversation()
						// refreshRendor()
					}}>
					<FontAwesomeIcon icon={faPrint} />
					<span className="itemName">{t('Print Transcript')}</span>
				</div>
			)}

			{menuFlow &&
				menuFlow.length > 0 &&
				menuFlow.map((flow, index) => {
					if (index > 5) return <></>
					return (
						<div className="menuItem" onClick={() => handleFlowMenu(flow)}>
							<img src={flow.iconUrl} />
							<span className="hide-text">{flow.name}</span>
						</div>
					)
				})}
		</div>
	)
}

export default ChatOptions

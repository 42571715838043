import { differenceInMinutes } from 'date-fns'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { detectChannelAgentType } from 'src/app/bot/components/utils'
import { checkIdentity, clearIdentity, reInitIdentity } from 'src/app/identity/actions/identity.actions'
import { saveTenantId, saveUserData } from 'src/app/identity/actions/tenant.actions'
import {
	selectBotData,
	selectGlobalSetting,
	selectIdentity,
	selectTenant,
	useAppDispatch,
	useAppSelector,
} from 'src/redux/hooks'
import { v4 as uuid } from 'uuid'
import { identityActions, identityServices } from '../../../identity'
import { getBotData, getNotificationCount } from '../../actions/bot.actions'
import { getDataWithDefaultTenant } from '../../actions/whiteLabel.actions'
import type { BaseProps, ChatInfo, MobileProps } from '../../interfaces'
import { WhiteLabelTheme } from '../../reducers/whiteLabel.reducers'
import MinimizableWebChat from '../MinimizableWebChat'
import { useWidgetUiContext } from '../WidgetUiContext'
import useWhiteLabel from '../useWhiteLabel'
import GlobalStyle from './GlobalStyle'

interface BotComponentProps {
	micrositeName?: string
	micrositeAttr?: any
	pgContext?: string
	chatResetTime?: number
	learnigKey?: string
	mobileProps?: MobileProps
	baseProps?: BaseProps
	botStudioBot?: unknown
	flowDetails?: {
		[key: string]: any
	}
	authToken?: string
	flowName?: string
	botClientType?: any
	botType?: string
	userPayloadToken?: string
	userConversationId?: string // TODO: this is declared originally in BotComponent.componentDidUpdate() but never used anywhere
	isGuest?: boolean
	website?: string
	otherInfo?: {
		[key: string]: any
	}
	traceLog?: boolean
	callBack?: (identity: any, logOut: any, conversationId: any, globalSetting: any) => any

	uploadButton: unknown
	showBotCustom: unknown
	gaCodeId?: string
	classNames?: string
	isSupportBot?: any
	isVirtualagent?: any
	aiBot?: any
}

function BotComponent({ baseProps, mobileProps, ...props }: BotComponentProps) {
	const [loading, setLoading] = useState(true)
	const [success, setSuccess] = useState(false)
	const [userIdFromChat, setUserIdFromChat] = useState(uuid())
	const [menuFlow, setMenuFlow] = useState([])
	const [userAttributes, setUserAttributes] = useState(null)
	const [attributes, setAttributes] = useState<string | null>(null) // TODO: find out what's the intended difference between attributes and userAttributes
	const [conversationId, setConversationId] = useState<string | null>(null)
	const [pgCtx, setPgCtx] = useState(props.pgContext)
	const [isConnectedToAgent, setIsConnectedToAgent] = useState(false)
	const [isEnableLastAC, setIsEnableLastAC] = useState(false)
	const [authenticated, setAuthenticated] = useState(false)
	const [isEventRegistered, setIsEventRegistered] = useState(false)
	const [setTenant, setSetTenant] = useState(false) // TODO: figure out what this is meant for and change to better name
	const [getConfig, setGetConfig] = useState(false)
	const [theme, setTheme] = useState<WhiteLabelTheme>({})
	const [isGuestLogin, setIsGuestLogin] = useState(false)
	const [token, setToken] = useState<string | null>(null)

	const identity = useAppSelector(selectIdentity)
	const tenant = useAppSelector(selectTenant)
	const botData = useAppSelector(selectBotData)
	const globalSetting = useAppSelector(selectGlobalSetting)
	const { getMicrositeTheme } = useWhiteLabel(tenant, props)
	const { t } = useTranslation()
	const dispatch = useAppDispatch()

	const { locale, ls } = useWidgetUiContext()

	function getLSChatInfo(lsKey = 'chatInfo') {
		const chatInfo: ChatInfo = ls && ls.get(lsKey)
		return { lsKey, chatInfo }
	}

	function updateChatState({ updateTokenYN = false }: { updateTokenYN: boolean }): ChatInfo {
		function reset(lsKey: string) {
			ls.remove(lsKey)
			setConversationId(null)
			setIsConnectedToAgent(false)
			setIsEnableLastAC(false)

			if (updateTokenYN) {
				setToken(null)
			}
		}

		function update(chatInfo: ChatInfo) {
			setConversationId(chatInfo.chatConversationId)
			setUserIdFromChat(chatInfo.userId)
			setIsConnectedToAgent(chatInfo.isConnetedToAgent)
			setIsEnableLastAC(chatInfo.isEnableLastAC)

			if (updateTokenYN) {
				setToken(chatInfo.token ?? null)
			}
		}

		const { chatResetTime, pgContext } = props
		const { lsKey, chatInfo } = getLSChatInfo(pgContext || tenant.tenantId)
		if (chatInfo?.chatConversationId) {
			// const mins = moment.utc().diff(chatInfo.timestamp, 'minutes')
			const mins = differenceInMinutes(new Date(), chatInfo.timestamp)
			if (mins > (chatResetTime || 10)) {
				reset(lsKey)
			} else {
				update(chatInfo)
			}
		}
		return chatInfo
	}

	function stringifyChatData() {
		// TODO: this probably should not be referred to as "chatData"
		const { authToken, botType } = props
		const signedUserData = {
			userId: props.botStudioBot ? identity?.userInfo?.sub : identity[tenant.tenantId] && identity[tenant.tenantId]?.userInfo?.sub || userIdFromChat,
			tenantId: tenant.id,
		}
		const botContextData = {
			botId: botData?.botId,
			botName: (theme && theme.botName) || botData?.botName,
			landingMessage: isGuestLogin ? 'guestLandingMessage' : botData?.landingMessage,
			landingMsgKey: botData?.landingMsgKey || '',
			locale,
			authUserId: authToken,
		}

		return JSON.stringify({
			userData: {
				...signedUserData,
				authUserId: authToken,
				locale,
				isSupportBot: props.isSupportBot
			},
			signedUserData,
			botContextData,
			botType,
			logTrace: props.traceLog ? 'On' : 'Off',
		})
	}

	function gatherUserAttributes(userDetails) {
		const roles = (props.botStudioBot && identity.roles) ? { roles: identity.roles } : (identity[tenant.tenantId] && identity[tenant.tenantId].roles) ? { roles: identity[tenant.tenantId].roles } : {}

		// REFACTOR-NOTES: why is "userDetails?.attributes" being used as a condition to exclude roles?
		const userAttrs = userDetails?.attributes ? ({ ...roles, ...userDetails.attributes }) : {}

		// REFACTOR-NOTES: pluralize these field names or see if they can be a scalars
		if (!userAttrs.email) {
			userAttrs.email = props.botStudioBot ? [identity.userInfo.email] : [identity[tenant.tenantId].userInfo.email]
		}
		if (!userAttrs.firstName) {
			userAttrs.firstName = [userDetails?.firstName]
		}
		if (!userAttrs.lastName) {
			userAttrs.lastName = [userDetails?.lastName]
		}
		if (!userAttrs.username) {
			userAttrs.username = [userDetails?.username]
		}
		if (!userAttrs.sub) {
			userAttrs.sub = [userDetails?.id || userDetails?.sub]
		}
		return userAttrs
	}

	useEffect(() => {
		// componentWillReceiveProps rewritten as useEffect() with the relevant prop dependencies
		const { micrositeName, pgContext } = props

		setTheme(getMicrositeTheme(micrositeName))

		if (botData) {
			const { chatInfo } = getLSChatInfo(pgContext || tenant.tenantId)
			if (!chatInfo || !chatInfo.chatConversationId) {
				setConversationId(null)
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.micrositeName, botData, props.pgContext, tenant])

	useEffect(() => {
		// componentWillMount() code rewritten as useEffect() with no dependency
		//
		const _ = updateChatState({ updateTokenYN: false })

		if (tenant?.id) {
			setGetConfig(true) // TODO: consider renaming this state to a boolean-naming convention
			dispatch(getDataWithDefaultTenant(tenant.id, tenant.apiUrl))
		}
	}, [])

	// NOTE: use debounce for now to limit the number of getBotData calls.
	// A cleaner approach is to break up the component did update function into smaller useEffect calls with 1 concerning getBot() only
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getBot = useCallback(
		debounce(
			async () => {
				const chatInfo = updateChatState({ updateTokenYN: true })

				const { learnigKey, botStudioBot } = props

				if (tenant?.id) {
					setGetConfig(true) // TODO: change this state name to a more boolean sounding one
					const botInfo = {
						botId: tenant.botId, // can be null too as api will determine
						// TODO: in this case "tenantId" refers to the uuid tenant.id. In others, "tenantId" is
						// the human friendly tenant name. We need to sort this out to prevent confusion
						tenantId: tenant.id,
						conversationId: (chatInfo && chatInfo.chatConversationId) || null,
						learnigKey: learnigKey || null,
						token: chatInfo && chatInfo.token,
						botStudioBot: botStudioBot || false,
					}
					await dispatch(getBotData(botInfo, tenant.apiUrl))
				}
				// eslint-disable-next-line react-hooks/exhaustive-deps
			},
			100,
			{ leading: true, trailing: false }
		),
		[]
	)

	useEffect(() => {
		// componentDidUpdateCode() rewritten as useEffect() with dependencies
		// wrap it in "async" then call the function and call it because we have some "await" calls
		// and useEffect doesn't handle top-level await
		async function componentDidUpdate() {
			try {
				const { botStudioBot, flowDetails, authToken, flowName, botClientType, botType, userPayloadToken } = props
				// console.log(JSON.stringify({bsb: props.botStudioBot,
				// 	mobileProps,
				// 	lmk: botData?.landingMsgKey,
				// 	authenticated,
				// 	identity,
				// 	tenant,
				// 	globalSetting,
				// 	baseProps,
				// 	conversationId,
				// 	userIdFromChat,}))
				// REFACTORING-NOTE: no need for this because this update is done automatically by
				// adding botData.landingMsgKey to the useEffect dependencies
				// if (
				// 	prevProps.bot &&
				// 	prevProps.bot.botData &&
				// 	prevProps.bot.botData.landingMsgKey !== bot.botData.landingMsgKey
				// )
				// 	this.forceUpdate()

				let userDetails
				// debugger
				const identityDef = identity
				// REFACTOR-NOTE: already commented prior to refactor
				//
				// let localValueRoot = localStorage.getItem("persist:root");
				// localValueRoot = localValueRoot ? JSON.parse(localValueRoot) : false;
				// let identityDef = localValueRoot && localValueRoot.identity? JSON.parse(localValueRoot.identity): null
				// if (localValueRoot && localValueRoot.identity && JSON.parse(localValueRoot.identity).isAuthenticated) {
				//   // localStorage.setItem("persist:root_widget", JSON.stringify(localValueRoot))
				//   console.log("inside ")
				//   isAuthenticatedSession = true;
				// }

				// if (
				// 	(tenant.loading !== prevProps.tenant.loading || identity.isInitiated !== prevProps.identity.isInitiated) &&
				// 	!tenant.failed &&
				// 	!identity.error
				// ) {
				// 	if (mobileProps && mobileProps.isGuestLogin) this.state.isGuestLogin = true
				// 	this.setState({ loading: false, success: true })
				// }
				// REFACTOR-NOTES: replaced above with below. Revisit to see if this approximation is good enough
				if (!tenant.loading && !tenant.failed && ((identity[tenant.tenantId] && identity[tenant.tenantId].isAuthenticated) || (props.botStudioBot && identity.isAuthenticated)) && !identity.error) {
					if (mobileProps && mobileProps.isGuestLogin) setIsGuestLogin(true)
					setLoading(false)
					setSuccess(true)
				}

				if (
					!authenticated &&
					tenant &&
					!tenant.loading &&
					!tenant.failed &&
					tenant.userAuthReqd &&
					!(mobileProps && (mobileProps.isNotLogin || mobileProps.isGuestLogin)) &&
					((!identity[tenant.tenantId] || !identity[tenant.tenantId].isAuthenticated || !identity[`token_${tenant.tenantId}`]) || (props.botStudioBot && !identity.isAuthenticated))
				) {
					setAuthenticated(true)

					// if (baseProps && baseProps.checkIdentity) await this.props.baseProps.checkIdentity
					// else if (!this.props.isGuest) await this.props.checkIdentity()
					// REFACTOR-NOTE: replace above with below because bug in the if-part: can't await for a function definition. Have to call it with parens
					if (baseProps?.checkIdentity) {
						await baseProps.checkIdentity(props)
					} else if (!props.isGuest) {
						await dispatch(checkIdentity(props)) // TODO: find out whether these 2 checkIdentity functions are the same
					}
				}

				if ((identity[tenant.tenantId] && identity[tenant.tenantId].isAuthenticated) || (props.botStudioBot && identity.isAuthenticated)) {
					// TODO: should we get rid of the isAuthenticated state altogether and just use identity.isAuthenticated?
					setAuthenticated(true)
				}

				// REFACTORING-NOTES: already commented out prior to refactor
				//
				// else if (isExpired && tenant.userAuthReqd) {
				//   this.props.checkIdentity()
				//   return;
				// }

				// if (
				// 	identity.isInitiated !== prevProps.identity.isInitiated &&
				// 	typeof tenant.userAuthReqd !== 'undefined' &&
				// 	tenant.userAuthReqd &&
				// 	!(mobileProps && (mobileProps.isNotLogin || mobileProps.isGuestLogin)) &&
				// 	!tenant.failed &&
				// 	typeof identity.isAuthenticated !== 'undefined' &&
				// 	identity.isAuthenticated
				// ) {
				// 	this.setState({ loading: false, success: true })
				// }
				// REFACTORING-NOTES: replaced above with below without using prevProps. Revisit to see if this approximation is good enough
				if (
					((identity[tenant.tenantId] &&
						identity[tenant.tenantId].isInitiated &&
						identity[tenant.tenantId].isAuthenticated) || (props.botStudioBot && identity.isInitiated &&
							identity.isAuthenticated)) &&
					tenant.userAuthReqd &&
					!tenant.failed &&
					(mobileProps?.isNotLogin || mobileProps?.isGuestLogin)
				) {
					setLoading(false)
					setSuccess(true)
				}

				// if (tenant.loading !== prevProps.tenant.loading && (tenant.failed || identity.error)) {
				// 	this.setState({ loading: false, success: false })
				// }
				// REFACTORING-NOTES: replaced above with below without using prevProps. Revisit to see if this approximation is good enough
				if (!tenant.loading && (tenant.failed || identity.error)) {
					setLoading(false)
					setSuccess(false)
				}

				if (tenant?.id && !setTenant) {
					if (((identity[tenant.tenantId] && identity[tenant.tenantId]?.userInfo?.sub && identity[tenant.tenantId]?.profile?.tenantId) || (props.botStudioBot && identity?.userInfo?.sub && identity?.profile?.tenantId)) && globalSetting && !globalSetting.loading) {
						setSetTenant(prev => true) // a flag so that the below dont get called multiple times
						// @ts-ignore
						const isExpired = props.botStudioBot ? identity?.exp ? new Date((identity.exp + identity.timeSkew) * 1000) < new Date() : true : (identity[tenant.tenantId] && identity[tenant.tenantId]?.exp) ? new Date((identity[tenant.tenantId].exp + identity[tenant.tenantId].timeSkew) * 1000) < new Date() : true
						if (isExpired) {
							console.log('isExpired', isExpired, identity)
							if (baseProps?.clearIdentity) baseProps.clearIdentity()
							else dispatch(clearIdentity())

							if (baseProps?.checkIdentity) {
								// await this.props.baseProps.checkIdentity
								// REFACTOR-NOTES: replaced above with below because above was not calling the actual function, i.e. no parens
								await baseProps.checkIdentity(props)
							} else if (!props.isGuest) {
								await dispatch(checkIdentity(props))
							}
							// TODO: figure out how to make this hidden return clearer
							return
						}
						// if (!this.props.baseProps) this.props.reInitKeycloak()
						// REFACTOR-NOTES: this mean if baseProps exists, we are not calling reInitKeycloak().
						// What is the full implication of the presence of baseProps?
						if (!baseProps) await dispatch(reInitIdentity(props))

						if (globalSetting && !globalSetting.loading && props.callBack) {
							if (props.botStudioBot) {								
								props.callBack(identity, identityActions.logOut, conversationId, globalSetting)
							} else {
								props.callBack({ ...identity, ...identity[tenant.tenantId], token: identity[`token_${tenant.tenantId}`] }, identityActions.logOut, conversationId, globalSetting)
							}
						}

						// let userFullName =
						// 	identity && identity.userInfo && identity.userInfo.name
						// 		? identity.userInfo.name
						// 		: identityDef && identityDef.userInfo && identityDef.userInfo.name
						// 		? identityDef.userInfo.name
						// 		: 'Anonymous user'
						// let userName =
						// 	identity && identity.userInfo && identity.userInfo.preferred_username
						// 		? identity.userInfo.preferred_username
						// 		: identityDef && identityDef.userInfo && identityDef.userInfo.preferred_username
						// 		? identityDef.userInfo.preferred_username
						// 		: 'Anonymous'
						// REFACTOR-NOTES: not sure why we're doing this. identity and identityDef are the same
						const userFullName = props.botStudioBot ? (identity?.userInfo?.name || identityDef?.userInfo?.name || 'Anonymous user') : identity[tenant.tenantId]?.userInfo?.name || identityDef[tenant.tenantId]?.userInfo?.name || 'Anonymous user'
						const userName = props.botStudioBot ? (identity?.userInfo?.preferred_username || identityDef?.userInfo?.preferred_username || 'Anonymous') :
							identity[tenant.tenantId]?.userInfo?.preferred_username || identityDef[tenant.tenantId]?.userInfo?.preferred_username || 'Anonymous'


						if (props.botStudioBot) {
							await dispatch(getNotificationCount(tenant.id, identity.profile.email, tenant.apiUrl))
						} else {
							await dispatch(getNotificationCount(tenant.id, identity[tenant.tenantId].profile.email, tenant.apiUrl))
						}

						// REFACTOR-NOTES: already commented out prior to refactor
						// this.getBot();

						const queryParameters = identityServices.getQueryParameters()
						console.log('Query params from bot component', queryParameters)

						if (queryParameters && (queryParameters.isTeams === true || queryParameters.isTeams === 'true')) {
							userDetails = props.botStudioBot ? identity.profile : identity[tenant.tenantId].profile
						} else {
							userDetails = props.botStudioBot ? identity?.profile : identity[tenant.tenantId]?.profile
							if (props.botStudioBot) {
								userDetails = await identityActions.getUserById(
									identity.profile.tenantId,
									identity.userInfo.sub,
									identity.token,
									tenant.id,
									tenant.apiUrl
								)
							} else {
								userDetails = await identityActions.getUserById(
									identity[tenant.tenantId].profile.tenantId,
									identity[tenant.tenantId].userInfo.sub,
									identity[`token_${identity[tenant.tenantId].profile.tenantId}`],
									tenant.id,
									tenant.apiUrl
								)
							}
						}
						const _userAttributes = gatherUserAttributes(userDetails)
						setSetTenant(prev => true)
						setUserAttributes(_userAttributes)
						setAttributes(userDetails?.attributes || '')

						const _userData = {
							tenantId: tenant.id,
							realmName: tenant.tenantId,
							userId: (_userAttributes?.sub && _userAttributes.sub[0]) || userIdFromChat,
							authUserId: authToken || '',
							locale,
							userAttributes: { ..._userAttributes, website: props.website, ...props.otherInfo },
							userIntent: botStudioBot ? { ...flowDetails } : !conversationId && flowName ? flowName : null,
							botClientType: botClientType || null,
							botType: botType || null,
							userFullName,
							userName,
							initialDialogInitiated: !!conversationId,
							userPayloadToken,
							micrositeAttr: props.micrositeAttr,
							botStudioBot: botStudioBot || false,
						}

						if (userDetails?.attributes) {
							if (props.botStudioBot) {
								await Promise.all([
									dispatch(saveUserData({ source: 'authWithAttr', ..._userData }, identity.token, tenant.apiUrl)),
									getBot(),
								])
							} else {
								await Promise.all([
									dispatch(saveUserData({ source: 'authWithAttr', ..._userData }, identity[`token_${tenant.tenantId}`], tenant.apiUrl)),
									getBot(),
								])
							}
						} else {
							if (props.botStudioBot) {
								await Promise.all([
									dispatch(saveUserData({ source: 'authWithoutAttr', ..._userData }, identity.token, tenant.apiUrl)),
									getBot(),
								])
							} else {
								await Promise.all([
									dispatch(saveUserData({ source: 'authWithoutAttr', ..._userData }, identity[`token_${tenant.tenantId}`], tenant.apiUrl)),
									getBot(),
								])
							}
						}
					} else {
						if (
							tenant &&
							(!tenant.userAuthReqd ||
								props.isGuest ||
								(mobileProps && (mobileProps.isNotLogin || mobileProps.isGuestLogin)))
						) {
							let userFullName = mobileProps && mobileProps.fullName ? mobileProps.fullName : ''
							let userName = mobileProps && mobileProps.username ? mobileProps.username : ''
							if (
								mobileProps &&
								mobileProps.tenantId &&
								mobileProps.sub &&
								mobileProps.token &&
								mobileProps.tenantUid
							) {
								userDetails = await identityActions.getUserById(
									mobileProps.tenantId,
									mobileProps.sub,
									mobileProps.token,
									mobileProps.tenantUid,
									mobileProps.apiUrl
								)
							}
							if (!userFullName) {
								if (props.botStudioBot) {
									userFullName = identity && identity.userInfo && identity.userInfo.name
										? identity.userInfo.name
										: identityDef && identityDef.userInfo && identityDef.userInfo.name
											? identityDef.userInfo.name : 'Anonymous user'
								} else {
									userFullName =
										identity && identity[tenant.tenantId] && identity[tenant.tenantId]?.userInfo && identity[tenant.tenantId]?.userInfo.name
											? identity[tenant.tenantId]?.userInfo.name
											: identityDef && identityDef[tenant.tenantId]?.userInfo && identityDef[tenant.tenantId]?.userInfo.name
												? identityDef[tenant.tenantId]?.userInfo.name
												: 'Anonymous user'
								}
							}
							if (!userName) {
								if (props.botStudioBot) {
									userName =
									identity && identity.userInfo && identity.userInfo.preferred_username
										? identity.userInfo.preferred_username
										: identityDef && identityDef.userInfo && identityDef.userInfo.preferred_username
										? identityDef.userInfo.preferred_username : 'Anonymous'
								} else {
									userName =
										identity && identity[tenant.tenantId] && identity[tenant.tenantId]?.userInfo && identity[tenant.tenantId]?.userInfo.preferred_username
											? identity[tenant.tenantId]?.userInfo.preferred_username
											: identityDef && identityDef[tenant.tenantId]?.userInfo && identityDef[tenant.tenantId]?.userInfo.preferred_username
												? identityDef[tenant.tenantId]?.userInfo.preferred_username
												: 'Anonymous'
								}
							}

							setSetTenant(prev => true)
							let _userAttributes: any = {}

							if (mobileProps && mobileProps.userAttributes) {
								_userAttributes = mobileProps.userAttributes
							}

							if (userDetails && userDetails.attributes) {
								_userAttributes = { ..._userAttributes, ...userDetails.attributes }
							}
							if (mobileProps && mobileProps.isGuestLogin) {
								_userAttributes.guestRole = ['guest']
							}
							setUserAttributes(_userAttributes)
							setAttributes('')
							// TODO: userDetails.attributes is an object, but attributes is a string.
							// We need to figure out a more efficient naming convention

							const tenantData = {
								tenantId: tenant.id,
								userId: userIdFromChat,
								authUserId: authToken || '',
								locale,
								userAttributes: { ..._userAttributes, website: props.website, ...props.otherInfo },
								userIntent: !conversationId && flowName ? flowName : null,
								botClientType: botClientType || null,
								initialDialogInitiated: !!(mobileProps?.initialDialogInitiated || conversationId),
								botType: botType || null,
								userFullName,
								userName,
								userPayloadToken,
								source: 'noAuth',
								micrositeAttr: props.micrositeAttr,
							}

							await Promise.all([dispatch(saveTenantId(tenantData)), getBot()])
						}
						// TODO: there is significant code repetition in the above if-else block.
						// Let's doing some DRY-ing later.
					}
					// REFACTOR-NOTES: already commented out prior to refactor
					// fetch('https://api.ipify.org/?format=json', {mode: 'cors'})
					// .then(response => response.json())
					// .then(response => {
					//   console.log("Response",response.ip)
					//   this.props.saveTenantId(tenant.id, this.state.userId,response.ip ,'');
					// }).catch(err=>{

					// })
				}

				// REFACTOR-NOTES: already commented out prior to refactor
				// if (tenant && tenant.id && !this.getConfig && this.setTenant) {
				//   this.getConfig = true;
				//   let botID = { 'botId': botId, tenantId: tenant.id, conversationId: this.state.conversationId, learnigKey : learnigKey || null };
				//
				//   this.props.getBot(botID, tenant.id);
				//   // this.props.getLang(tenant.id);
				//   this.props.getWhiteLabelWithDefaultTenant(tenant.id)
				// }

				if (!isEventRegistered) registerEvents()
			} catch (e) {
				// TODO: we need to break up this huge try-block
				console.log('Error in rgester event ', e)
			}
		}
		componentDidUpdate()
	}, [
		props.botStudioBot,
		mobileProps,
		botData?.landingMsgKey,
		authenticated,
		identity,
		tenant,
		globalSetting,
		baseProps,
		conversationId,
		userIdFromChat,
		// isEventRegistered,
		setTenant
	])

	// REFACTOR-NOTES: already commented out prior to refactor
	// floatBtnShow() {
	//   if (document.getElementById('float-show')) {
	//     document.getElementById('float-show').style.display = "block";
	//   }
	// }
	// shouldComponentUpdate(prevProps, nextProps) {
	//   if (
	//     prevProps.bot &&
	//     prevProps.bot.botData &&
	//     prevProps.bot.botData.landingMsgKey !==
	//       nextProps.bot.botData.landingMsgKey
	//   )
	//     return true
	//   else return false
	// }

	function registerEvents() {
		// REFACTOR-NOTE: comment out because not used
		//
		// const { t, botTheme, tenant, authToken } = this.props
		// const { theme } = this.state

		// REFACTOR-NOTE: already commented out prior to refactor
		// if(!theme) {
		//   this.registerEvents();
		//   return;
		// }

		// REFACTOR-NOTE: comment out because _mobileProps and "that" aren't used
		//
		// let _mobileProps
		// let that = this
		// if (mobileProps && typeof mobileProps == 'string' && isJson(mobileProps)){
		// 	_mobileProps = JSON.parse(mobileProps)
		// }

		// REFACTOR-NOTE: comment out because not used
		//
		// const swalWithBootstrapButtons = Swal.mixin({
		// 	customClass: {
		// 		confirmButton: 'btn btn-success',
		// 		cancelButton: 'btn btn-danger',
		// 	},
		// 	buttonsStyling: true,
		// })

		// this.isEventRegistered = true
		setIsEventRegistered(true)

		// NOTE: which browser is this polyfill for? Modern browsers should all have .remove()
		if (!('remove' in Element.prototype)) {
			// @ts-ignore
			Element.prototype.remove = function () {
				if (this.parentNode) {
					this.parentNode.removeChild(this)
				}
			}
		}
		// REFACTOR-NOTE: comment out bc this is just a no-op try block
		// try {
		// } catch (err) {
		// 	console.log('JSON flow parsing error', err)
		// }
	}

	// REFACTOR-NOTE: render() begins here. States are declared at the top of the component with useState hooks
	// classNames and learnigKey are removed because not used
	const { authToken, botType } = props

	const channelAgent = mobileProps?.mobileApp ? 'Mobile App' : detectChannelAgentType()
	const userFullName = props.botStudioBot ? (mobileProps?.fullName || identity?.userInfo?.name || 'Anonymous user') : mobileProps?.fullName || (identity && identity[tenant.tenantId] && identity[tenant.tenantId]?.userInfo?.name) || 'Anonymous user'
	const username = props.botStudioBot ? (mobileProps?.username || identity?.userInfo?.preferred_username || 'Anonymous') : mobileProps?.username || (identity && identity[tenant.tenantId] && identity[tenant.tenantId]?.userInfo?.preferred_username) || 'Anonymous'

	// TODO: change this to a config-level variable or function
	const imageUrl = botData ? `https://as-cdn.azureedge.net/cdn/${botData.botLogo || 'avt2.png'}` : ''
	const jsonEncodedChatData = botData ? stringifyChatData() : ''

	// REFACTOR-NOTE: already commented out prior to refactor
	// alert('yes')
	// var isAttachment = theme && (/true/i).test(theme.isAttachment)
	// if (loading) return <Loader />

	if (botData) {
		return (
			<div id="botChatDiv" upload-btn="true">
				<GlobalStyle
					// @ts-ignore -- TODO: fix GlobalStyle props to include botTheme
					botTheme={theme && theme.botTheme ? theme.botTheme : '#1e90ff'}
					botStyle={theme && theme.botStyle}
				/>

				{botData && (botData.token || botData.botSecret) && (
					<MinimizableWebChat
						userId={props.botStudioBot ? (identity?.userInfo?.sub) : identity[tenant.tenantId]?.userInfo?.sub || userIdFromChat}
						botAvatar={theme && theme.botImage ? theme && theme.botImage : imageUrl}
						agentAvatar={theme && theme.agentImage || ''}
						username={userFullName || username}
						botName={(theme && theme.botName) || botData.botName}
						chatData={jsonEncodedChatData}
						user={{
							id: props.botStudioBot ? identity?.userInfo?.sub : identity[tenant.tenantId]?.userInfo?.sub || userIdFromChat, // TODO: this is repeated a lot. We need a getUserId(identity) function
							userFullName,
							username,
							attributes,
							channelAgent,
							email: props.botStudioBot ? identity?.userInfo?.email : identity[tenant.tenantId]?.userInfo?.email,
						}}
						botTheme={theme?.botTheme || '#FFF'} // TODO: better variable name needed. This is just a color, not a theme.
						mobileData={mobileProps}
						menuFlow={menuFlow}
						theme={theme}
						userAttributes={userAttributes}
						conversationId={conversationId}
						isConnetedToAgent={isConnectedToAgent}
						isEnableLastAC={isEnableLastAC}
						pgCtx={props.pgContext || tenant.tenantId}
						botId={botData.botId}
						// REFACTOR-NOTES: original props were broken up earlier into the following fields,
						// here we put them back so the same original props content is passed through
						baseProps={baseProps}
						mobileProps={mobileProps}
						{...props}
					/>
				)}
			</div>
		)
	}

	return <span></span>
}

export default BotComponent

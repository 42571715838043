import locale2 from 'locale2'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecureLS from 'secure-ls'

interface WidgetUiContextType {
	uiClassNames: string
	setUiClassNames: React.Dispatch<React.SetStateAction<string>>
	chatContainerClasses: string
	setChatContainerClasses: React.Dispatch<React.SetStateAction<string>>
	uiTheme: string
	setUiTheme: React.Dispatch<React.SetStateAction<string>>
	restartIcon: string
	setRestartIcon: React.Dispatch<React.SetStateAction<string>>
	liveChatIcon: string
	setliveChatIcon: React.Dispatch<React.SetStateAction<string>>
	chatOptionImage: string
	displayTooltip: boolean
	setDisplayTooltip: React.Dispatch<React.SetStateAction<boolean>>
	showAlert: boolean
	setShowAlert: React.Dispatch<React.SetStateAction<boolean>>
	disableSendBox: boolean
	setDisableSendBox: React.Dispatch<React.SetStateAction<boolean>>
	hideDisconnect: boolean
	setHideDisconnect: React.Dispatch<React.SetStateAction<boolean>>
	chatMinimize: boolean
	setChatMinimize: React.Dispatch<React.SetStateAction<boolean>>
	handleMinimize: () => void
	regEvent: boolean
	setRegEvent: React.Dispatch<React.SetStateAction<boolean>>
	isregisteredEvents: boolean
	setIsregisteredEvents: React.Dispatch<React.SetStateAction<boolean>>
	conversationId: string
	setConversationId: React.Dispatch<React.SetStateAction<string>>
	showResetBtn: boolean
	setShowResetBtn: React.Dispatch<React.SetStateAction<boolean>>
	chatOption: boolean
	setChatOption: React.Dispatch<React.SetStateAction<boolean>>
	resetChatOptions: () => void
	locale: string
	setLocale: React.Dispatch<React.SetStateAction<string>>
	ls: SecureLS
}

const WidgetUiContext = createContext<WidgetUiContextType | undefined>(undefined)

interface WidgetUiProviderProps {
	lang?: string // TODO: temporarily park this prop here. Eventually should be handled along with other i18n stuff
	children: React.ReactNode
	defaultSetLang?: any
	metadata?: any
	aiBot?: any
}
export default function WidgetUiProvider({ lang, children, defaultSetLang, metadata, aiBot }: WidgetUiProviderProps) {
	const images = {
		restart: 'https://as-cdn.azureedge.net/cdn/restart-white.png',
		chatOption: 'https://as-cdn.azureedge.net/cdn/exp-icon.png',
		liveChatIcon: 'https://as-cdn.azureedge.net/cdn/agent-icon.png',
		minimizeIcon: 'https://as-cdn.azureedge.net/cdn/w-expand-sprit.png',
	}

	const [uiClassNames, setUiClassNames] = useState('')
	const [chatContainerClasses, setChatContainerClasses] = useState('')
	const [uiTheme, setUiTheme] = useState('')
	const [restartIcon, setRestartIcon] = useState(images.restart)
	const [liveChatIcon, setliveChatIcon] = useState(images.liveChatIcon)
	const [displayTooltip, setDisplayTooltip] = useState(true)
	const [showAlert, setShowAlert] = useState(false)
	const [disableSendBox, setDisableSendBox] = useState(false)
	const [hideDisconnect, setHideDisconnect] = useState(true)
	const [showResetBtn, setShowResetBtn] = useState(false)

	// const [chatMinimize, setChatMinimize] = useState(props.isMaximize ? false : true)
	const [chatMinimize, setChatMinimize] = useState(true)

	const [regEvent, setRegEvent] = useState(false)
	const [isregisteredEvents, setIsregisteredEvents] = useState(false)

	const [conversationId, setConversationId] = useState('')
	const [chatOption, setChatOption] = useState(false)

	const { i18n } = useTranslation()
	function determineActiveLang() {
		// const lng = i18n.language //should be equivalent to window?.localStorage?.getItem('i18nextLng')
		return defaultSetLang ? defaultSetLang : lang || i18n.language?.slice(0, 5) || (locale2 as string).slice(0, 2)
	}
	const [locale, setLocale] = useState<string>(determineActiveLang)

	function setActiveLang() {
		// REFACTOR-NOTE: this impl may have a bug. Why condition on "lng" if "lang" has priority any way.
		// const lng = window && window.localStorage && window.localStorage.getItem('i18nextLng')
		const toggleLangSessStge = sessionStorage.getItem('toggleLang')
		if (defaultSetLang && !toggleLangSessStge) {
			sessionStorage.setItem('toggleLang', 'true')
		}
		const lng = i18n.language
		if (lng) {
			setLocale((defaultSetLang && !toggleLangSessStge) ? defaultSetLang : (lang || i18n.language?.slice(0, 5) || (locale2 as string).slice(0, 2)))
		}
	}

	useEffect(() => {
		setActiveLang()
		if (metadata?.fn?.maximize || aiBot) {
			const el = document.querySelector('.chat-container')
			if (!chatMinimize && el && el.classList) {
				setUiClassNames('maximize-bot-next')
				const elMaxIcon: HTMLElement | null = document.querySelector('.btn-mazimize-icon')
				const elMinIcon: HTMLElement | null = document.querySelector('.btn-nimimize-icon')
				if (elMaxIcon && elMinIcon) {
					elMaxIcon.style.display = 'inline'
					elMinIcon.style.display = 'none'
				}
			} else {
				setUiClassNames('maximize-bot maximize-bot-next')
				const elMaxIcon: HTMLElement | null = document.querySelector('.btn-mazimize-icon')
				const elMinIcon: HTMLElement | null = document.querySelector('.btn-nimimize-icon')
				if (elMaxIcon && elMinIcon) {
					elMaxIcon.style.display = 'none'
					elMinIcon.style.display = 'inline'
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleMinimize = useCallback(() => {
		setChatMinimize(!chatMinimize)
		const el = document.querySelector('.chat-container')
		if (!chatMinimize && el && el.classList) {
			setUiClassNames('maximize-bot-next')
			// el.classList.remove('maximize-bot');
			// el.classList.add('maximize-bot-next');
			const elMaxIcon: HTMLElement | null = document.querySelector('.btn-mazimize-icon')
			const elMinIcon: HTMLElement | null = document.querySelector('.btn-nimimize-icon')
			if (elMaxIcon && elMinIcon) {
				elMaxIcon.style.display = 'inline'
				elMinIcon.style.display = 'none'
			}
		} else {
			setUiClassNames('maximize-bot maximize-bot-next')
			// el.classList.add('maximize-bot');
			// el.classList.add('maximize-bot-next');
			const elMaxIcon: HTMLElement | null = document.querySelector('.btn-mazimize-icon')
			const elMinIcon: HTMLElement | null = document.querySelector('.btn-nimimize-icon')
			if (elMaxIcon && elMinIcon) {
				elMaxIcon.style.display = 'none'
				elMinIcon.style.display = 'inline'
			}
		}
	}, [chatMinimize, setChatMinimize, setUiClassNames])

	const resetChatOptions = useCallback(() => {
		setChatOption(!chatOption)
		setShowAlert(false)
	}, [chatOption, setChatOption, setShowAlert])

	const ls = new SecureLS({
		encodingType: 'des',
		isCompression: false,
		// TODO: we should tack away from this!
		encryptionSecret: 'f145bcdd-1125-4eb6-a360-5690064c585b',
	})

	const ctxValue = useMemo(
		() => ({
			uiClassNames,
			setUiClassNames,
			chatContainerClasses,
			setChatContainerClasses,
			uiTheme,
			setUiTheme,
			restartIcon,
			setRestartIcon,
			liveChatIcon,
			setliveChatIcon,
			chatOptionImage: images.chatOption,
			displayTooltip,
			setDisplayTooltip,
			showAlert,
			setShowAlert,
			disableSendBox,
			setDisableSendBox,
			hideDisconnect,
			setHideDisconnect,
			chatMinimize,
			setChatMinimize,
			handleMinimize,
			regEvent,
			setRegEvent,
			isregisteredEvents,
			setIsregisteredEvents,
			conversationId,
			setConversationId,
			showResetBtn,
			setShowResetBtn,
			chatOption,
			setChatOption,
			resetChatOptions,
			locale,
			setLocale,
			ls,
		}),
		[
			uiClassNames,
			setUiClassNames,
			chatContainerClasses,
			setChatContainerClasses,
			uiTheme,
			setUiTheme,
			restartIcon,
			setRestartIcon,
			liveChatIcon,
			setliveChatIcon,
			images.chatOption,
			displayTooltip,
			setDisplayTooltip,
			showAlert,
			setShowAlert,
			disableSendBox,
			setDisableSendBox,
			hideDisconnect,
			setHideDisconnect,
			chatMinimize,
			setChatMinimize,
			handleMinimize,
			regEvent,
			setRegEvent,
			isregisteredEvents,
			setIsregisteredEvents,
			conversationId,
			setConversationId,
			showResetBtn,
			setShowResetBtn,
			chatOption,
			setChatOption,
			resetChatOptions,
			locale,
			setLocale,
			ls,
		]
	)

	return <WidgetUiContext.Provider value={ctxValue}>{children}</WidgetUiContext.Provider>
}
export const useWidgetUiContext = () => {
	const ctx = useContext(WidgetUiContext)
	if (!ctx) {
		throw new Error('useWidgetUiContext must be used within a WidgetUiProvider')
	}
	return ctx
}

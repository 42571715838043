import React from 'react'
import ReactDOM from 'react-dom'
import App from './app/App'
import { BaseProps } from './app/bot/interfaces'
// import '../vendor/cleanslate.css';

// Added below code for remove element on load
// issue multi page unmount not working in javascript code
const elmcontainer = document.querySelectorAll('.chat-container')
if (elmcontainer && elmcontainer.length > 0) {
	for (let i = 0; i < elmcontainer.length; i++) {
		const elm = elmcontainer[i].parentNode
		if (elm && elm.id === 'botChatDiv') {
			elm.parentNode.parentNode.parentNode.removeChild(elm.parentNode.parentNode)
		}
	}
}

interface WidgetProps {
	showBotCustom?: unknown
	lang: string
	baseProps?: BaseProps
}
export default class EmbeddableWidget {
	static el: HTMLElement

	static mount(
		tenantId: string,
		authToken: string,
		mobileProps: any,
		props: WidgetProps,
		userPayloadToken: string,
		callBack: (...args: any[]) => void
	) {
		console.log(
			'*****',
			`widget-version: ${process.env.REACT_APP_VERSION || 'unknown'} `,
			`tenantId: ${tenantId} `,
			`user-lang: ${props && props.lang}`
		)

		const component = (
			<App
				tenantId={tenantId}
				authToken={authToken}
				mobileProps={mobileProps}
				{...props}
				userPayloadToken={userPayloadToken}
				callBack={callBack}
			/>
		)
		function doRender() {
			if (EmbeddableWidget.el) {
				throw new Error('EmbeddableWidget is already mounted, unmount first')
			}
			const el = document.createElement('div')
			// el.setAttribute('class', 'cleanslate');
			document.body.appendChild(el)
			ReactDOM.render(component, el)
			EmbeddableWidget.el = el
		}
		if (document.readyState === 'complete') {
			doRender()
		} else {
			window.addEventListener('load', () => {
				doRender()
			})
		}
	}

	static unmount() {
		if (!EmbeddableWidget.el) {
			throw new Error('EmbeddableWidget is not mounted, mount first')
		}
		ReactDOM.unmountComponentAtNode(EmbeddableWidget.el)
		EmbeddableWidget.el.parentNode?.removeChild(EmbeddableWidget.el)
		EmbeddableWidget.el = null
	}
}

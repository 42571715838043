import { createAction } from '@reduxjs/toolkit'
import { AppDispatch, AppGetState } from 'src/redux/store'
import * as identityConstants from '../constants/identity.constants'
import { IdentityState } from '../reducers/identity.reducers'
import { TenantState } from '../reducers/tenant.reducers'
import * as identityServices from '../services/identity.services'
import * as settingsActions from './settings.actions'
// export const initiateLogin = () => {
// 	sessionStorage.clear()
// 	return {
// 		type: identityConstants.LOGIN_INITIATE,
// 		payload: {
// 			isInitiated: true,
// 			isAuthenticated: false,
// 		},
// 	}
// }
export const initiateLogin = createAction(identityConstants.LOGIN_INITIATE, (tenant, props) => {
	sessionStorage.clear() // NOTE: this may not be the right place to for this side-effect
	if (props?.botStudioBot) {
		return {
			payload: {
				isInitiated: true,
				isAuthenticated: false,
			}
		}
	}
	return {
		payload: {
			[tenant.tenantId]: {
				isInitiated: true,
				isAuthenticated: false,
			}
		},
	}
})
export const setIdentitySuccessMs = (userInfo: unknown, tenant: TenantState, props: any) =>
	function setIdSuccessMs(dispatch: AppDispatch) {
		// debugger
		// let keycloak = identityServices.keycloakInstances[tenant.tenantId]
		// if (!singleTenant) {
		dispatch(settingsActions.getAllGlobalSettings(tenant.id, userInfo.profile.preferred_username, tenant.apiUrl))
		dispatch(settingsActions.getAllTenantConfig((tenant && tenant.id) || null, tenant.apiUrl))
		// dispatch(settingsActions.getSsoData(tenant.tenantId))
		// dispatch(whiteLabelActions.getDataWithDefaultTenant(tenant.id))
		// }

		if (props.botStudioBot) {
			return dispatch({
				type: identityConstants.SET_IDENTITY,
				payload: {
					userInfo: userInfo.userInfo,
					isAuthenticated: true,
					isInitiated: false,
					profile: {
						...userInfo.userInfo,
						...userInfo.profile,
					},
					roles: userInfo.profile.roles, // keycloak.realmAccess && keycloak.realmAccess.roles ? keycloak.realmAccess.roles : null,
					exp: userInfo.exp,
					iat: userInfo.iat,
					timeSkew: userInfo.timeSkew || 0,
					expiryDate: userInfo.expiryDate,
				},
			})
		}

		return dispatch({
			type: identityConstants.SET_IDENTITY,
			payload: {
				[tenant.tenantId]: {
					userInfo: userInfo.userInfo,
					isAuthenticated: true,
					isInitiated: false,
					profile: {
						...userInfo.userInfo,
						...userInfo.profile,
					},
					roles: userInfo.profile.roles, // keycloak.realmAccess && keycloak.realmAccess.roles ? keycloak.realmAccess.roles : null,
					exp: userInfo.exp,
					iat: userInfo.iat,
					timeSkew: userInfo.timeSkew || 0,
					expiryDate: userInfo.expiryDate,
				}
			},
		})
	}

export const loginCheckRequest = (tenant, props) =>
// sessionStorage.clear();
(props.botStudioBot ? ({
	type: identityConstants.LOGIN_CHECK,
	payload: {
		isInitiated: true,
	},
}) : ({
	type: identityConstants.LOGIN_CHECK,
	payload: {
		[tenant.tenantId]: { isInitiated: true, }
	},
}))

// export const loginSuccessMs = token => {
// 	sessionStorage.setItem('ms_access_token', token)

// 	return {
// 		type: identityConstants.LOGIN_SUCCESS,
// 		payload: {
// 			token: token,
// 		},
// 	}
// }
export const loginSuccessMs = createAction(identityConstants.LOGIN_SUCCESS, (token: string, tenant: any, props: any) => {
	sessionStorage.setItem('ms_access_token', token) // NOTE: move this side effect to somewhere more appropriate
	if (props.botStudioBot) {
		return {
			payload: { token }
		}
	}
	return {
		payload: {
			[`token_${tenant.tenantId}`]: token,
		},
	}
})
export const loginCheckSuccess = (tenant, props) => {
	sessionStorage.clear()
	if (props.botStudioBot) {
		return {
			type: identityConstants.LOGIN_CHECK,
			payload: {
				isInitiated: false,
			},
		}
	}
	return {
		type: identityConstants.LOGIN_CHECK,
		payload: {
			[tenant.tenantId]: { isInitiated: false, }
		},
	}
}

export const loginSuccess = (token: string, tenant: any, props: any) => {
	sessionStorage.setItem('keycloak_token', token)
	if (props.botStudioBot) {
		return {
			type: identityConstants.LOGIN_SUCCESS,
			payload: {
				token,
			},
		}
	}
	return {
		type: identityConstants.LOGIN_SUCCESS,
		payload: {
			[`token_${tenant.tenantId}`]: token,
		},
	}
}

// export const loginFailure = () => {
// 	sessionStorage.clear()
// 	return {
// 		type: identityConstants.LOGIN_FAILURE,
// 		payload: {
// 			isAuthenticated: false,
// 			isInitiated: false,
// 			token: null,
// 			userInfo: {},
// 		},
// 	}
// }
export const loginFailure = createAction(identityConstants.LOGIN_FAILURE, (tenant, props) => {
	sessionStorage.clear() // NOTE: undesirable side-effect
	if (props.botStudioBot) {
		return {
			payload: {
				isAuthenticated: false,
				isInitiated: false,
				token: null,
				userInfo: {},
			},
		}
	}
	return {
		payload: {
			[tenant.tenantId]: {
				isAuthenticated: false,
				isInitiated: false,
				[`token_${tenant.tenantId}`]: null,
				userInfo: {},
			}
		},
	}
})
export const reInitIdentity = (props) => async (dispatch: AppDispatch, getState: AppGetState) => {
	const { tenant, identity } = getState()
	await identityServices.reInitIdentityService(tenant, identity, dispatch, props)
}
// export const clearIdentity = () => {
// 	sessionStorage.clear()
// 	return {
// 		type: identityConstants.LOGOUT_SUCCESS,
// 	}
// }
export const clearIdentity = createAction(identityConstants.LOGOUT_SUCCESS, () => {
	sessionStorage.clear() // NOTE: may be use rtk listener api to handle this side-effect
	return {
		payload: null,
	}
})

export const logOut = (tenant: TenantState) => {
	identityServices.logoutService(tenant)
}

export const logout = () => (dispatch: AppDispatch, getState: AppGetState) => {
	const { tenant } = getState()
	dispatch(clearIdentity())
	identityServices.logoutService(tenant)
}

export const setIdentitySuccess = (userInfo: unknown, tenant: TenantState, props: any) =>
	function setIdSuccess(dispatch: AppDispatch) {
		const keycloak = identityServices.keycloakInstances[tenant.tenantId]
		dispatch(settingsActions.getAllGlobalSettings(tenant.id, userInfo.preferred_username, tenant.apiUrl))
		dispatch(settingsActions.getAllTenantConfig((tenant && tenant.id) || null, tenant.apiUrl))
		if (props.botStudioBot) {
			return dispatch({
				type: identityConstants.SET_IDENTITY,
				payload: {
					userInfo,
					isAuthenticated: true,
					isInitiated: false,
					profile: {
						...userInfo,
						tenantId: tenant.tenantId,
						tenantUid: tenant.id,
						userId: userInfo.sub,
						roles: keycloak.realmAccess && keycloak.realmAccess.roles ? keycloak.realmAccess.roles : null,
						tenantRoles: keycloak.realmAccess && keycloak.realmAccess.roles ? keycloak.realmAccess.roles : null,
						// created_at: userInfo.profile.attributes.created_at[0],
					},
					roles: keycloak.realmAccess && keycloak.realmAccess.roles ? keycloak.realmAccess.roles : null,
					exp: keycloak.tokenParsed.exp,
					iat: keycloak.tokenParsed.iat,
					timeSkew: keycloak.timeSkew,
					expiryDate: new Date((keycloak.tokenParsed.exp + keycloak.timeSkew) * 1000).toLocaleString()
				},
			})
		}
		return dispatch({
			type: identityConstants.SET_IDENTITY,
			payload: {
				[`${tenant.tenantId}`]: {
					userInfo,
					isAuthenticated: true,
					isInitiated: false,
					profile: {
						...userInfo,
						tenantId: tenant.tenantId,
						tenantUid: tenant.id,
						userId: userInfo.sub,
						roles: keycloak.realmAccess && keycloak.realmAccess.roles ? keycloak.realmAccess.roles : null,
						tenantRoles: keycloak.realmAccess && keycloak.realmAccess.roles ? keycloak.realmAccess.roles : null,
						// created_at: userInfo.profile.attributes.created_at[0],
					},
					roles: keycloak.realmAccess && keycloak.realmAccess.roles ? keycloak.realmAccess.roles : null,
					exp: keycloak.tokenParsed.exp,
					iat: keycloak.tokenParsed.iat,
					timeSkew: keycloak.timeSkew,
					expiryDate: new Date((keycloak.tokenParsed.exp + keycloak.timeSkew) * 1000).toLocaleString()
				},
			},
		})
		// return Promise.all([getUserRole(tenant.tenantId, userInfo.sub),getRolesByTenant(tenant.tenantId)]).then(roles=>{
		//     dispatch({

		//         type: identityConstants.SET_IDENTITY,
		//         payload: {
		//             userInfo: userInfo,
		//             isAuthenticated: true,
		//             isInitiated: false,
		//             profile: {
		//                 ...userInfo,
		//                 tenantId: tenant.tenantId,
		//                 tenantUid: tenant.id,
		//                 userId: userInfo.sub,
		//                 roles: roles[0],
		//                 tenantRoles: roles[1]
		//                 // created_at: userInfo.profile.attributes.created_at[0],
		//             },
		//             roles: keycloak.realmAccess.roles,
		//             exp: keycloak.tokenParsed.exp,
		//             iat: keycloak.tokenParsed.iat,
		//             timeSkew: keycloak.timeSkew,
		//             expiryDate: new Date((keycloak.tokenParsed.exp + keycloak.timeSkew) * 1000).toLocaleString()
		//         }
		//     })
		// })
		// identityServices.getUserRoleById(tenant.tenantId, userInfo.sub).then(res=>{
		//     console.log("yesrds.....", res);

		// });
	}

export const setIdentityActionCreator = createAction<any>(identityConstants.SET_IDENTITY)

export const setIdentitySuccessLocal = (userInfo, tenant, identity: IdentityState) =>
	function setIdSuccessLocal(dispatch) {
		return dispatch({
			type: identityConstants.SET_IDENTITY,
			payload: identity,
		})
	}

export const setIdentity = (props) => (dispatch: AppDispatch, getState: AppGetState) => {
	const { tenant } = getState()
	identityServices.keycloakInstances[tenant.tenantId]
		.loadUserInfo()
		.success(userInfo => setTimeout(() => { dispatch(setIdentitySuccess(userInfo, tenant, props)) }, 3000))
		.error(err => dispatch(loginFailure(tenant, props)))
}

export const checkIdentity = (props) => async (dispatch: AppDispatch, getState: AppGetState) => {
	const { tenant, identity } = getState()
	// debugger;
	if (tenant.isTenant) {
		if ((props.botStudioBot && identity.isAuthenticated) || (identity[tenant.tenantId] && identity[tenant.tenantId].isAuthenticated)) {
			dispatch(loginCheckRequest(tenant, props))
		}

		identityServices.initiateIdentityService(tenant, dispatch, props)

		await identityServices.ensureAuthentication({ dispatch, identity, tenant, props })
	}
}
export const getUserById = async (
	tenantId: string,
	userId: string,
	token: string,
	tenantUid: string,
	apiUrl: string
) => {
	const userInfo = await identityServices.getUserById(tenantId, userId, token, tenantUid, apiUrl)
	return userInfo
}

const getUserRole = async (tenantId, userId, apiUrl) => {
	const roles = await identityServices.getUserRoleById(tenantId, userId, apiUrl)
	return roles
}

const getRolesByTenant = async (tenantId, apiUrl) => {
	let roles = await identityServices.getRolesByTenant(tenantId, apiUrl)
	roles = await Promise.all(
		roles.map(async role => {
			const _role = await identityServices.getTenantRoleById(tenantId, role.id)
			return _role
		})
	)
	return roles
}

export const getTenantRoleById = async (tenantId: string, roleId: string, apiUrl: string) => {
	const roles = await identityServices.getRolesByTenant(tenantId, apiUrl)
	return roles
}

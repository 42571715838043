import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Store } from 'redux'
import { useWidgetUiContext } from '../WidgetUiContext'

interface DisconnectBtnProps {
	store: Store
	hideUploadButton: boolean
	setHideUploadButton: (b: boolean) => void
	setIsConnetedToAgent: (b: boolean) => void
	lsAgentChatDeactivate: () => void
}
const DisconnectBtn = ({
	store,
	hideUploadButton,
	setHideUploadButton,
	setIsConnetedToAgent,
	lsAgentChatDeactivate,
}: DisconnectBtnProps) => {
	const { t } = useTranslation()
	const { liveChatIcon, hideDisconnect, setHideDisconnect } = useWidgetUiContext()

	const handleDisconnect = useCallback(() => {
		if (store) {
			store.dispatch({
				type: 'WEB_CHAT/SEND_MESSAGE',
				payload: { text: t('Disconnect From Agent') },
			})
		}
		setHideDisconnect(true)
		setHideUploadButton(true)
		setIsConnetedToAgent(false)
		lsAgentChatDeactivate()
	}, [hideDisconnect, setHideDisconnect])

	return (
		<span className="restart-icon live-chat" onClick={handleDisconnect}>
			<img src={liveChatIcon}></img>
			<span className="notify connect" title={t(`You are connected to Agent`) as string}>
				<span className="point"></span>
				<span className="beats"></span>
			</span>
			{/* {hideDisconnect && <span className="notify disconnect" title={t(`You are not connected to Agent`)}>
                  <span className="point">
                  </span>
              </span>
              } */}
		</span>
	)
}
export default DisconnectBtn

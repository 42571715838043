import React,{useEffect,useRef} from 'react'
import { useTranslation } from 'react-i18next'
import { Store } from 'redux'
import { useWidgetUiContext } from '../WidgetUiContext'

interface RestartBtnProps {
	store: Store
	enableShortcut:boolean
}
function RestartBtn({store,enableShortcut}: RestartBtnProps) {
	const { t } = useTranslation()
	const { restartIcon } = useWidgetUiContext()

	const handleRestart = () => {
		if (store) {
			store.dispatch({
				type: 'WEB_CHAT/SEND_MESSAGE',
				payload: { text: t('Restart') },
			})
		}
	}
	const buttonRef = useRef<HTMLButtonElement>(null);
	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			let findIsClosed:any = document.querySelectorAll('div.close-bot') || null
			let isMac = false;
			if ((navigator as any).userAgentData) {
				isMac = (navigator as any).userAgentData.platform === 'macOS';
			  } else {
				// Fallback to userAgent for older browsers
				isMac = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);
			  }
            // console.log("mac is",isMac)
		  if (event.ctrlKey && event.key.toLowerCase() === 'r' && isMac && (!(findIsClosed && findIsClosed.length))) {
			buttonRef.current?.click();
		  }else  if (event.altKey && event.key.toLowerCase() === 'r' && !isMac && (!(findIsClosed && findIsClosed.length))) {
			buttonRef.current?.click();
		  }
		};
	
		window.addEventListener('keydown', handleKeyDown);
	
		return () => {
		  window.removeEventListener('keydown', handleKeyDown);
		};
	  }, []);

	return (
		<span className="restart-icon" ref={enableShortcut?buttonRef:null}  onClick={handleRestart}>
			<img src={restartIcon} />
		</span>
	)
}
export default RestartBtn

import React, { Suspense, useEffect, useState } from 'react'
import { selectTenant } from 'src/app/identity/actions/tenant.actions'
import { selectTenant as tenantSelector, useAppDispatch, useAppSelector } from 'src/redux/hooks'
import i18nInit from '../../i18n'
import { BaseProps, MobileProps } from '../interfaces'
import WidgetUiProvider from '../components/WidgetUiContext'
import BotComponent from '../components/BotComponent'

interface BotContainerProps {
	tenantId: string
	authToken?: string
	userPayloadToken?: string
	callBack?: (...args: any[]) => void // TODO: flesh out this type definition

	mobileProps?: MobileProps
	baseProps?: BaseProps

	showBotCustom?: unknown
	micrositeName?: string
	micrositeAttr?: any
	lang?: string
	pgContext?: any
	chatResetTime?: number
	learnigKey?: string
	botStudioBot?: boolean
	flowDetails?: any
	flowName?: string
	botClientType?: string
	botType?: string
	userConversationId?: string
	isGuest?: boolean
	isTestBot?: any
	website?: string
	otherInfo?: any
	traceLog?: any
	disableBeginButtonOnRefresh: boolean
	uploadButton: unknown
	gaCodeId: string
	classNames?: string
	metadata?: any
	isVirtualagent?: any
	aiBot?: any
	jwtToken?: any
	searchValue?: any
	inSearch?: any
}

function BotContainer({ tenantId, ...props }: BotContainerProps) {
	const [isValidTenant, setIsValidTenant] = useState(false)
	const [pgContextVal, setPgContextVal] = useState('')
	const tenant = useAppSelector(tenantSelector)

	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(selectTenant(tenantId))
	}, [tenantId])

	const isEmptyObject = (obj) => {
		return Object.entries(obj).length === 0 && obj.constructor === Object;
	}

	useEffect(() => {
		if (!tenant?.loading && !tenant?.failed && tenant.id) {
			// i18nInit(tenant.id, identity?.token)
			i18nInit(tenant.id)
			setIsValidTenant(true)
			setPgContextVal(props.isGuest ? `${tenant.tenantId}_guest` : (props?.isTestBot && !isEmptyObject(props?.isTestBot)) ?  `${tenant.tenantId}_isTestBot` : tenant.tenantId)
		}
	}, [tenant?.id, tenant?.loading, tenant?.failed, props])

	return isValidTenant ? (
		<div>
			<Suspense fallback={<div>Loading...</div>}>
				<WidgetUiProvider lang={props.lang} metadata={props.metadata} aiBot={props.aiBot} >
					<BotComponent {...props} pgContext={pgContextVal} />
				</WidgetUiProvider>
			</Suspense>
		</div>
	) : (
		<div>Initializing tenant...</div>
	)
}

export default BotContainer

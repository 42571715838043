/**
 * may not be needed once moved to entity based
 */
// import { apiUrl } from  '../../../config';
import * as identityServices from './identity.services'

export const changeStatus = async (status, id, apiUrl) => {
	const apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	const requestOptions = {
		url: `${apiUrlCustom}/botmgmt-service/globalsettings/getGlobalSettingsChangeStatus/${status}/${id}`,
		method: 'GET',
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}

		return response.data
	})
}

export const getAllGlobalSettings = async (customerTenantID, apiUrl) => {
	const apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	let usecase
	if (window.location.pathname.split('/')[2] === 'morsel') {
		usecase = 'morsel'
	}
	const requestOptions = {
		url: `${apiUrlCustom}/botmgmt-service/globalSetting?ts=${new Date().getTime()}`,
		method: 'GET',
		headers: { usecase },
	}
	identityServices.identityApi.interceptors.request.use(config => {
		config.headers.common['x-tenantId'] = customerTenantID
		return config
	})

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const updateGlobalSetting = async (settingData, tenantId, apiUrl) => {
	const apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	const tenantConfig = {
		configType: 'uiFeature',
		tenantId,
		properties: settingData.productSetting.streams,
		name: 'streams',
		status: 'Published',
		appModule: 'common',
	}

	const requestOptions = {
		url: `${apiUrl}/tenantConfig/publish`,
		method: 'POST',
		data: tenantConfig,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status >= 200 && response.status < 207) {
			return response.data
		}
		return Promise.reject(response)
	})
}

export const getSettingByCustomer = async (serviceName, apiUrl) => {
	const apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	const requestOptions = {
		url: `${apiUrlCustom}/settings/getSettingByCustomerName/${serviceName}`,
		method: 'GET',
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}

		return response.data
	})
}

export const getSsoData = async (tenanId, apiUrl) => {
	if (tenanId === undefined || tenanId == null) Promise.reject('`Error : Missing Tenant Id ')
	const apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrlCustom}/sso-management/idp`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(`Unable to fetch SSO Configurations : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const getTenantConfig = async (configType, customerTenantID, apiUrl) => {
	const apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	let usecase
	if (window.location.pathname.split('/')[2] === 'morsel') {
		usecase = 'morsel'
	}
	const requestOptions = {
		url: `${apiUrlCustom}/TenantConfig${configType ? `/${configType}/${customerTenantID}` : ''}`,
		method: 'GET',
		headers: { usecase },
	}
	identityServices.identityApi.interceptors.request.use(config => {
		config.headers.common['x-tenantId'] = customerTenantID
		return config
	})

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const getAllTenantConfig = async (tenantId, apiUrl) => {
	const apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	let usecase
	if (window.location.pathname.split('/')[2] === 'morsel') {
		usecase = 'morsel'
	}
	const requestOptions = {
		headers: { Pragma: 'no-cache', 'Content-Type': 'application/json', usecase },
		url: `${apiUrlCustom}/tenantConfig`,
		method: 'GET',
	}
	// identityServices.identityApi.interceptors.request.use(config => {
	//     config.headers.common['x-tenantId'] = tenantId;
	//     return config
	// })

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

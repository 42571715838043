// require('dotenv').config()
// export const hostUrl = process.env.REACT_APP_UI_URL
// export const idpUrl = process.env.REACT_APP_IDP_URL
// export const defaultSubdomain = process.env.REACT_APP_DEFAULT_SUBDOMAIN
// export const rootDomain = process.env.REACT_APP_ROOT_DOMAIN
// export const apiHostUrl = process.env.REACT_APP_API_HOST
// export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
// export const apiMainUrl = process.env.REACT_APP_API_MAIN
// export const apiUrl = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_BASE_URL}`
// export const botId = process.env.REACT_APP_BOT_ID
// export const publicPageUsers = process.env.REACT_APP_PUBLICPAGE_USERS || 'patelco,nexera,patelconewtest,ms';
// export const socketUrl = process.env.REACT_APP_API_MAIN
// export const directlineSecret = process.env.REACT_APP_BOT_DIRECTLINE;
// export const botName = process.env.REACT_APP_BOT_NAME||"";
// export const conversationTimeout = process.env.REACT_APP_BOT_CONVERSATION_TIMEOUT || 10;
export const i18nSaving = process.env.REACT_APP_i18nSaving
export const headerParams = process.env.REACT_APP_API_HEADER_PARAMS || null

interface HeaderParam {
	name: string
	value: string
}
export const HEADER_PARAMS = (headerParams ? JSON.parse(headerParams) : []) as ReadonlyArray<HeaderParam>

const hn = process.env.REACT_APP_GLOBALTenant_SERVICE_URL
const globalTSUrl = addEnv1(hn, 'apps.actionable-science.com')
const cdn = process.env.REACT_APP_BOT_CDN || process.env.REACT_APP_CDN_URI
const cdnU = addEnv1(cdn)

function addEnv1(str, rootDomain) {
	if (!str) return
	if (str && !str.includes('://')) str = `https://${str}`
	const str1 = `https://${str.split('://')[1].split('/')[0]}`
	const path = str.split('://')[1].split('/')[1] ? `/${str.split('://')[1].split('/').slice(1).join('/')}` : ''
	let retStr = str
	if (str1 && !str1.includes('localhost')) {
		;['.dev.', '.test.', '.staging.', '.alpha.', '.beta.'].some(e => {
			if (window.location.hostname.includes(e) && !str1.includes(e.slice(1))) {
				if (!str1.includes('.')) {
					// append entire root here.
					retStr = `${str1}-${e.slice(1)}${rootDomain}${path || ''}`
				} else {
					// insert e after prefix domain...
					const x = str1.split('.')
					x.splice(1, 0, e.slice(1, -1))
					retStr = x.join('.') + (path || '')
					if (retStr.includes('apigateway')) retStr = retStr.replace(e, `-${e.slice(1)}`)
					if (retStr.includes('apigateway') && !retStr.includes('apps'))
						retStr = retStr.replace('.actionable', '.apps.actionable')
				}
				if (e.includes('staging') && !retStr.includes('cdn')) {
					retStr = retStr.replace('staging', 'uat')
					retStr = retStr.replace('uat-uat', 'uat')
				}
				return retStr
			}
		})
		return retStr
	}
	return str
}

export const globalTenantServiceUrl =
	(globalTSUrl || process.env.REACT_APP_API_HOST) +
	(hn && !hn.includes('/') ? process.env.REACT_APP_API_BASE_URL || '/api/v1' : '')

function addEnv(str) {
	let retStr = str
	if (str && !str.includes('localhost')) {
		;['.dev.', '.test.', '.staging.'].forEach(e => {
			if (window.location.hostname.includes(e) && !str.includes(e)) {
				const rootDomain = window.location.hostname.split(e)[1]
				let newStr
				if (str.includes('://')) newStr = str.split('://')[1].split('/')[0]
				else newStr = str.split('/')[0]
				retStr = str.replace(newStr, newStr + e + rootDomain)
			}
		})
		return retStr
	}
	return retStr
}
const env =
	(window.location.hostname.includes('dev') ||
		window.location.hostname.includes('test') ||
		window.location.hostname.includes('staging')) &&
	window.location.hostname.split('.')[1]

export const rootDomains =
	process.env.REACT_APP_ROOT_DOMAIN || env ? `${env}.virtualpeople.ai,${env}.rezolve.ai` : `virtualpeople.ai,rezolve.ai`

export function interpretBool(v: boolean | string | undefined | null) {
	if (v === null) return false

	switch (typeof v) {
		case 'boolean':
			return v
		case 'string': {
			const vLo = v.toLowerCase().trim()
			if (vLo === 'true' || vLo === '1' || vLo === 'yes') {
				return true
			}
			if (vLo === 'false' || vLo === '0' || vLo === 'no') {
				return false
			}
			throw new Error(`Unable to interpret string "${v}" to bool`)
		}
		case 'undefined':
			return false
		default:
			throw new Error(`Unimplemented for input type ${typeof v}`)
	}
}

export function isEmpty(obj: Record<any, any>): boolean {
	return Object.keys(obj).length === 0
}

// const MINUTE_MILLIS = 1000 * 60
// const HOUR_MILLIS = 1000 * 60 * 60
// const DAY_MILLIS = 1000 * 60 * 60 * 24
// export function getRelativeTime(dt: Date) {
// 	const rtf = new Intl.RelativeTimeFormat('en', {
// 		numeric: 'auto',
// 	})
// 	const millis = dt.getTime() - new Date().getTime()
// 	const days = Math.round(millis / DAY_MILLIS)
// 	if (Math.abs(days) >= 1) {
// 		return rtf.format(days, 'day')
// 	}

// 	const hours = Math.round(millis / HOUR_MILLIS)
// 	if (Math.abs(hours) >= 1) {
// 		return rtf.format(hours, 'hour')
// 	}

// 	const minutes = Math.round(millis / MINUTE_MILLIS)
// 	if (Math.abs(minutes) >= 1) {
// 		return rtf.format(minutes, 'minute')
// 	}

// 	return rtf.format(Math.round(millis / 1000), 'second')
// }

import axios from 'axios'
import type Keycloak from 'keycloak-js'
import type { AppDispatch } from 'src/redux/store'
import { interpretBool } from 'src/utils'
import { HEADER_PARAMS, globalTenantServiceUrl } from '../../../config'
import type { IdentityState } from '../reducers/identity.reducers'
import type { TenantState } from '../reducers/tenant.reducers'
import { getQueryParameters, identityApi } from './identityApi'
import { kcEnsureAuthentication, kcIdentityService, setBearerTokenByInterceptor } from './keycloak.service'
import { msEnsureAuthentication, msIdentityService } from './microsoft.service'

export { getQueryParameters, identityApi } from './identityApi'
// import { idpUrl, apiUrl } from '../../../config';

// TODO: shouldn't this live in keycloak.service.ts?
export const keycloakInstances: Record<string, Keycloak> = {}

export const initiateIdentityService = (tenant: TenantState, dispatch: AppDispatch, props: any) => {
	const queryParameters = getQueryParameters()
	// debugger
	console.log('Query params from id service', queryParameters)
	if (interpretBool(queryParameters.isTeams)) {
		console.log('Inside insure athantication condition', queryParameters)
		// NOTE (from Tai): doesn't look like "handlerParent" exists. Remnant of old code??
		// window.addEventListener('message', identityServices.handlerParent)
		//

		// TODO: bug? msToken and its stored version could be out of sync here if the stored value exist
		// but is different from queryParameters.token
		let msToken = sessionStorage.getItem('ms_token')
		if (!msToken) {
			sessionStorage.setItem('ms_token', queryParameters.token)
		}
		msToken = queryParameters.token

		if (msToken) {
			msIdentityService(tenant, msToken, queryParameters.appName, dispatch, props)
		}
	} else {
		kcIdentityService(tenant)
	}
	// end
	// if (!keycloakInstances[tenant.tenantId]) {
	//     keycloakInstances[tenant.tenantId] = new Keycloak({
	//         url: idpUrl,
	//         realm: tenant.tenantId,
	//         clientId: 'adminui-service'
	//     });
	// }
}

// export const ensureAuthentication = async (props) => {
//     let { dispatch, identity, tenant } = props
//      console.log("this is props to check identity service",identity);
//      console.log("////////////////////",identity)
//      const keycloak = keycloakInstances[tenant.tenantId];
//     // let localValue = localStorage.getItem("persist:root")
//     // if(localValue) {
//     //     identity = JSON.parse(JSON.parse(localValue).identity);
//     // }
//     // console.log("identity ticketing",identity)
//     //const token = sessionStorage.getItem('keycloak_token');
//     //const refreshToken = sessionStorage.getItem('keycloak_refreshToken');
//     const isExpired = identity && identity.exp ? new Date((identity.exp + identity.timeSkew) * 1000) < new Date() : true
//     if (identity.token && identity.isAuthenticated && !isExpired) {
//         //console.log("Already Logged into Actionable Science")
//         // let token = sessionStorage.getItem("keycloak_token");
//         // let tokenRefresh = sessionStorage.getItem("keycloak_refreshToken");
//         !identity.isAuthenticated && keycloakInstances[tenant.tenantId].init({ adapter: 'default', token:identity.token, checkLoginIframe: false })
//         dispatch(identityActions.loginCheckSuccess())
//         await setInterceptor(identity.token, tenant.id)
//         setTimeout(()=>dispatch(identityActions.setIdentity(keycloakInstances[tenant.tenantId])), 1000);

//     } else {
//         console.log("SESSION EXPIRED : ", isExpired)
//         // dispatch(identityActions.clearIdentity())
//         dispatch(identityActions.initiateLogin());
//         keycloakInstances[tenant.tenantId]
//             .init({
//                 onLoad: 'login-required',
//                 flow: 'standard',
//                 checkLoginIframe: false
//             })
//             .success(async authenticated => {
//                 try {
//                     console.log("login authenticated+++++++++++++++",authenticated)
//                     dispatch(identityActions.loginSuccess(keycloakInstances[tenant.tenantId].token))
//                     await setInterceptor(keycloakInstances[tenant.tenantId].token, tenant.id)
//                     setTimeout(()=>dispatch(identityActions.setIdentity(keycloakInstances[tenant.tenantId])), 1000);
//                     updateSession(tenant)
//                 } catch(err) {
//                     console.log("++++++++++++++error in sucess",err)
//                 }

//             })
//             .error((err) => {
//                 console.log("login error+++++++++++++++",err)
//                 dispatch(identityActions.loginFailure())
//                  }
//                 )

//     }
// }
interface EnsureAuthenticationArgs {
	dispatch: AppDispatch
	identity: IdentityState
	tenant: TenantState
	props: any
}
export const ensureAuthentication = async ({ dispatch, identity, tenant, props }: EnsureAuthenticationArgs) => {
	const queryParameters = getQueryParameters()
	if (queryParameters && interpretBool(queryParameters.isTeams)) {
		// NOTE (from Tai): doesn't look like "handlerParent" exists. Remnant of old code??
		// window.addEventListener('message', identityServices.handlerParent)
		//
		await msEnsureAuthentication({ dispatch, identity, tenant, props })
	} else {
		console.log('keycloakService ensureAuthentication ')
		await kcEnsureAuthentication({ dispatch, identity, tenant, props })
	}

	// const keycloak = keycloakInstances[tenant.tenantId];

	// const isExpired = identity && identity.exp ? new Date((identity.exp + identity.timeSkew) * 1000) < new Date() : true
	// if (identity.token && identity.isAuthenticated && !isExpired) {

	//     // Async init but does some magic stuff that in some scenarios prevents
	//     // URL fragments from being left in the address bar
	//     keycloak.init({ adapter: 'default', token: identity.token, checkLoginIframe: false });

	//     dispatch(identityActions.loginCheckSuccess())

	//     // See not on setInterceptor for why we call it multiple times
	//     setInterceptor({
	//         token: identity.token, tenantUid: tenant.id, dispatch,
	//         exp: identity.exp, timeSkew: identity.timeSkew,
	//         realmName: tenant.tenantId
	//     });

	// } else {
	//     console.log("SESSION EXPIRED: ", isExpired);
	//     try {

	//         dispatch(identityActions.initiateLogin());
	//         let authenticated = await keycloak.init({ onLoad: 'login-required', flow: 'standard', checkLoginIframe: false })
	//         if (authenticated) {
	//             dispatch(identityActions.loginSuccess(keycloak.token))

	//             // See not on setInterceptor for why we call it multiple times
	//             setInterceptor({
	//                 token: keycloak.token, tenantUid: tenant.id, dispatch,
	//                 exp: keycloak.tokenParsed.exp, timeSkew: keycloak.timeSkew,
	//                 realmName: tenant.tenantId
	//             });
	//             setTimeout(() => dispatch(identityActions.setIdentity(keycloak)), 1000);
	//         }
	//     } catch (err) { dispatch(identityActions.loginFailure()) }

	// }
}

export const logoutService = (tenant: TenantState) => {
	// debugger
	const redirectURL = `${window.location.protocol}//${window.location.host || window.location.hostname}/logout`
	try {
		if (keycloakInstances[tenant.tenantId]) {
			const keycloak = keycloakInstances[tenant.tenantId]
			// keycloak.logout({ redirectUri: redirectURL });
			delete keycloakInstances[tenant.tenantId]
			sessionStorage.clear()
			localStorage.clear()

			window.location.href = keycloak ? keycloak.createLogoutUrl({ redirectUri: redirectURL }) : redirectURL
		}
	} catch (err) {
		sessionStorage.clear()
		localStorage.clear()
		window.location.href = redirectURL
	}
}

// const setInterceptor = async(token, tenantUid) => {

//     return identityApi.interceptors.request.use(config => {
//         config.headers.common['Authorization'] = 'Bearer ' + token;
//         config.headers.common['Content-Type'] = 'application/json'
//         config.headers.common['x-tenantId'] = tenantUid;
//         return config
//     })
// }
export const reInitIdentityService = (tenant: TenantState, identity: IdentityState, dispatch: AppDispatch, props: any) => {
	const queryParameters = getQueryParameters()
	const token = identity[`token_${tenant.tenantId}`]
	console.log('reInit: query params', queryParameters)

	initiateIdentityService(tenant, dispatch)

	if (!interpretBool(queryParameters.isTeams)) {
		const kc = keycloakInstances[tenant.tenantId]
		if (props.botStudioBot) {
			kc.init({ adapter: 'default', token: identity.token as string, checkLoginIframe: false })
		} else if (!window?.keycloakInstances) {			
			kc.init({ adapter: 'default', token: token as string, checkLoginIframe: false })
		}

		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		setBearerTokenByInterceptor({
			token: props.botStudioBot ? identity.token as string : token as string,
			tenantUid: tenant.id,
			dispatch,
			exp: props.botStudioBot ? identity.exp as number : identity[tenant.tenantId].exp as number,
			timeSkew: props.botStudioBot ? identity.timeSkew as number : identity[tenant.tenantId].timeSkew,
			realmName: tenant.tenantId,
		})
	}
}

// console.log("I WAS HIT !!! 1 ",identityApi.interceptors.request.use(config=> console.log(config.headers)))

// export const saveTenantId = (tenant_Id) => {
//     let ep = apiUrl
//     if (tenant_Id) ep = ep + '/' + tenant_Id
//     let platform = 'Web';
//     if (navigator.userAgent.match(/Android/i))
//         platform = 'Android';
//     if (navigator.userAgent.match(/iPhone|iPad|iPod/i))
//         platform = 'iOS';
//     fetch('https://api.ipify.org/?format=json')
//         .then(response => response.json())
//         .then(response => {
//             const requestOptions = {
//                 method: 'POST',
//                 data: { ip: response.ip, platform: platform },
//                 headers: { 'Content-Type': 'application/json' },
//                 url: `${ep}/botdef/saveTenantId`
//             };
//             return identityApi.request(requestOptions)
//                 .then(response => {
//                     if (response.status !== 200) {
//                         return Promise.reject(response);
//                     }

//                     return response.data;
//                 })
//         })
//         .catch(err => {
//             console.log(err);
//         })
// }

export const getUserRoleById = (tenantId: string, userId: string, apiUrl: string) => {
	const apiUrlCustom = apiUrl || (sessionStorage.getItem('apiUrl') as string)
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrlCustom}/role/user-roles/${userId}?tenantId=${tenantId}`,
	}

	return identityApi.request<unknown>(requestOptions).then(response => {
		if (response.status !== 200 && response.status !== 304) {
			return Promise.reject(new Error(`Unable to fetch roles : Error code : ${response.status}`))
		}

		return response.data
	})
}

export const getRolesByTenant = (tenantId: string, apiUrl: string) => {
	const apiUrlCustom = apiUrl || (sessionStorage.getItem('apiUrl') as string)
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		// FIXME: why is ${null} being interpolated into this url?
		url: `${apiUrlCustom}/role/tenant-roles/${null}?tenantId=${tenantId}`,
	}

	return identityApi.request<unknown>(requestOptions).then(response => {
		if (response.status !== 200 && response.status !== 304) {
			return Promise.reject(new Error(`Unable to fetch the list of roles : Error code : ${response.status}`))
		}

		return response.data
	})
}

export const getTenantRoleById = (tenantId: string, roleId: string, apiUrl: string) => {
	const apiUrlCustom = apiUrl || (sessionStorage.getItem('apiUrl') as string)
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrlCustom}/role/tenant-role/${roleId}?tenantId=${tenantId}`,
	}
	return identityApi.request<unknown>(requestOptions).then(response => {
		if (response.status !== 200 && response.status !== 304) {
			return Promise.reject(new Error(`Unable to fetch the list of roles : Error code : ${response.status}`))
		}

		return response.data
	})
}

export const getUserById = async (
	tenantId: string,
	userId: string,
	token: string,
	tenantUid: string,
	apiUrl: string
) => {
	if (!tenantId) throw new Error('Error : Missing Tenant Id')

	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
	try {
		if (!apiUrlCustom && tenantId) {
			const tenantRequestOpts = {
				url: `${globalTenantServiceUrl}/tenant?tenantId=${tenantId}&ts=${new Date().getTime()}`,
				method: 'GET',
				headers: HEADER_PARAMS,
			}

			const req = await axios.request<unknown>(tenantRequestOpts)
			const tenant = req && req.data
			apiUrlCustom = tenant && tenant.apiUrl + (tenant.basePath || '')
		}

		if (!apiUrlCustom) {
			throw new Error(`Api url not known for specified tenant ${tenantUid} ${tenantId}`)
		}
		const identityRequestOpts = {
			method: 'GET',
			url: `${apiUrlCustom}/role/users/${userId}?tenantId=${tenantId}`,
			headers: HEADER_PARAMS,
		}

		return await identityApi.request<unknown>(identityRequestOpts).then(response => {
			if (response.status !== 200 && response.status !== 304) {
				return Promise.reject(new Error(`Unable to fetch the list of users : Error code : ${response.status}`))
			}

			return response.data
		})
	} catch (e) {
		return Promise.reject(e)
	}
}

// const setInterceptor = (tenant) => {
//     console.log("I WAS HIT !!! 1 ")
//     identityApi.interceptors.request.use(config => {
//         console.log("I WAS HIT !!! 2 ",keycloakInstances)
//         keycloakInstances[tenant.tenantId].logout().updateToken(5)
//         .then(refreshed => {

//             if (refreshed) {
//             updateSession(keycloakInstances[tenant.tenantId].logout())
//             }
//             config.headers.Authorization = 'Bearer ' + keycloakInstances[tenant.tenantId].logout().token;
//             config.headers['Content-Type']  = 'application/json'

//             return Promise.resolve(config)
//         })
//         .catch(() => {
//             keycloakInstances[tenant.tenantId].logout().login();
//         })
//     })
// }

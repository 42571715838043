import { combineReducers } from 'redux'
import { botReducers, notificationReducers, whiteLabelReducers } from '../app/bot'
import { identityReducers, settingsReducers, tenantReducers } from '../app/identity'

const rootReducer = combineReducers({
  ...identityReducers,
  ...tenantReducers,
  ...botReducers,
  ...whiteLabelReducers,
  ...settingsReducers,
  ...notificationReducers
})

export default rootReducer
import Keycloak from 'keycloak-js'
// import queryString from 'query-string'
import { HEADER_PARAMS } from 'src/config'
import type { AppDispatch } from 'src/redux/store'
import { identityActions } from '..'
// import { idpUrl } from '../../../config'
import { checkIdentity } from '../actions/identity.actions'
import type { IdentityState } from '../reducers/identity.reducers'
import type { TenantState } from '../reducers/tenant.reducers'
import { getQueryParameters, identityApi, keycloakInstances } from './identity.services'

export const kcIdentityService = (tenant: TenantState) => {
	if (window?.keycloakInstances && window?.keycloakInstances[tenant.tenantId]) {
		keycloakInstances[tenant.tenantId] = window?.keycloakInstances[tenant.tenantId]
	}
	if (!keycloakInstances[tenant.tenantId]) {
		keycloakInstances[tenant.tenantId] = new Keycloak({
			url: tenant.federationServerUrl,
			realm: tenant.tenantId,
			clientId: 'adminui-service', // TODO: find out why this is clientId is adminui-service and not something like 'chatwidget'
		})
	}

	const queryParams = getQueryParameters()

	if (queryParams.idp) {
		let kc: any = keycloakInstances[tenant.tenantId]
		let kcLogin = kc.login
		kc.login = options => {
			options.idpHint = queryParams.idp
			kcLogin(options)
		}

		// TODO: we prob shouldn't do this. Existing calls to kc.login already provides {idpHint: queryParams.idp}
		// If we have to do this, would be cleaner to avoid this override and define a "keycloakInitWithIdpHint" function
		// kc.login = options => kc.login({ ...options, idpHint: queryParams.idp })
	}
}

interface EnsureAuthenticationArgs {
	dispatch: AppDispatch
	identity: IdentityState
	tenant: TenantState
	props: any
}
export const kcEnsureAuthentication = async ({ dispatch, identity, tenant, props }: EnsureAuthenticationArgs) => {
	const keycloak = keycloakInstances[tenant.tenantId]
	const token = identity[`token_${tenant.tenantId}`]

	const isExpired = props.botStudioBot ? identity && identity.exp ? new Date((identity.exp + identity.timeSkew) * 1000) < new Date() : true : (identity && identity[tenant.tenantId] && identity[tenant.tenantId].exp) ? new Date((identity[tenant.tenantId].exp + identity[tenant.tenantId].timeSkew) * 1000) < new Date() : true

	if (((props.botStudioBot && identity.token && identity.isAuthenticated) || (token && identity[tenant.tenantId] && identity[tenant.tenantId].isAuthenticated)) && !isExpired) {
		// Async init but does some magic stuff that in some scenarios prevents
		// URL fragments from being left in the address bar
		keycloak.init({ adapter: 'default', token: props.botStudioBot ? identity.token : token, checkLoginIframe: false }) // TODO: deliberate no await?

		dispatch(identityActions.loginCheckSuccess(tenant, props))

		if (props.botStudioBot) {
			// See not on setInterceptor for why we call it multiple times
			setBearerTokenByInterceptor({
				token: identity.token,
				tenantUid: tenant.id,
				dispatch,
				exp: identity.exp,
				timeSkew: identity.timeSkew,
				realmName: tenant.tenantId,
			})
		} else {
			// See not on setInterceptor for why we call it multiple times
			setBearerTokenByInterceptor({
				token: token,
				tenantUid: tenant.id,
				dispatch,
				exp: identity[tenant.tenantId].exp,
				timeSkew: identity[tenant.tenantId].timeSkew,
				realmName: tenant.tenantId,
			})
		}
	} else {
		console.log('SESSION EXPIRED: ', isExpired, 'reinitiating...')
		try {
			let authenticated = (window?.keycloakInstances && window?.keycloakInstances[tenant.tenantId]) ? true : false
			if (!authenticated) {
				dispatch(identityActions.initiateLogin(tenant))
				authenticated = await keycloak.init({ onLoad: 'login-required', flow: 'standard', checkLoginIframe: false })
			}

			if (authenticated) {
				if (keycloak.token) {
					const queryParams = getQueryParameters()
					// const { idp: idpHint } = queryParams
					console.log(`Authenticated: with idp ${queryParams.idp}`)
					dispatch(identityActions.loginSuccess(keycloak.token, tenant, props))
					// See not on setInterceptor for why we call it multiple times
					setBearerTokenByInterceptor({
						token: keycloak.token,
						tenantUid: tenant.id,
						dispatch,
						exp: keycloak?.tokenParsed?.exp,
						timeSkew: keycloak.timeSkew,
						realmName: tenant.tenantId,
					})

					// TODO: why setTimeout? Is this only to _defer?
					setTimeout(() => dispatch(identityActions.setIdentity(props)), 1000)
					// eslint-disable-next-line @typescript-eslint/no-use-before-define
					kcUpdateSession(tenant)
				} else {
					const queryParams = getQueryParameters()

					console.log(`relogged in with idp: ${queryParams.idp}`)
					keycloak.login({ idpHint: queryParams.idp })
				}
			} else {
				const queryParams = getQueryParameters()

				console.log(`relogged in when authentication failed , with idp: ${queryParams.idp}`)
				keycloak.login({ idpHint: queryParams.idp })
			}
		} catch (err) {
			const queryParams = getQueryParameters()

			console.log(`in catch: idp ${queryParams.idp}`)
			dispatch(identityActions.loginFailure(tenant, props))
		}
	}

	// //const token = sessionStorage.getItem('keycloak_token');
	// //const refreshToken = sessionStorage.getItem('keycloak_refreshToken');
	// const isExpired = identity && identity.exp ? new Date((identity.exp + identity.timeSkew) * 1000) < new Date() : true
	// if (identity.token && identity.isAuthenticated && !isExpired) {
	//     //console.log("Already Logged into Actionable Science")
	//     // let token = sessionStorage.getItem("keycloak_token");
	//     // let tokenRefresh = sessionStorage.getItem("keycloak_refreshToken");
	//     //!identity.isAuthenticated &&
	//     keycloakInstances[tenant.tenantId].init({ adapter: 'default', token: identity.token })
	//     dispatch(identityActions.loginCheckSuccess())
	//     await setInterceptor(identity.token, tenant.id);
	//     // setTimeout(()=>dispatch(identityActions.setIdentity(keycloakInstances[tenant.tenantId])), 1000);

	// } else {
	//     console.log("SESSION EXPIRED : ", isExpired)
	//     // dispatch(identityActions.clearIdentity())
	//     dispatch(identityActions.initiateLogin());
	//     keycloakInstances[tenant.tenantId]
	//         .init({
	//             onLoad: 'login-required',
	//             flow: 'standard',
	//         })
	//         .success(async authenticated => {
	//             dispatch(identityActions.loginSuccess(keycloakInstances[tenant.tenantId].token))
	//             await setInterceptor(keycloakInstances[tenant.tenantId].token, tenant.id)
	//             setTimeout(() => dispatch(identityActions.setIdentity()), 1000);
	//             updateSession(tenant)
	//             //if (!singleTenant) saveTenantId()
	//         })
	//         .error(err =>
	//             dispatch(identityActions.loginFailure()))

	// }
}

let interceptorId: number | null = null
interface SetInterceptorAgs {
	token: string
	tenantUid: string
	realmName: string
	dispatch: AppDispatch
	exp: number
	timeSkew: number
}
export function setBearerTokenByInterceptor({
	token,
	tenantUid,
	dispatch,
	exp,
	timeSkew,
	realmName,
}: SetInterceptorAgs) {
	if (interceptorId) {
		identityApi.interceptors.request.eject(interceptorId)
	}

	interceptorId = identityApi.interceptors.request.use(
		async config => {
			const isExpired = exp ? new Date((exp + (timeSkew || 0)) * 1000) < new Date() : true
			if (isExpired) {
				const refreshed = await keycloakInstances[realmName].updateToken(5)
				console.log('Refreshed', refreshed)
				token = keycloakInstances[realmName].token
			}

			config.headers.common = {
				...((config?.headers?.common ?? {}) as object),
				Authorization: `Bearer ${token}`,
				'x-tenantId': tenantUid,
				'Content-Type': 'application/json',
				...HEADER_PARAMS,
			}

			return config
		},
		error => {
			console.log(`Error in update Token ${JSON.stringify(error)}`)
			// dispatch(alertActions.error("Session has Expired. Please login again."));
			dispatch(checkIdentity())
			return Promise.reject(error)
		}
	)
}

function kcUpdateSession(tenant: TenantState) {
	sessionStorage.setItem('keycloak_token', keycloakInstances[tenant.tenantId].token as string)
	sessionStorage.setItem('keycloak_refreshToken', keycloakInstances[tenant.tenantId].refreshToken as string)
}
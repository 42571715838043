import { faBell } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { selectIdentity, selectNotificationCount, selectTenant, useAppSelector } from 'src/redux/hooks'

function NotifBadge({ botStudioBot }) {
	const tenant = useAppSelector(selectTenant)
	const identity = useAppSelector(selectIdentity)
	const notificationCount = useAppSelector(selectNotificationCount)

	const handleOpenNoti = () => {
		const email = botStudioBot ? identity && identity.profile && identity.profile.email : identity && identity[tenant.tenantId] && identity[tenant.tenantId].profile && identity[tenant.tenantId].profile.email
		window.open(
			`/virtualagent/checknotification?tenantuid=${tenant.id}&email=${email}`,
			'_blank' // <- This is what makes it open in a new window.
		)
	}

	return (
		<span
			className="restart-icon btn-notification"
			onClick={() => {
				handleOpenNoti()
			}}>
			<FontAwesomeIcon className="btn-faBell-icon" color="white" icon={faBell} />
			{notificationCount && notificationCount > 0 ? <span className="count">{notificationCount}</span> : <></>}
		</span>
	)
}
export default NotifBadge

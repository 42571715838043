import type { AnyAction } from '@reduxjs/toolkit'
import { createStore } from 'botframework-webchat'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import simpleUpdateIn from 'simple-update-in'
import { selectTenant, useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { getBotData } from '../../actions/bot.actions'
import { ChatInfo } from '../../interfaces'
import type { BotActivity } from '../../reducers/bot.reducers'
import { useWidgetUiContext } from '../WidgetUiContext'
import { scrollChatToBottom } from '../utils'

interface UseComplexCreateStoreArgs {
	activities: BotActivity[]
	setHideUploadButton: React.Dispatch<React.SetStateAction<boolean>>
	lsAgentChatActivate: () => void
	lsAgentChatDeactivate: () => void
	botId: string
	botStudioBot: any
	learnigKey: string
	user: any
	conversationId: string
	chatData: string
	pgCtx?: string
	isEnableLastAC: boolean
	setIsEnableLastAC: React.Dispatch<React.SetStateAction<boolean>>
	setIsConnetedToAgent: React.Dispatch<React.SetStateAction<boolean>>
	iframeScrollMeta: any
}
export default function useComplexCreateStore({
	activities,
	setHideUploadButton,
	lsAgentChatActivate,
	lsAgentChatDeactivate,
	botId,
	botStudioBot,
	learnigKey,
	user,
	chatData,
	pgCtx,
	isEnableLastAC,
	setIsEnableLastAC,
	setIsConnetedToAgent,
	iframeScrollMeta,
	// TODO: use this for the refactoring bc it looks like props.conversationId and MinimizeWidget's conversationId
	// state may be out of sync. Pass the props.conversationId in to keep the same behavior for the refactoring.
	// Revisit this later to either unify them or change to disctinctive variable names
	...props
}: UseComplexCreateStoreArgs) {
	const { setHideDisconnect, setDisableSendBox, setConversationId, ls } = useWidgetUiContext()
	const tenant = useAppSelector(selectTenant)
	const { t } = useTranslation()
	const appDispatch = useAppDispatch()

	const chatInit = ({ dispatch }) => {
		if (user?.hasOwnProperty('agentCheck')) {
			dispatch({
				type: 'WEB_CHAT/SEND_EVENT',
				payload: {
					type: 'event',
					value: 'agentChatInit',
					from: { id: 'Agent' },
					name: 'agentChatInit',
				},
			})
		} else {
			const chatValues = chatData || 'customerChatInit'
			dispatch({
				type: 'WEB_CHAT/SEND_EVENT',
				payload: {
					type: 'event',
					value: chatValues,
					from: { id: 'Customer' },
					name: 'customerChatInit',
				},
			})
		}

		//  dispatch({
		//     type: 'DIRECT_LINE/POST_ACTIVITY',
		//     channelData: user
		//   });
	}

	const onRefreshDisableLastAtt = useCallback(() => {
		const chatInfo = ls.get(pgCtx || 'chatInfo') as ChatInfo
		if (chatInfo) {
			chatInfo.isEnableLastAC = false
			ls.set(pgCtx || 'chatInfo', chatInfo)
		}
		setIsEnableLastAC(false)
	}, [setIsEnableLastAC, ls, pgCtx])

	const onRefreshEnableLastAtt = useCallback(() => {
		const chatInfo = ls.get(pgCtx || 'chatInfo') as ChatInfo
		if (chatInfo) {
			chatInfo.isEnableLastAC = true
			ls.set(pgCtx || 'chatInfo', chatInfo)
			if (!isEnableLastAC) {
				setIsEnableLastAC(true)
			}
		}
	}, [isEnableLastAC, setIsEnableLastAC, ls, pgCtx])

	return () =>
		createStore({ activities: activities || [] }, ({ dispatch, getState }) => next => (action: AnyAction) => {
			if (action?.payload?.activity?.attachments) {
				// workaround for a bot bug where attachment array contains null elements.
				// This null value crashes the underlying botframework-webchat redux handling.
				action.payload.activity.attachments = action.payload.activity.attachments.filter(
					item => item !== null && item !== undefined,
				)
			}

			// eslint-disable-next-line @typescript-eslint/no-shadow
			const { activities } = getState() as { activities: BotActivity[] }
			const { text, from, attachments } = (action.payload && action.payload.activity) || {}
			const role = from && from.role
			const innerText = attachments && attachments[0] && attachments[0].content && attachments[0].content.text
			let cerText = innerText || text
			// action.payload && action.payload.activity && console.log(`cerText: ${cerText} text: ${text} activity ${JSON.stringify(action.payload.activity)}`)
			if (
				cerText &&
				(cerText.indexOf(t('ending this conversation')) !== -1 ||
					cerText.indexOf(t('It has been sometime since we')) !== -1) &&
				['bot', 'agent'].includes(role)
			) {
				// for servicenow txt
				setHideUploadButton(true)
				setHideDisconnect(true)
				lsAgentChatDeactivate()
			}
			// console.log('I am calling ', action.type)
			// console.log(
			// 	'payload ',
			// 	action.payload,
			// 	action.payload && action.payload.activity,
			// 	action.payload && action.payload.activity && action.payload.activity.attachments,
			// 	action.payload && action.payload.activity && action.payload.activity.attachments && action.payload.activity.attachments[0]
			// )
			if (action.type === 'DIRECT_LINE/CONNECT_REJECTED') {
				if (action.error) {
					const botItem = {
						botId,
						tenantId: tenant.id,
						conversationId: null,
						learnigKey: learnigKey || null,
						token: null,
					}
					if (botStudioBot) botItem.botStudioBot = botStudioBot
					ls.remove(tenant.tenantId || 'chatInfo')
					setTimeout(() => appDispatch(getBotData(botItem, tenant.apiUrl)), 1000)
				}
			}
			if (action.type === 'WEB_CHAT/SEND_POST_BACK') {
				if (!iframeScrollMeta) {
					setTimeout(scrollChatToBottom, 2200)
				}
				let activityWithOptions
				let hideSendBoxLength
				let lastIndex = -1
				activities &&
					activities.forEach((a, i) => {
						if (a.value && a.value.hideSendBox) lastIndex = i
					})
				if (lastIndex > -1) {
					activityWithOptions = activities[lastIndex]
					hideSendBoxLength = activityWithOptions.value.hideSendBox
				}
				// activityWithOptions &&
				// 	console.log(
				// 		`web post back lastActivity  is ${JSON.stringify(activityWithOptions)} len:${activities.length} last:${lastIndex} hLen:${hideSendBoxLength}`
				// )

				if (hideSendBoxLength && activities.length < lastIndex + hideSendBoxLength) {
					setDisableSendBox(true)
				}
			}
			if (action.type === 'DIRECT_LINE/POST_ACTIVITY') {
				action = simpleUpdateIn(action, ['payload', 'activity', 'channelData'], () => user)
				let activityWithOptions
				let hideSendBoxLength
				let lastIndex = -1
				activities &&
					activities.forEach((a, i) => {
						if (a.value && a.value.hideSendBox) lastIndex = i
					})
				if (lastIndex > -1) {
					activityWithOptions = activities[lastIndex]
					hideSendBoxLength = activityWithOptions.value.hideSendBox
				}
				// activityWithOptions &&
				// 	console.log(` post  lastactivity is ${JSON.stringify(activityWithOptions)} len:${activities.length} last:${lastIndex} hLen:${hideSendBoxLength}`)

				if (hideSendBoxLength && activities.length < lastIndex + hideSendBoxLength) {
					setDisableSendBox(true)
				}
			}
			if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
				// NOTE: comment out because not used
				// const { mobileData } = props
				// const isExpired =
				//	identity && identity.exp ? new Date((identity.exp + identity.timeSkew) * 1000) < new Date() : true
				setConversationId(action.payload && action.payload.directLine && action.payload.directLine.conversationId)
				!props.conversationId && chatInit({ dispatch })
			}
			if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
				const chatInfo = pgCtx ? ls.get(pgCtx) : ls.get(tenant.tenantId || 'chatInfo')
				const {
					text,
					name,
					type,
					attachments,
					value,
					from: { role },
				} = action.payload.activity
				const lastActivity = activities && activities[activities.length - 1]
				if (action.payload && action.payload.activity && action.payload.activity.text) {
					onRefreshDisableLastAtt()
					if (!text) return next(action)

					const lastText = (lastActivity && lastActivity.text && lastActivity.text.toLowerCase()) || ''
					const textLower = (text && text.toLowerCase()) || ''
					if (
						(lastText.includes(t('select')) && lastText.includes(t('file')) && lastText.includes(t('please'))) ||
						(lastText.includes(t('paperclip')) && lastText.includes(t('click')) && lastText.includes(t('please'))) ||
						(textLower.includes(t('select')) && textLower.includes(t('file'))) ||
						(textLower.includes(t('paperclip')) && textLower.includes(t('click')) && textLower.includes(t('please')))
					) {
						setHideUploadButton(false)
					} else if (!(chatInfo && chatInfo.isConnetedToAgent)) {
						// !props.uploadButton && setHideUploadButton(true);
						setHideUploadButton(true)
					} else {
						setHideUploadButton(false)
					}

					if (
						(text.indexOf(t('You are now connected with')) !== -1 || text.indexOf(t('has entered the chat')) !== -1) &&
						role === 'bot'
					) {
						// for servicenow test
						setHideUploadButton(false)
						setHideDisconnect(false)
						lsAgentChatActivate()
					}
					cerText = innerText || text
					// console.log(`cerText: ${cerText} text: ${text} activity ${JSON.stringify(action.payload.activity)}`)
					if (
						(cerText.indexOf(t('ending this conversation')) !== -1 ||
							cerText.indexOf(t('It has been sometime since we')) !== -1) &&
						['bot', 'agent'].includes(role)
					) {
						// for servicenow txt
						setHideUploadButton(true)
						setHideDisconnect(true)
						lsAgentChatDeactivate()
					}
				}

				if (action?.payload?.activity?.attachments) {
					// this.setState({ incomingMessage: true })
					// this.OnRefreshDisableLastAtt()
					const cards = action.payload.activity.attachments.length > 0 && action.payload.activity.attachments[0]
					cards?.content?.body?.forEach(item => {
						if (
							item.text &&
							(item.text.indexOf(t('Your feedback matters')) !== -1 ||
								item.text.indexOf(t('We appreciate your feedback')) !== -1)
						) {
							setHideDisconnect(true)
							setIsConnetedToAgent(false)
							lsAgentChatDeactivate()
						}
					})
					onRefreshEnableLastAtt()
				}

				if (action?.payload?.activity) {
					const contentTypes = [
						'image/png',
						'image/jpeg',
						'application/pdf',
						'application/vnd.ms-excel',
						'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						'application/msword',
						'text/csv',
						'image/gif',
						'text/plain',
						'application/pdf',
					]
					const { value } = action.payload.activity
					const attachment =
						action.payload.activity.attachments &&
						action.payload.activity.attachments.length > 0 &&
						action.payload.activity.attachments[0]
					// console.log(`Logging attachment minimize webchat`, attachment)
					const flag = attachment?.content?.body?.some(bd => (bd.id === 'EnableInputbox' && bd.isVisible === false && bd.type === 'TextBlock'))
					if ((attachment && contentTypes.indexOf(attachment.contentType) !== -1) || (value && value.agent) || flag) {
						setDisableSendBox(false)
					} else if (
						(attachments && role === 'bot') ||
						(type === 'event' && name === 'formPropertyError') ||
						(lastActivity?.name === 'formPropertyError' && lastActivity?.type === 'event')
					) {
						setDisableSendBox(true)
					} else if (text && role === 'bot') {
						setDisableSendBox(false)
					}
					if (
						text &&
						role === 'bot' &&
						(text.indexOf(t('Notifying agents about your request')) !== -1 ||
							text.indexOf(t('Routing you to a live agent')) !== -1)
					) {
						setDisableSendBox(true)
						// setHideSendBox(true)
					}
					let activityWithOptions
					let hideSendBoxLength
					let lastIndex = -1
					activities?.forEach((a, i) => {
						if (a.value && a.value.hideSendBox) lastIndex = i
					})

					if (lastIndex > -1) {
						activityWithOptions = activities[lastIndex]
						hideSendBoxLength = activityWithOptions.value.hideSendBox
					}
					// activityWithOptions &&
					// 	console.log(
					// 		`incoming lastActivity is ${JSON.stringify(activityWithOptions)} len:${activities.length} last:${lastIndex} hLen:${hideSendBoxLength}`
					// 	)

					if (hideSendBoxLength && activities.length < lastIndex + hideSendBoxLength) {
						setDisableSendBox(true)
					}
				}

				if (
					(type === 'event' && name === 'forReconnect') ||
					(text &&
						(text.indexOf(t('Hope that helped')) !== -1 ||
							text.indexOf(t('You are now connected back to bot')) !== -1 ||
							text.indexOf(t('Would you like us to email you the chat')) !== -1 ||
							text.indexOf(t('We appreciate your feedback')) !== -1 ||
							text.indexOf(t('Your feedback matters')) !== -1 ||
							text.indexOf(t('Thanks for contacting')) !== -1))
				) {
					setHideDisconnect(true)
					setHideUploadButton(true)
					lsAgentChatDeactivate()
				}

				if (action?.payload?.activity?.attachments) {
					const signcards = action.payload.activity.attachments.length > 0 && action.payload.activity.attachments[0]
					if (
						signcards?.content?.text?.includes(t('Please sign in')) ||
						signcards?.content?.text?.includes(t('Please signin'))
					) {
						setDisableSendBox(false)
						// setHideSendBox(false);
					}
				}
			}
			return next(action)
		})
}

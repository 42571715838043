export const GET_TENANT_REQUEST = 'GET_TENANT_REQUEST'
export const GET_TENANT_SUCCESS = 'GET_TENANT_SUCCESS'
export const GET_TENANT_FAILURE = 'GET_TENANT_FAILURE'

export const CHECK_TENANT_EXISTS_REQUEST = 'CHECK_TENANT_EXISTS_REQUEST'
export const CHECK_TENANT_EXISTS_SUCCESS = 'CHECK_TENANT_EXISTS_SUCCESS'
export const CHECK_TENANT_EXISTS_FAILURE = 'CHECK_TENANT_EXISTS_FAILURE'

export const REGISTER_TENANT_REQUEST = 'REGISTER_TENANT_REQUEST'
export const REGISTER_TENANT_SUCCESS = 'REGISTER_TENANT_SUCCESS'
export const REGISTER_TENANT_FAILURE = 'REGISTER_TENANT_FAILURE'

export const TENANT_LIFECYCLE_REQUEST = 'TENANT_LIFECYCLE_REQUEST'
export const TENANT_LIFECYCLE_SUCCESS = 'TENANT_LIFECYCLE_SUCCESS'
export const TENANT_LIFECYCLE_FAILURE = 'TENANT_LIFECYCLE_FAILURE'

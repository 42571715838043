import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import rootReducer from './reducers'

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['identity', 'globalSetting', 'namespace', 'whiteLabelEntity'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const devMiddlewares = process.env.NODE_ENV !== 'production' ? [logger] : []
const middlewares = [thunk, ...devMiddlewares]

// export default () => {
//   const store = configureStore({
//     reducer: persistedReducer,
//     middleware: middlewares,
//     enhancers: [],
//     devTools: process.env.NODE_ENV !== 'production'
//   })
//   let persistor = persistStore(store)
//   return { store, persistor }
// }

export const store = configureStore({
	reducer: persistedReducer,
	middleware: middlewares,
	enhancers: [],
	devTools: process.env.NODE_ENV !== 'production',
})
export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppGetState = typeof store.getState

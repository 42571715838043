import * as whiteLabelConstants from '../constants/whiteLabel.constants'

export interface WhiteLabelTheme {
	botName?: string
	botTheme?: string // TODO: is this really used to describe a theme? Its usage seems to be for describing a color only ('#FFF')
	botStyle?: string
	botImage?: string
	agentImage?: string
	showBot?: unknown
	isPowerdBy?: string
	welcomeMessage?: string
	headerHtmlText?: string
	companyLink?: string
	companyName?: string
	gaCodeId?: string // TODO: why is this field stored in theme? It is referenced in MinimizableWebChat::registerJSEvents()
	contantInfo?: string // TODO: what does this mean? It is referenced in MinimizableWebChat rendering <Upload>. Upload names it "customMessage"
}

export interface WhiteLabelEntity {
	publicPrivate: string
	micrositeName: string
	appName: string
	config: WhiteLabelTheme // TODO: verify that "config" is only used for theme and change it to themeConfig
}
export interface WhiteLabelEntityState {
	loading?: boolean
	itemData?: WhiteLabelEntity[]
}
export const whiteLabelEntity = (state: WhiteLabelEntityState = {}, action): WhiteLabelEntityState => {
	switch (action.type) {
		case whiteLabelConstants.GETCONFIG_REQUEST_WHITE_LABELING:
			return {
				...state,
				loading: true,
			}
		case whiteLabelConstants.GETCONFIG_SUCCESS_WHITE_LABELING:
			return {
				...state,
				loading: false,
				itemData: action.item,
			}
		case whiteLabelConstants.GETCONFIG_FAILURE_WHITE_LABELING:
			return {
				...state,
				loading: false,
			}

		default:
			return state
	}
}

// import { alertActions } from '../../common'
import * as botConstant from '../constants/bot.constants'
import * as notificationConstant from '../constants/notification.constants'
import { botService } from '../services/bot.services'

const RESOURCE_NAME = 'Bot'

function getNotificationCountRequest() {
	return { type: notificationConstant.GET_REQUEST_NOTIFICATION }
}

function getNotificationCountSuccess(record) {
	// TODO: use 'payload' instead of 'item' field name
	return { type: notificationConstant.GET_SUCCESS_NOTIFICATION, item: record }
}

function getNotificationCountFailure(error: string) {
	return { type: notificationConstant.GET_FAILURE_NOTIFICATION, error }
}

function getBotRequest() {
	return { type: botConstant.GETCONFIG_REQUEST_BOT }
}

function getBotResetRequest() {
	return { type: botConstant.GETCONFIG_REQUEST_BOT_RESET }
}

function getBotSuccess(record: object) {
	return { type: botConstant.GETCONFIG_SUCCESS_BOT, item: record }
}

function getBotFailure(error: string) {
	return { type: botConstant.GETCONFIG_FAILURE_BOT, error }
}

function getLangSuccess(payload: unknown) {
	return {
		type: botConstant.GET_LANG_SUCCESS,
		item: payload,
	}
}

export function getLang(tenantId: string, apiUrl: string) {
	return async dispatch => {
		dispatch(getBotRequest())
		await botService
			.getLangs(tenantId, apiUrl)
			.then((items: any[]) => {
				if (items) {
					const langs = items.map(el => el.lang)
					dispatch(getLangSuccess(langs))
				} else {
					const errorMsg = 'Cannot fetch languages at this moment please try again later'
					dispatch(getBotFailure(errorMsg))
				}
			})
			.catch(err => {
				const errorMsg = 'Cannot fetch languages at this moment please try again later'
				dispatch(getBotFailure(errorMsg))
			})
	}
}

export function getNotificationCount(tenantId, email, apiUrl) {
	return async dispatch => {
		dispatch(getNotificationCountRequest())
		await botService
			.getNotificationCount(tenantId, email, apiUrl)
			.then(item => {
				if (item) {
					dispatch(getNotificationCountSuccess(item))
				} else {
					const errorMsg = 'Cannot fetch languages at this moment please try again later'
					dispatch(getNotificationCountFailure(errorMsg))
				}
			})
			.catch(err => {
				const errorMsg = 'Cannot fetch languages at this moment please try again later'
				dispatch(getNotificationCountFailure(errorMsg))
			})
	}
}

export function getBotData(item, apiUrl) {
	return async dispatch => {
		dispatch(getBotRequest())

		await botService.getBotData(item, apiUrl).then(
			dataItem => {
				if (dataItem) {
					dispatch(getBotSuccess(dataItem))
					window.parent.postMessage({ type: 'botReady', content: { value: true } }, '*')
				} else {
					const errorMsg = `Cannot fetch ${RESOURCE_NAME} at this moment please try again later`
					dispatch(getBotFailure(errorMsg))
					// dispatch(alertActions.error(errorMsg));
				}
			},
			error => {
				const errorMsg = `Cannot fetch ${RESOURCE_NAME} at this moment please try again later`
				dispatch(getBotFailure(errorMsg))
				// dispatch(alertActions.error(errorMsg));
			}
		)
	}
}

export function getResetBot(item, apiUrl) {
	return async dispatch => {
		dispatch(getBotResetRequest())

		await botService.getBotData(item, apiUrl).then(
			item => {
				if (item) {
					dispatch(getBotSuccess(item))
				} else {
					const errorMsg = `Cannot fetch ${RESOURCE_NAME} at this moment please try again later`
					dispatch(getBotFailure(errorMsg))
					// dispatch(alertActions.error(errorMsg));
				}
			},
			error => {
				const errorMsg = `Cannot fetch ${RESOURCE_NAME} at this moment please try again later`
				dispatch(getBotFailure(errorMsg))
				// dispatch(alertActions.error(errorMsg));
			}
		)
	}
}

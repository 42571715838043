import { createReducer } from '@reduxjs/toolkit'
import {
	checkTenantExistsFailure,
	checkTenantExistsRequest,
	checkTenantExistsSuccess,
	getTenantFailure,
	getTenantLifeCycleFailure,
	getTenantLifeCycleRequest,
	getTenantLifeCycleSuccess,
	getTenantRequest,
	getTenantSuccess,
	registerTenantFailure,
	registerTenantRequest,
	registerTenantSuccess,
} from '../actions/tenant.actions'

// TODO: we should make TenantState a union of UnfetchedTenantState and FetchedTenantState (where id become available)
// that would help resolve a bunch of type errors while maintaining strong type safety
export interface TenantState {
	loading: boolean
	isValidated: boolean
	registrationInitiated: boolean
	isRegistering: boolean
	failed?: boolean
	error?: any
	tenantExists?: boolean
	tenantLifeCycle?: unknown
	registrationFailed?: boolean
	id: string // TODO: we may want to change this to uuid to make disction with "tenantId"
	tenantId: string
	apiUrl?: string
	botId?: string
	userAuthReqd?: boolean
	federationServerUrl?: string
	isTenant?: boolean
}

const initialState: TenantState = {
	loading: true,
	isValidated: false,
	registrationInitiated: false,
	isRegistering: false,
} as TenantState

export const tenant = createReducer(initialState, builder =>
	builder
		.addCase(
			// case tenantConstants.GET_TENANT_REQUEST:
			// return {
			// 	...state,
			// 	loading: true,
			// }
			getTenantRequest,
			(state, action) => ({ ...state, loading: true })
		)
		.addCase(
			// case tenantConstants.GET_TENANT_SUCCESS:
			// 	if (action.payload) {
			// 		return {
			// 			...state,
			// 			loading: false,
			// 			...action.payload,
			// 		}
			// 	} else {
			// 		return {
			// 			...state,
			// 			loading: false,
			// 		}
			// 	}
			getTenantSuccess,
			(state, action) => ({ ...state, loading: false, ...(action.payload ?? {}) })
		)
		.addCase(
			// case tenantConstants.GET_TENANT_FAILURE:
			// 	return {
			// 		...state,
			// 		loading: false,
			// 		failed: true,
			// 		error: action.payload,
			// 	}
			getTenantFailure,
			(state, action) => ({ ...state, loading: false, failed: true, error: action.payload })
		)
		.addCase(
			// case tenantConstants.CHECK_TENANT_EXISTS_REQUEST:
			// return {
			// 	...state,
			// 	isValidated: false,
			// }
			checkTenantExistsRequest,
			(state, action) => ({ ...state, isValidated: false })
		)
		.addCase(
			// case tenantConstants.CHECK_TENANT_EXISTS_SUCCESS:
			// if (action.payload) {
			// 	return {
			// 		...state,
			// 		tenantExists: action.payload,
			// 		isValidated: true,
			// 	}
			// } else {
			// 	return {
			// 		...state,
			// 		isValidated: true,
			// 	}
			// }
			checkTenantExistsSuccess,
			(state, action) => ({
				...state,
				isValidated: true,
				...(action.payload ? { tenantExists: action.payload } : {}),
			})
		)
		.addCase(
			// case tenantConstants.CHECK_TENANT_EXISTS_FAILURE:
			// 	return {
			// 		...state,
			// 		tenantExists: [],
			// 		error: action.payload,
			// 		isValidated: false,
			// 	}
			checkTenantExistsFailure,
			(state, action) => ({ ...state, tenantExists: [], error: action.payload, isValidated: false })
		)
		.addCase(
			// case tenantConstants.REGISTER_TENANT_REQUEST:
			// return {
			// 	...state,
			// 	registrationInitiated: true,
			// 	registrationFailed: false,
			// }
			registerTenantRequest,
			(state, action) => ({ ...state, registrationInitiated: true, registrationFailed: false })
		)
		.addCase(
			// case tenantConstants.REGISTER_TENANT_SUCCESS:
			// return {
			// 	...state,
			// 	registrationInitiated: false,
			// }
			registerTenantSuccess,
			(state, action) => ({ ...state, registrationInitiated: false })
		)
		.addCase(
			// case tenantConstants.REGISTER_TENANT_FAILURE:
			// return {
			// 	...state,
			// 	error: action.payload,
			// 	registrationInitiated: false,
			// 	registrationFailed: true,
			// }
			registerTenantFailure,
			(state, action) => ({
				...state,
				error: action.payload,
				registrationInitiated: false,
				registrationFailed: true,
			})
		)
		.addCase(
			// case tenantConstants.TENANT_LIFECYCLE_REQUEST:
			// return {
			// 	...state,
			// 	isRegistering: false,
			// }
			getTenantLifeCycleRequest,
			(state, action) => ({ ...state, isRegistering: false })
		)
		.addCase(
			// case tenantConstants.TENANT_LIFECYCLE_SUCCESS:
			// if (action.payload) {
			// 	return {
			// 		...state,
			// 		tenantLifeCycle: action.payload,
			// 		isRegistering: true,
			// 	}
			// } else {
			// 	return {
			// 		...state,
			// 		isRegistering: true,
			// 	}
			// }
			getTenantLifeCycleSuccess,
			(state, action) => ({
				...state,
				isRegistering: true,
				...(action.payload ? { tenantLifeCycle: action.payload } : {}),
			})
		)
		.addCase(
			// case tenantConstants.TENANT_LIFECYCLE_FAILURE:
			// return {
			// 	...state,
			// 	tenantLifeCycle: null,
			// 	error: action.payload,
			// 	isRegistering: false,
			// 	registrationFailed: true,
			// }
			getTenantLifeCycleFailure,
			(state, action) => ({
				...state,
				tenantLifeCycle: null,
				error: action.payload,
				isRegistering: false,
				registrationFailed: true,
			})
		)
)

import axios from 'axios'
import type { AppDispatch } from 'src/redux/store'
import { HEADER_PARAMS } from '../../../config'
import { loginCheckSuccess, loginSuccessMs, setIdentitySuccessMs } from '../actions/identity.actions'
import { IdentityState } from '../reducers/identity.reducers'
import { TenantState } from '../reducers/tenant.reducers'
import { getQueryParameters, setMsTokenByInterceptor } from './identityApi'

export const msIdentityService = async (
	tenant: TenantState,
	msToken: string,
	appName: string,
	dispatch: AppDispatch,
	props: any
) => {
	console.log('msIdentityService-------****', tenant, msToken)

	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	const { data: tokenData } = await getAcessTokenFromMsToken(msToken, appName, tenant.apiUrl as string)

	dispatch(loginSuccessMs(tokenData.signedPayload, tenant, props))
	setMsTokenByInterceptor(tokenData.signedPayload, tenant.id)
	dispatch(setIdentitySuccessMs(tokenData.userPayload, tenant, props))
	// identityServices.saveTenantId()
	return tokenData
	// if (!keycloakInstances[tenant.tenantId]) {
	//     keycloakInstances[tenant.tenantId] = new Keycloak({
	//         url: idpUrl,
	//         realm: tenant.tenantId,
	//         clientId: 'adminui-service'
	//     });
	// }
}

interface MsEnsureAuthenticationArgs {
	dispatch: AppDispatch
	identity: IdentityState
	tenant: TenantState
	props: any
}
export const msEnsureAuthentication = async ({ dispatch, identity, tenant, props }: MsEnsureAuthenticationArgs) => {
	// const token = sessionStorage.getItem('keycloak_token');
	// const refreshToken = sessionStorage.getItem('keycloak_refreshToken');
	const isExpired = props.botStudioBot ? (identity && identity.exp ? new Date((identity.exp + identity.timeSkew) * 1000) < new Date() : true) : (identity && identity[tenant.tenantId] && identity[tenant.tenantId].exp) ? new Date((identity[tenant.tenantId].exp + identity[tenant.tenantId].timeSkew) * 1000) < new Date() : true
	const token = identity[`token_${tenant.tenantId}`]
	if (((props.botStudioBot && identity.token && identity.isAuthenticated) || (token && identity[tenant.tenantId] && identity[tenant.tenantId].isAuthenticated)) && !isExpired) {
		// console.log("Already Logged into Actionable Science")
		// let token = sessionStorage.getItem("keycloak_token");
		// let tokenRefresh = sessionStorage.getItem("keycloak_refreshToken");
		//! identity.isAuthenticated &&
		// keycloakInstances[tenant.tenantId].init({ adapter: 'default', token: identity.token })

		dispatch(loginCheckSuccess(tenant, props))
		if (props.botStudioBot) {
			setMsTokenByInterceptor(identity.token, tenant.id)
		} else {
			setMsTokenByInterceptor(token, tenant.id)
		}
		// setTimeout(()=>dispatch(identityActions.setIdentity(keycloakInstances[tenant.tenantId])), 1000);
	} else {
		console.log('SESSION EXPIRED : ', isExpired)
		const msToken = sessionStorage.getItem('ms_token')
		const queryParameters = getQueryParameters()
		const { signedPayload: signedUserToken } = await msIdentityService(
			tenant,
			msToken as string,
			queryParameters.appName,
			dispatch
		)

		dispatch(loginSuccessMs(signedUserToken, tenant, props))

		setMsTokenByInterceptor(signedUserToken, tenant.id)
		
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		msUpdateSession(tenant, signedUserToken)

		// if (!singleTenant) identityServices.saveTenantId()
		// dispatch(identityActions.clearIdentity())
		// dispatch(identityActions.initiateLogin());
		// keycloakInstances[tenant.tenantId]
		//     .init({
		//         onLoad: 'login-required',
		//         flow: 'standard',
		//     })
		//     .success(async authenticated => {
		//         dispatch(identityActions.loginSuccess(keycloakInstances[tenant.tenantId].token))
		//         await setInterceptor(keycloakInstances[tenant.tenantId].token, tenant.id)
		//         setTimeout(() => dispatch(identityActions.setIdentity()), 1000);
		//         updateSession(tenant)
		//         //if (!singleTenant) saveTenantId()
		//     })
	}
}
function msUpdateSession(_tenant: unknown, token: string) {
	sessionStorage.setItem('ms_access_token', token)
}

function getAcessTokenFromMsToken(msToken: string, appName: string, apiUrl: string) {
	const apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl') || ''
	const requestOptions = {
		url: `${apiUrlCustom}/tenant/getAuthToken/${appName}`,
		headers: { msToken, ...HEADER_PARAMS },
		method: 'GET',
	}

	interface GetAuthTokenResponse {
		signedPayload: string
		userPayload: unknown
	}
	return axios.request<GetAuthTokenResponse>(requestOptions)
}

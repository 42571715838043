import * as notificationConstants from '../constants/notification.constants'

interface NotificationState {
	// TODO: why count.count? Can we flatten this? Referenced in MinimizableWebChat code a few lines prior to returning jsx
	count?: {
		count: number
	}
	loading?: boolean
}
export const notificationCount = (state: NotificationState = {}, action): NotificationState => {
	switch (action.type) {
		case notificationConstants.GET_REQUEST_NOTIFICATION:
			return {
				...state,
				//  botData:{},
				loading: true,
			}
		case notificationConstants.GET_SUCCESS_NOTIFICATION:
			return {
				...state,
				loading: false,
				count: action.item[0],
			}
		case notificationConstants.GET_FAILURE_NOTIFICATION:
			return {
				...state,
				loading: false,
			}

		default:
			return state
	}
}

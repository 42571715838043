import * as whiteLabelConstant from '../constants/whiteLabel.constants'
import { whiteLabelService } from '../services/whiteLabel.services'
class WhiteLabelActions {
	getDataWithDefaultTenant(tenantUid, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.getWhiteRequest())

			whiteLabelService.getDataWithDefaultTenant(tenantUid, apiUrl).then(
				item => {
					if (item) {
						dispatch(that.getWhiteSuccess(item))
					} else {
						let errorMsg = 'Cannot fetch white label at this moment please try again later'
						dispatch(that.getWhiteFailure(errorMsg))
					}
				},
				error => {
					let errorMsg = 'Cannot fetch white label at this moment please try again later'
					dispatch(that.getWhiteFailure(errorMsg))
				}
			)
		}
	}

	getWhiteRequest() {
		return { type: `${whiteLabelConstant.GETCONFIG_REQUEST_WHITE_LABELING}` }
	}
	getWhiteSuccess(record) {
		return { type: `${whiteLabelConstant.GETCONFIG_SUCCESS_WHITE_LABELING}`, item: record }
	}
	getWhiteFailure(error) {
		return { type: `${whiteLabelConstant.GETCONFIG_FAILURE_WHITE_LABELING}`, error: error }
	}
}

let whiteLabelAction = new WhiteLabelActions()

const getDataWithDefaultTenant = (tenantUid, apiUrl) => whiteLabelAction.getDataWithDefaultTenant(tenantUid, apiUrl)

export { getDataWithDefaultTenant }
